import React, { useEffect, useState } from "react";
import MultiDatePicker from "react-multi-date-picker";
import moment from "moment-timezone";
import "./goondemand.css";
import { useDispatch, useSelector } from "react-redux";
import Questions from "./questionsbox";
import ScheduledetailsModal from "../../admin/ondemandupdates/scheduledetails";
import {
  acceptOnDemandSession,
  addMediumToOnDemandQueue,
  bookOnDemandSession,
  checkMediumInOnDemandQueue,
  clearOnDemandCache,
  createMediumBooking,
  createOnDemandBooking,
  getMediumOndemandEvents,
  getZoomLinkOnDemand,
  rejectOnDemandSession,
  removeMediumFromOnDemandQueue,
  removePairFromCache,
  updateOnDemandBoxStatus,
} from "../../../services/medium";
import AlredyJoined from "./alreadyJoined";
import ConfirmOut from "./confirmOut";
import { el } from "date-fns/locale";
import Pusher from "pusher-js";
import ReminderPopUp from "./reminderPopup";
import ExitQueuePopup from "./exitQueuePopup";
import { useLocation, useNavigate } from "react-router-dom";
import CountdownTimerReact from "./countdown";
import { getTimeImage } from "../../../utils/utils";
import { setLoadingContent, setLoadingText } from "../../../reducer/auth/auth";
import TimeEndedPopup from "./timeendedpopup";
import TimerForEvent from "./timerforevent";
import ChooseMedium from "./choosemedium";
import { toast } from "react-toastify";
import PartnerEndedPopup from "./partnerendedpopup";
import EmerGencyPopUp from "./emergencypopup";
import { setIsOnDemandBox, setUser } from "../../../reducer/userSlice";
import CacheStep from "./cacheStep";
import { getBrowserData } from "../../../utils/helpers";
import { fetchUser } from "../../../services/auth";
import { items } from "../../admin/calendar/data";
import BookAppointmentPopUp from "./bookappointmentpopup";
import BookingConfirm from "../booking/confirm";
import { getOndemandCategories } from "../../../services/admin";
import { ClockLoader, GridLoader } from "react-spinners";
import LoaderText from "../../../components/loader";

export default function GoOnDemandPractice() {
  const {
    timezone,
    id,
    is_on_demand_box,
    cache_clear_confirmations,
    timezone_name,
    test_mode,
  } = useSelector((state) => state.user.user);

  const dispatch = useDispatch();
  const currentUserTime = moment()
    .tz(timezone_name)
    .format("hh:mm A");
  const currentUserDate = moment()
    .tz(timezone_name)
    .format("hh:mm A");
  const [loaderType, setloaderType] = useState("1");
  const [inputValue, setInputValue] = useState("");
  const [info, setInfo] = useState();
  const [Index, setIndex] = useState(0);
  const [eventsList, setEventList] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5);
  const [values, setValues] = useState([]);
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [timer, setTimer] = useState(null);
  const [activeEvent, setActiveEvent] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [time, setTime] = useState(0);

  const [ondemandStatus, setOndamandStatus] = useState(false);
  const [counterStatus, setCounterStatus] = useState(false);
  const [shoHostStatus, setshoHostStatus] = useState(false);
  const [meeting_link, setmettingLink] = useState("");
  const [meetingLinkStatus, setmettingLinkStatus] = useState(false);
  const [appoinmentId, setAppoinmentId] = useState("");

  //new
  const [pairedUsers, setPairedUsers] = useState([]);
  const [pairedevent_id, setpairedevent_id] = useState("");
  const [runagain, setRunAgain] = useState(false);
  const [hangon, setHangOn] = useState(false);
  const [acceptSession, seAcceptSession] = useState(false);
  const [otherInfo, setOtherInfo] = useState();
  const [seconds, setSeconds] = useState(30);
  const [isActivePaired, setIsActivePaired] = useState(false);
  const [checkYes, setCheckYes] = useState(false);
  const [matchName, setMatchName] = useState("");
  const [message, setMessage] = useState("");
  const [timeLeft, setTimeLeft] = useState(""); // 10 minutes in seconds (600 seconds)
  const [isActive, setIsActive] = useState(false);
  const [is_verified, setIs_verified] = useState(false);
  const [checkONdemandMessage, setcheckONdemandMessage] = useState("");
  const [directAppMediumName, setdirectAppMediumName] = useState("");
  const [directAppMediumInfo, setdirectAppMediumInfo] = useState({});
  const [slotDetails, setslotDetails] = useState({});
  const [userDetails, setuserDetails] = useState({});
  const [sessions, setSessions] = useState([]);
  const [session_id, setsession_id] = useState('');
  const [disabledfilters, setDisabledFilters] = useState(false);
  const [disableLoadMore, setdisableLoadMore] = useState(false);

  //new
  let interval;

  useEffect(() => {
    getOndemandCategories().then((data) => {
      console.log(data.data.data, "data.data.dat")
      // if (data.data.status) {

      setSessions(data.data.data)
      // }
    })
  }, [])

  const resetEvents = () => {
    setValues([])
    setInputValue('')
    setsession_id('');
    const from = moment().format("YYYY-MM-DD");
    const to = moment()
      .add(1, "days")
      .format("YYYY-MM-DD");
    setFromTime(from);
    setToTime(to);

    getEventList(from, to, '', '');
  }
  const loadMoreItems = () => {
    if (eventsList.length > visibleCount) {
      setVisibleCount((prevCount) => prevCount + 5);
      setFromTime(fromTime);
      setToTime(toTime);
    } else {
      console.log(values, session_id)
      if (!values.length && session_id == "") {

        const from = moment(toTime, "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD");
        const to = moment(from, "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD");
        setFromTime(from);
        setToTime(to);
        getMediumOndemandEvents(from, to, inputValue, session_id)
          .then((data) => {
            const events = Object.values(data.data.data).flat();

            let newEvents = events.map((info) => {
              return {
                id: info.id,
                description: info.description,
                ondemand_session_category_id: info.ondemand_session_category_id,
                name: info.title,
                // time: times(info.start_time),
                time: info.start_time,
                monthname: month(info.date),
                date: date(info.date),
                day: day(info.date),
                fullday: fullday(info.date),
                subtext: info.sub_text,
                backupdate: info.date,
                end_time: info.end_time,
                // showReminder: checkTime(info.date, info.start_time, info.end_time),
                is_event_started: info.is_event_started,
                spots_left: info.spots_left !== undefined ? info.spots_left : "",
                is_group_event:
                  info.is_group_event !== undefined ? info.is_group_event : false,
                session_id: info.session_id !== undefined ? info.session_id : "",
                medium_name:
                  info.medium_name !== undefined ? info.medium_name : "",
                medium_id: info.medium_id !== undefined ? info.medium_id : "",
                duration: info.duration !== undefined ? info.duration : "",
              };
            });
            let newE = eventsList;
            let newarray = newE.concat(newEvents);
            console.log("eventsList", eventsList, "newEvents", newEvents);
            setEventList(newarray);
            console.log(newarray, "");
            // runIntervalFunc(newEvents);
            setVisibleCount((prevCount) => prevCount + 5);
          })
          .catch(() => { });
      }
      else if (session_id != "" && !values.length) {
        const from = moment(toTime, "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD");
        const to = moment(from, "YYYY-MM-DD")
          .add(30, "days")
          .format("YYYY-MM-DD");
        setFromTime(from);
        setToTime(to);
        getMediumOndemandEvents(from, to, inputValue, session_id)
          .then((data) => {
            const events = Object.values(data.data.data).flat();

            let newEvents = events.map((info) => {
              return {
                id: info.id,
                description: info.description,
                ondemand_session_category_id: info.ondemand_session_category_id,
                name: info.title,
                // time: times(info.start_time),
                time: info.start_time,
                monthname: month(info.date),
                date: date(info.date),
                day: day(info.date),
                fullday: fullday(info.date),
                subtext: info.sub_text,
                backupdate: info.date,
                end_time: info.end_time,
                // showReminder: checkTime(info.date, info.start_time, info.end_time),
                is_event_started: info.is_event_started,
                spots_left: info.spots_left !== undefined ? info.spots_left : "",
                is_group_event:
                  info.is_group_event !== undefined ? info.is_group_event : false,
                session_id: info.session_id !== undefined ? info.session_id : "",
                medium_name:
                  info.medium_name !== undefined ? info.medium_name : "",
                medium_id: info.medium_id !== undefined ? info.medium_id : "",
                duration: info.duration !== undefined ? info.duration : "",
              };
            });
            let newE = eventsList;
            let newarray = newE.concat(newEvents);
            console.log("eventsList", eventsList, "newEvents", newEvents);
            setEventList(newarray);
            console.log(newarray, "");
            // runIntervalFunc(newEvents);
            setVisibleCount((prevCount) => prevCount + 5);
          })
          .catch(() => { });
      }
      else {
        setdisableLoadMore(true);
      }
    }
  };

  const navigate = useNavigate();

  const day = (day) => moment(day).format("ddd"); // Short day name (e.g., 'Fri')
  const fullday = (day) => moment(day).format("dddd");
  const month = (month) => moment(month).format("MMM"); // Short month name (e.g., 'Sep')
  const date = (date) => moment(date).format("DD");
  const times = (time) => moment(time, "HH:mm").format("hh:mm A");
  const location = useLocation();

  // useEffect(() => {
  //   const handleRouteChange = () => {
  //     console.log("Route changed!");
  //     removeMediumFromQueue()
  //   };

  //   handleRouteChange(); // Initial call on component mount

  //   // Return cleanup function if needed (not necessary for route changes here)
  // }, [location]);

  //functions
  const getEventList = (from, to, keyword, session_id) => {
    console.log(session_id, "session_id")
    setVisibleCount(5);
    setDisabledFilters(true)
    setdisableLoadMore(false);
    getMediumOndemandEvents(from, to, keyword, session_id)
      .then((data) => {
        setDisabledFilters(false)
        const events = Object.values(data.data.data).flat();
        console.log(events, "events", data.data.data, "data.data.data");
        let newEvents = events.map((info) => {
          return {
            id: info.id,
            description: info.description,
            ondemand_session_category_id: info.ondemand_session_category_id,
            name: info.title,
            // time: times(info.start_time),
            time: info.start_time,
            monthname: month(info.date),
            date: date(info.date),
            day: day(info.date),
            fullday: fullday(info.date),
            subtext: info.sub_text,
            backupdate: info.date,
            end_time: info.end_time,
            // showReminder: checkTime(info.date, info.start_time, info.end_time),
            is_event_started: info.is_event_started,
            spots_left: info.spots_left !== undefined ? info.spots_left : "",
            is_group_event:
              info.is_group_event !== undefined ? info.is_group_event : false,
            session_id: info.session_id !== undefined ? info.session_id : "",
            medium_name: info.medium_name !== undefined ? info.medium_name : "",
            medium_id: info.medium_id !== undefined ? info.medium_id : "",
            duration: info.duration !== undefined ? info.duration : "",
          };
        });

        console.log(newEvents, "newEvents");
        setEventList(newEvents);
        // runIntervalFunc(newEvents);
      })
      .catch(() => { });
  };

  const checkOldTime = (datee, timee) => {
    let status = false;
    // moment(`${datee} ${timee}`, "YYYY-MM-DD hh:mm A").tz(timezone_name)
    // const targetDate =  moment(`${datee} ${timee}`, "YYYY-MM-DD hh:mm A");
    const targetDate = moment(`${datee} ${timee}`, "YYYY-MM-DD hh:mm A").tz(
      timezone_name
    );
    const currentDate = moment("YYYY-MM-DD hh:mm A").tz(timezone_name);

    // Check if the current time is past the target time
    // console.log(currentDate.isSame(targetDate), "currentDate.isSame(targetDate)")
    if (currentDate.isSame(targetDate)) {
      status = true;
    } else {
      status = false;
    }
    return status;
  };
  const checkEndTime = (item) => {
    let status = true;
    let currenTime = moment().format("YYYY-MM-DD hh:mm A");

    let eventtime = moment(
      `${item.backupdate} ${item.end_time}`,
      "YYYY-MM-DD hh:mm A"
    ).format("YYYY-MM-DD hh:mm A");

    // let beforeTime = moment(eventtime).subtract(15, 'minutes');
    const differenceInMinutes = moment(eventtime.replace(/-/g, "/")).diff(
      currenTime.replace(/-/g, "/"),
      "seconds"
    );
    return differenceInMinutes;

    // return differenceInMinutes;
  };
  const expireSession = (item) => {
    // checkEndTime(item);
    if (checkEndTime(item) == 0) {
      clearOnDemandCache(item.id).then(() => { });
    }
    // clearOnDemandCache()
  };
  const runIntervalFunc = (event = []) => {
    let checkReturnItem;
    interval = setInterval(() => {
      let list = eventsList.length > 0 ? eventsList : event;
      let newlist = list.filter((item) => {
        expireSession(item);
        checkReturnItem = checkOldTime(item.backupdate, item.end_time);
        // console.log(checkReturnItem, "checkReturnItem value passed")
        if (!checkOldTime(item.backupdate, item.end_time)) {
          return item;
        }
      });
      console.log(newlist, "newlist");
      let newEvent = newlist.map((item) => {
        // console.log(item.backupdate, item.time);
        let status = checkTime(item.backupdate, item.time, item.end_time);
        return {
          ...item,
          showReminder: status,
        }; // Example: Incrementing each value
      });
      // console.log(newEvent, "newEvent")
      console.log(checkReturnItem, "checkReturnItem value passed");
      if (checkReturnItem) {
        localStorage.setItem("run", "yes");
        if (!localStorage.getItem("run")) {
          // console.log(checkReturnItem, "checkReturnItem")
          const from = moment().format("YYYY-MM-DD");
          const to = moment()
            .add(1, "days")
            .format("YYYY-MM-DD");
          setFromTime(from);
          setToTime(to);

          getEventList(from, to, "");
          return;
        }
      } else {
        localStorage.removeItem("run");
      }
      setEventList(newEvent);
    }, 6000); // 60 seconds = 60000 ms
    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  };
  const getClientProfileDetails = (item, index) => {
    setIndex(index);
    setInfo(item);
    window.loadModal2("#ScheduledetailsModal", "show");
  };
  const handleValueChange = (newValues) => {
    if (newValues) {
      setValues(newValues);
      let name = {};
      if (newValues.length === 2) {
        const startDate = newValues[0].format("YYYY-MM-DD");
        const endDate = newValues[1].format("YYYY-MM-DD");
        // name["created_at"] = `${startDate},${endDate}`; // Fix the assignment
        setFromTime(startDate);
        setToTime(endDate);
        getEventList(startDate, endDate, inputValue, session_id);
      }
      // if(newValues.length === 1){
      //   console.log( newValues[0].format("YYYY-MM-DD"))
      //   const startDate = newValues[0].format("YYYY-MM-DD");
      //   getEventList('', '','',startDate)
      // }
    }
  };
  const inputChanged = (e) => {
    let value = e.target.value;
    setInputValue(e.target.value);

    clearTimeout(timer);
    console.log(e.target.value.length, "e.target.value.length")
    const newTimer = setTimeout(() => {
      if (e.target.value.length > 2) {
        const from = moment().format("YYYY-MM-DD");
        const to = moment()
          .add(30, "days")
          .format("YYYY-MM-DD");
        setFromTime(from);
        setToTime(to);
        getEventList(from, to, value, session_id);
      }
      else if (e.target.value.length == 0) {
        console.log('this also runi');
        const from = moment().format("YYYY-MM-DD");
        const to = moment()
          .add(1, "days")
          .format("YYYY-MM-DD");
        setFromTime(from);
        setToTime(to);
        getEventList(from, to, value, session_id);
      }
      else {

      }

    }, 2000);

    setTimer(newTimer);
  };
  const checkTime = (date, time, end) => {
    const dateToCheck = moment(date).tz(timezone_name); // Replace with the date you want to check
    const isToday = dateToCheck.isSame(moment().tz(timezone_name), "day");
    console.log(isToday, "isToday");
    // console.log(isToday, date)

    if (isToday) {
      // Convert input to moment
      let currentTime = moment()
        .format("YYYY-MM-DD HH:mm")
        .tz(timezone_name);
      let endtime = moment(`${date} ${end}`, "YYYY-MM-DD hh:mm A")
        .format("YYYY-MM-DD HH:mm")
        .tz(timezone_name);

      const inputMoment = moment(`${date} ${time}`, "YYYY-MM-DD hh:mm A")
        .tz(timezone_name)
        .format("YYYY-MM-DD HH:mm");
      const time1 = moment(currentTime, "YYYY-MM-DD HH:mm").tz(timezone_name);
      const time2 = moment(inputMoment, "YYYY-MM-DD HH:mm").tz(timezone_name);

      const time3 = moment(endtime.replace(/-/g, "/"), "YYYY-MM-DD HH:mm").tz(
        timezone_name
      );
      const minutes = time3.diff(time1, "minutes");
      const minutesDifference = time1.diff(time2, "minutes");
      let status = minutesDifference >= 0 && minutes > 0;

      return status;
    } else {
      return isToday;
    }
    return;

    // Check if the difference is between 0 and 10 (inclusive) or exactly 0
  };
  const openReminderPopup = (info) => {
    localStorage.setItem("activeEvent", info.id);
    setActiveEvent(info);
    window.loadModal2("#ReminderPopUp", "show");
  };
  const cacheCheck = (info) => {
    setActiveEvent(info);
    localStorage.setItem("activeEvent", info.id);
    localStorage.setItem("ondemandLoader", "2");
    localStorage.removeItem("matched");
    if (is_verified) {
      setloaderType("7");
    } else {
      setloaderType("2");
    }
  };
  const callProfile = async () => {
    await fetchUser()
      .then((data) => {
        dispatch(setUser(data.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const startLoaderOndemand = (type) => {
    closePopup(type);
  };
  const checkMediumInOnDemand = (info, check, user_pereference) => {
    dispatch(setLoadingContent(`<div>Please Wait ...</div>`));
    dispatch(setLoadingText(true));
    setTime(0);

    setOndamandStatus(false);
    checkMediumInOnDemandQueue()
      .then((data) => {
        console.log(data.data.data.in_queue, "data");
        console.log(data.data.data.is_cache_cleared, "data");
        dispatch(setLoadingContent(""));
        dispatch(setLoadingText(false));
        setActiveEvent(info);
        localStorage.setItem("activeEvent", info.id);
        localStorage.setItem("ondemandLoader", "2");
        localStorage.removeItem("matched");
        setcheckONdemandMessage(data.data.success);
        if (data.data.data.in_queue) {
          window.loadModal2("#AlredyJoined", "show");
        } else if (
          !data.data.data.in_queue &&
          !data.data.data.is_cache_cleared
        ) {
          setloaderType("2");
        } else if (
          !data.data.data.in_queue &&
          data.data.data.is_cache_cleared
        ) {
          setloaderType("7");
        }
      })
      .catch(() => {
        checkMediumInOnDemand(info, check, user_pereference);
      });
  };

  const addMediumQueue = (check) => {
    setRunAgain(false);
    dispatch(setLoadingContent(`<div>Please Wait ...</div>`));
    dispatch(setLoadingText(true));
    console.log(activeEvent, "activeEvent");
    let event_id = activeEvent.id;
    let date = activeEvent.backupdate;
    let end_time = activeEvent.end_time;
    let user_pereference = check == "yes" ? 0 : 1;

    // setTimeout(() => {

    addMediumToOnDemandQueue(event_id, date, end_time, user_pereference)
      .then((data) => {
        console.log(data.data);
        dispatch(setLoadingContent(""));
        dispatch(setLoadingText(false));
        setHangOn(false);
        setIsActivePaired(false);
        setSeconds(30);
        if (data.data.data) {
          let matched = localStorage.getItem("matched");
          if (matched) {
            return;
          }
          checkTimes();
        }
      })
      .catch(() => {
        // addMediumQueue(check)
      });
    // }, 5000);
  };

  const removeMediumFromQueue = () => {
    let ids = JSON.parse(localStorage.getItem("activeEvent"));
    if (ids) {
      removeMediumFromOnDemandQueue(ids)
        .then((data) => {
          console.log(data);

          if (data.data.data) {
            setTime(0);
            setOndamandStatus(true);
            setTimeout(() => {
              setloaderType("1");
              dispatch(setLoadingContent(""));
              dispatch(setLoadingText(false));
              localStorage.removeItem("activeEvent");
            }, 1000);
          } else {
            dispatch(setLoadingContent(""));
            dispatch(setLoadingText(false));
            // toast.error("There is problem while Removing you", { position: "top-center" })
          }
        })
        .catch(() => {
          removeMediumFromQueue();
          dispatch(setLoadingContent(""));
          dispatch(setLoadingText(false));
        });
    }
  };
  const startLoaderNow = (user_pereference) => {
    addMediumQueue(user_pereference);
    setTime(0);
  };

  const getZoomLink = (id) => {
    let status = true;
    getZoomLinkOnDemand(id)
      .then((data) => {
        // if (status) {
        if (!data.data.data) {
          setTimeout(() => {
            getZoomLink(appoinmentId);
          }, 5000);
        } else if (data.data.success == "Appointment Zoom Link") {
          setmettingLink(data.data.data);
          setTimeout(() => {
            navigate("/appointments?from=goondemand");
          }, 300000);
        }
      })
      .catch((er) => {
        console.log(er);
      });
  };
  const hideCountdownTimer = () => {
    setCounterStatus(false);
  };

  const fifteenMinutesBefore = (item) => {
    let status = true;
    let currenTime = moment()
      .tz(timezone_name)
      .format("YYYY-MM-DD hh:mm A");

    let eventtime = moment(
      `${item.backupdate} ${item.time}`,
      "YYYY-MM-DD hh:mm A"
    )
      .tz(timezone_name)
      .format("YYYY-MM-DD hh:mm A");

    let beforeTime = moment(eventtime)
      .subtract(15, "minutes")
      .tz(timezone_name);
    const differenceInMinutes = moment(eventtime.replace(/-/g, "/")).diff(
      currenTime.replace(/-/g, "/"),
      "minutes"
    );
    if (differenceInMinutes > 0 && differenceInMinutes < 16) {
      status = false;
    } else {
      status = true;
    }
    return status;
  };
  const backHandler = () => {
    dispatch(
      setLoadingContent(
        `<div>Please wait while we are removing you from queue ...</div>`
      )
    );
    dispatch(setLoadingText(true));

    let matched = localStorage.getItem("matched");
    if (!matched) {
      removeMediumFromQueue();
    }
  };

  const checkTimes = () => {
    let status = true;
    let currenTime = moment()
      .tz(timezone_name)
      .format("YYYY-MM-DD hh:mm A");
    console.log(currenTime, "currenTime");
    let eventtime = moment(
      `${activeEvent.backupdate} ${activeEvent.end_time}`,
      "YYYY-MM-DD hh:mm A"
    ).format("YYYY-MM-DD hh:mm A");
    // console.log(eventtime,"eventtime" ,moment(eventtime).tz(timezone_name).format('YYYY-MM-DD hh:mm A'))
    // let beforeTime = moment(eventtime).subtract(15, 'minutes');
    const differenceInMinutes = moment(eventtime.replace(/-/g, "/")).diff(
      currenTime.replace(/-/g, "/"),
      "seconds"
    );
    console.log(differenceInMinutes, "differenceInMinutes", "seconds");
    setTimeLeft(differenceInMinutes);
    localStorage.setItem("ondemandLoader", "3");
    setloaderType("3");
    setIsActive(true);
    // return differenceInMinutes;
  };
  const checkTimesEnd = () => {
    let status = true;
    let currenTime = moment()
      .tz(timezone_name)
      .format("YYYY-MM-DD hh:mm A");

    let eventtime = moment(
      `${activeEvent.backupdate} ${activeEvent.end_time}`,
      "YYYY-MM-DD hh:mm A"
    ).format("YYYY-MM-DD hh:mm A");

    // let beforeTime = moment(eventtime).subtract(15, 'minutes');
    const differenceInMinutes = moment(eventtime.replace(/-/g, "/")).diff(
      currenTime.replace(/-/g, "/"),
      "seconds"
    );
    console.log(differenceInMinutes, "differenceInMinutes");
    return differenceInMinutes;

    // return differenceInMinutes;
  };
  const openTimer = (item) => {
    setActiveEvent(item);
    window.loadModal2("#TimerForEvent", "show");
  };

  const rejectPaired = () => {
    let body = {
      pair: pairedUsers,
      event_id: pairedevent_id,
    };
    rejectOnDemandSession(body)
      .then((data) => {
        if (data.data.data) {
          // toast.error("Sorry that partner is no longer available", { position: "top-center" })
          setRunAgain(true);
          setHangOn(false);
          setIsActivePaired(false);
          setSeconds(30);
        }
        console.log(data, "data");
      })
      .catch(() => { });
  };

  const acceptPaired = () => {
    setHangOn(true);
    let body = {
      pair: pairedUsers,
      event_id: pairedevent_id,
    };
    // if (acceptSession) {
    //   createOnDemandBooking(body)
    //     .then((data) => {
    //       console.log(data);
    //       if (data.data.data) {
    //         console.log(data);
    //       }
    //     })
    //     .catch(() => { });
    // } else {
    acceptOnDemandSession(body)
      .then((data) => {
        console.log(data);

        if (data.data.data) {
          console.log(data);
        } else {
          setHangOn(false);
        }
      })
      .catch(() => { });
    // }
  };

  const addMediumToQueueAgain = (no) => {
    dispatch(setLoadingContent(`<div>Please Wait ...</div>`));
    dispatch(setLoadingText(true));
    console.log(checkTimesEnd());
    if (checkTimesEnd() < 0) {
      dispatch(setLoadingContent(``));
      dispatch(setLoadingText(false));
      setloaderType("1");
      window.loadModal2("#TimeEndedPopup", "show");
      removeMediumFromQueue();
      return;
    }
    if (no == "no") {
      localStorage.removeItem("again_started");
    } else {
      localStorage.setItem("again_started", "yes");
    }
    let body = {
      pair: pairedUsers,
    };
    removePairFromCache(body)
      .then((data) => {
        dispatch(setLoadingContent(""));
        dispatch(setLoadingText(false));
        if (data.data.data) {
          addMediumQueue(checkYes);
        }
      })
      .catch(() => {
        addMediumToQueueAgain(no);
      });
  };
  const handleStart = () => setIsActivePaired(true);
  const handleStop = () => setIsActivePaired(false);

  //effects

  const closePopup = (type) => {
    let body = {
      status: 1,
    };

    updateOnDemandBoxStatus(body)
      .then((data) => {
        // callProfile()
        if (type == undefined) {
          addMediumQueue(checkYes);
        } else {
          setloaderType("2");
        }
      })
      .catch(() => { });

    // localStorage.setItem("EmerGencyPopUp", "EmerGencyPopUp")
    window.loadModal2("#EmerGencyPopUp", "hide");
  };

  const getInfo = async () => {
    let browserinfo = await getBrowserData();
    let name = browserinfo.browser_info;
    let filterName = cache_clear_confirmations.filter(
      (info) => info.browser_info == name
    );
    console.log(
      filterName,
      "filterName",
      browserinfo,
      cache_clear_confirmations
    );
    if (
      (filterName.length && filterName[0].is_verified != 1) ||
      !filterName.length
    ) {
      setIs_verified(true);
    } else {
      setIs_verified(false);
    }
  };
  const getEventsFromName = (e) => {
    let value = e.target.value;
    console.log(value, "setsession_id");
    setsession_id(value)
    if (values.length) {

      getEventList(fromTime, toTime, inputValue, value);
    }
    else {
      const from = moment().format("YYYY-MM-DD");
      const to = moment()
        .add(30, "days")
        .format("YYYY-MM-DD");
      setFromTime(from);
      setToTime(to);

      getEventList(from, to, inputValue, value);
    }
  }
  useEffect(() => {
    getInfo();

    // if(cache_clear_confirmations.length >0 && )
    if (is_on_demand_box != 1) {
      // window.loadModal2('#EmerGencyPopUp', "show")
    }
    localStorage.removeItem("matched");
    localStorage.setItem("ondemandLoader", "1");
    const from = moment().format("YYYY-MM-DD");
    const to = moment()
      .add(1, "days")
      .format("YYYY-MM-DD");
    setFromTime(from);
    setToTime(to);

    getEventList(from, to, inputValue, session_id);
  }, []);

  useEffect(() => {
    if (time > 0 && !ondemandStatus) {
      let status = checkOldTime(activeEvent.backupdate, activeEvent.end_time);
      if (status) {
        setOndamandStatus(true);
        removeMediumFromQueue();
        localStorage.setItem("ondemandLoader", "1");
        setloaderType("1");
        return;
      }
    }
  }, [time, ondemandStatus]);

  useEffect(() => {
    const handleBeforeUnload = (event, confirmExit) => {
      console.log(event, confirmExit);
      let ondemandLoader = localStorage.getItem("ondemandLoader");
      // if (ondemandLoader == "3") {
      event.preventDefault();
      setIsActive(false);
      removeMediumFromQueue();
      event.returnValue = "";
      // } else {
      //   event.preventDefault();
      // }
    };
    const handleUnload = (event) => {
      console.log(event);
      // event.preventDefault();
      setIsActive(false);
      removeMediumFromQueue();
      // event.returnValue = "";
      console.log("User chose to leave the page");
      // You can run any final cleanup code here before the page unloads
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    // return () => {
    window.removeEventListener("beforeunload", handleBeforeUnload);
    window.removeEventListener("unload", handleUnload);
    // };
  }, []);

  useEffect(() => {
    Pusher.logToConsole = false;
    var pusher = new Pusher("823ab72ed6ecd563b8c8", {
      cluster: "ap2",
    });

    var channel = pusher.subscribe("ondemand-booking");
    channel.bind("App\\Events\\OnDemandBooking", function (data) {
      console.log(data, "data");
      if (data.appointment !== undefined) {
        if (
          data.appointment.medium_id == id ||
          data.appointment.user_id == id
        ) {
          // console.log(data, "data");
          setTime(0);
          setCounterStatus(true);
          setOndamandStatus(true);
          setMessage(data.message);
          setAppoinmentId(data.appointment.id);
          localStorage.setItem("appoinmentId", data.appointment.id);
          setmettingLink(data.appointment.meeting_link);
          setIsActivePaired(false);
          localStorage.setItem("matched", "matched");
          // if (
          //   data.appointment.meeting_link == null ||
          //   data.appointment.meeting_link == ""
          // ) {
          //   getZoomLink(data.appointment.id);
          // }
          // setTimeout(() => {
          navigate(
            `/appointments?from=goondemand&appointment_id=${data.appointment.id}&message=${data.message}`
          );
          // }, 3000);
          // setloaderType("4");

          if (data.appointment.medium_id == id) {
            setshoHostStatus(true);
          } else {
            setshoHostStatus(false);
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    localStorage.setItem("ONDEMAND", "ONDEMAND");
    return () => {
      localStorage.removeItem("matched");
      removeMediumFromQueue(); // Call the API when the component unmounts
      localStorage.removeItem("ONDEMAND");
    };
  }, []);

  useEffect(() => {
    console.log(activeEvent, "activeEvent");
    if (activeEvent !== undefined) {
      // window.loadModal2("#TimeEndedPopup", "show");
      console.log(checkTimesEnd());
      if (checkTimesEnd() === 0 && isActive) {
        removeMediumFromQueue();
        setloaderType("1");
        clearOnDemandCache(activeEvent.id).then(() => { });
        setIsActive(false);
        window.loadModal2("#TimeEndedPopup", "show");
        return;
      }
      // If the time reaches zero, stop the timer.

      let intervalId;

      if (isActive && timeLeft > 0) {
        intervalId = setInterval(() => {
          // console.log(timeLeft, "setIsActive");
          setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);
      }
      return () => clearInterval(intervalId); // Clean up the interval on component unmount or if the timer stops
    }
  }, [isActive, timeLeft]);

  useEffect(() => {
    if (loaderType != "3") {
      setTimeLeft("");
      setIsActive(false);
    }
    if (loaderType == "1") {
      removeMediumFromQueue();
      localStorage.removeItem("activeEvent");
    }
  }, [loaderType]);

  useEffect(() => {
    if (runagain) {
      addMediumQueue(checkYes);
    }
  }, [runagain]);

  useEffect(() => {
    if (otherInfo !== undefined) {
      if (
        pairedUsers.includes(otherInfo.other_medium_id) &&
        pairedUsers.includes(otherInfo.user_id) &&
        parseInt(otherInfo.event_id) == parseInt(pairedevent_id)
      ) {
        seAcceptSession(true);
      }
    }
  }, [otherInfo]);

  useEffect(() => {
    let timerId;
    if (isActivePaired && seconds > 0) {
      timerId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }
    if (isActivePaired && seconds == 0) {
      seAcceptSession(false);
      setloaderType("6");
      // window.loadModal2("#PartnerEndedPopup", "show");
      // toast.error("Sorry that partner is no longer available", { position: "top-center" })
      // addMediumQueue(checkYes);
      setHangOn(false);
      setIsActivePaired(false);
      setSeconds(30);
      // setTimeout(() => {
      //   if (localStorage.getItem("again_started") == "yes") {
      //     localStorage.removeItem("again_started");
      //   } else {
      //     addMediumToQueueAgain("no");
      //   }
      // }, 5000);
    }

    // Cleanup the interval on component unmount or when the timer stops
    return () => clearInterval(timerId);
  }, [isActivePaired, seconds]);

  //webhooks effects
  useEffect(() => {
    // if (loaderType == "3" || loaderType == "5") {
    Pusher.logToConsole = false;
    var pusher = new Pusher("823ab72ed6ecd563b8c8", {
      cluster: "ap2",
    });
    const randomNumber = Math.floor(Math.random() * 10) + 1;
    console.log(parseInt(`${randomNumber}000`), "randomNumber");
    var channel = pusher.subscribe("on-demand-practice-medium-confirmation");
    channel.bind("App\\Events\\OnDemandPracticeMediumConfirmation", function (
      data
    ) {
      console.log(data, "on-demand-practice-medium-confirmation");
      if (data.pair.includes(id)) {
        setPairedUsers(data.pair);
        setpairedevent_id(data.event_id);
        localStorage.setItem("pairedUsers", JSON.stringify(data.pair));
        localStorage.setItem("pairedevent_id", data.event_id);
        localStorage.setItem("ondemandLoader", "5");
        let match = data.users.filter((info) => info.id !== id);
        setMatchName(match[0].screen_name);
        // setTimeout(() => {

        setloaderType("5");
        setIsActivePaired(true);
        // }, parseInt(`${randomNumber}000`));
      }
    });
    // } else {
    //   var pusher = new Pusher("823ab72ed6ecd563b8c8", {
    //     cluster: "ap2",
    //   });
    //   pusher.unsubscribe("on-demand-practice-medium-confirmation");
    // }
  }, [loaderType]);

  useEffect(() => {
    if (loaderType == "5") {
      Pusher.logToConsole = false;
      var pusher = new Pusher("823ab72ed6ecd563b8c8", {
        cluster: "ap2",
      });

      var channel = pusher.subscribe("reject-on-demand-practice-pairing");
      channel.bind("App\\Events\\RejectOnDemandPracticePairing", function (
        data
      ) {
        console.log(data, "RejectOnDemandPracticePairing");
        let pairedUsers = localStorage.getItem("pairedUsers");
        let pairedevent_id = localStorage.getItem("pairedevent_id");
        console.log(
          pairedUsers,
          "JSON.stringify(pairedUsers) === JSON.stringify(data.pair)"
        );
        if (
          pairedUsers === JSON.stringify(data.pair) &&
          parseInt(data.event_id) == parseInt(pairedevent_id)
        ) {
          seAcceptSession(false);
          seAcceptSession(false);
          // setRunAgain(true);
          if (parseInt(data.rejected_user) !== parseInt(id)) {
            setloaderType("6");
            // window.loadModal2("#PartnerEndedPopup", "show");
            // setTimeout(() => {

            //   addMediumToQueueAgain("no");
            // }, 5000);
          }
        }
        // if (data.pair.includes(id)) {
        //   setPairedUsers(data.pair);
        //   setpairedevent_id(data.event_id);
        //   setloaderType("5");
        // }
      });
    } else {
      var pusher = new Pusher("823ab72ed6ecd563b8c8", {
        cluster: "ap2",
      });
      pusher.unsubscribe("reject-on-demand-practice-pairing");
    }
  }, [loaderType]);

  useEffect(() => {
    if (loaderType == "5") {
      Pusher.logToConsole = false;
      var pusher = new Pusher("823ab72ed6ecd563b8c8", {
        cluster: "ap2",
      });

      var channel = pusher.subscribe("on-demand-practice-medium-confirmed");
      channel.bind("App\\Events\\OnDemandPracticeMediumConfirmed", function (
        data
      ) {
        console.log(data, "OnDemandPracticeMediumConfirmed");
        console.log(pairedUsers, pairedevent_id);
        setOtherInfo(data);
      });
    } else {
      var pusher = new Pusher("823ab72ed6ecd563b8c8", {
        cluster: "ap2",
      });
      pusher.unsubscribe("on-demand-practice-medium-confirmed");
    }
  }, [loaderType]);
  useEffect(() => {
    Pusher.logToConsole = false;
    var pusher = new Pusher("823ab72ed6ecd563b8c8", {
      cluster: "ap2",
    });
    var channel = pusher.subscribe("on-demand-event-update");
    channel.bind("App\\Events\\OnDemandEventUpdate", function (data) {
      console.log(data, "OnDemandEventUpdate", inputValue, session_id);
      if (window.values.length) {
        const startDate = window.values[0].format("YYYY-MM-DD");
        const endDate = window.values[1].format("YYYY-MM-DD");
        getEventList(startDate, endDate, window.inputValue, window.session_id);
      }
      else if (window.inputValue !== "") {
        console.log('event ')
        const from = moment().format("YYYY-MM-DD");
        const to = moment()
          .add(30, "days")
          .format("YYYY-MM-DD");
        setFromTime(from);
        setToTime(to);
        getEventList(from, to, window.inputValue, window.session_id);
      }
      else {


        const from = moment().format("YYYY-MM-DD");
        const to = moment()
          .add(1, "days")
          .format("YYYY-MM-DD");
        setFromTime(from);
        setToTime(to);
        getEventList(from, to, window.inputValue, window.session_id);
      }
    });
    return () => {
      var pusher = new Pusher("823ab72ed6ecd563b8c8", {
        cluster: "ap2",
      });
      pusher.unsubscribe("on-demand-event-update");
    };
  }, []);

  useEffect(() => {
    window.inputValue = inputValue
    window.session_id = session_id
    window.values = values
  }, [inputValue, session_id, values])


  useEffect(() => {
    let interval;
    if (loaderType == "3") {
      interval = setInterval(() => {
        console.log("This runs every 2 seconds");
        checkMediumQueueAgain();
      }, 60000);
    }

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, [loaderType]);

  const checkMediumQueueAgain = () => {
    checkMediumInOnDemandQueue()
      .then((data) => {
        console.log(data.data.data.in_queue, "data");
        console.log(data.data.data.is_cache_cleared, "data");

        if (!data.data.data.in_queue) {
          let event_id = activeEvent.id;
          let date = activeEvent.backupdate;
          let end_time = activeEvent.end_time;
          let user_pereference = checkYes == "yes" ? 0 : 1;

          addMediumToOnDemandQueue(event_id, date, end_time, user_pereference)
            .then((data) => { })
            .catch(() => { });
        }
      })
      .catch(() => { });
  };
  const openExitQueuePopup = () => {
    window.loadModal2("#ExitQueuePopup", "show");
    //backHandler();
  };
  const ExitQueue = () => {
    backHandler();
  };
  useEffect(() => {
    console.log("Current loaderType:", loaderType);
  }, [loaderType]);

  // Then in your mouseLeave handler, add more detailed logging
  const handleMouseLeave = (e) => {
    if (e.clientY <= 0) {
      console.log("Mouse left window, loaderType:", loaderType);
      if (loaderType === "3") {
        window.loadModal2("#ExitQueuePopup", "show");
      }
    }
  };

  useEffect(() => {
    console.log("Current loaderType:", loaderType);
  }, [loaderType]);

  useEffect(() => {
    const handleMouseLeave = (e) => {
      // Get the event properties in a cross-browser way
      const from = e.relatedTarget || e.toElement;

      // Check if mouse left the window
      if (!from || from.nodeName === "HTML") {
        console.log("Mouse left window, loaderType:", loaderType);
        if (loaderType === "3") {
          setTimeout(() => {
            window.loadModal2("#ExitQueuePopup", "show");
          }, 700); // 1000ms = 1 second
        }
      }
    };

    // Use mouseleave for better cross-browser support
    document.addEventListener("mouseout", handleMouseLeave);

    // Cleanup event listener
    return () => {
      document.removeEventListener("mouseout", handleMouseLeave);
    };
  }, [loaderType]);

  const bookDirectAppointment = (info) => {
    setdirectAppMediumInfo(info);
    console.log(info);
    window.loadModal2("#BookAppointmentPopUp", "show");
  };
  const booKGroupEvent = () => {
    dispatch(setLoadingContent(`<div>Please Wait ...</div>`));
    dispatch(setLoadingText(true));

    let body = {
      date: directAppMediumInfo.backupdate,
      time: directAppMediumInfo.time,
      service: directAppMediumInfo.session_id,
      session_length: directAppMediumInfo.ondemand_session_category_id,
      medium_id: directAppMediumInfo.medium_id,
      partner_level: [1, 2, 3, 4, 5, 6, 7],
      is_confirmed: false,
      duration: directAppMediumInfo.duration,
      time_slot_id: directAppMediumInfo.id,
    };
    createMediumBooking(body, true)
      .then((data) => {
        dispatch(setLoadingContent(``));
        dispatch(setLoadingText(false));
        if (data.data.status) {
          setslotDetails(data.data.data);
          window.loadModal2("#BookAppointmentPopUp", "hide");
          setloaderType("CONFIRM");
          toast.success(data.data.message, {
            position: "top-center",
          });
        } else {
          toast.error(data.data.message, {
            position: "top-center",
          });
        }
      })
      .catch(() => {
        dispatch(setLoadingContent(``));
        dispatch(setLoadingText(false));
      });
  };
  return (
    <>
      {loaderType == "3" && (
        <>
          <div className="globe-gif-wrapper">
            <div
              onClick={backHandler}
              className="back-btn-appointments pointer"
            >
              <div className="bck back-btn-go-ondemand-practice">
                <img src={"/ondemand-icons/arrow-back.png"} alt="back" />
                <p className="margin-unset"> Back</p>
              </div>
            </div>
          </div>
          <div className="godemantevent-activeevent-info">
            <div className="globe-gif-wrapper globe-gif-wrapper-padd">
              <h4 className="text-left godemantevent-activeevent-info-heading mb-3 mt-3">
                {/* {console.log(activeEvent)} */}
                {activeEvent !== undefined && activeEvent.name}
              </h4>
              <p className="line-height-30px  font-size-20">
                Hold on, we are searching for your match.
                <br /> Leaving this screen will stop your matching process.
              </p>
            </div>
          </div>
          <div className="globe-gif-wrapper globe-gif-wrapper-margins ">
            <img
              className="w-100 globe-image"
              // src={"/ondemand-icons/loader.png"}
              src={"/localImages/AroundTheWorld.gif"}
              alt="globe"
            />
          </div>
          {/* <video className="loader-video" autoPlay loop>
            <source src={"/localImages/AroundTheWorld.mp4"} type="video/mp4" />
          </video> */}
          <div className="d-flex align-items-center justify-content-around mt-3">
            <div
              onClick={backHandler}
              className="font-size-22 width-350-px white-border pointer indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4  d-flex align-items-center justify-content-center border-radius-40"
            >
              Exit Queue
            </div>
          </div>
        </>
      )}
      {loaderType == "1" && (
        <div className="container-fluid ">
          <div className="container-fluid ">
            <div className="dashboard ">
              <div className="row">
                <div className="col-md-12">
                  <div className="middle-screen-name w-100 font-size-22 mt-2 mb-2">
                    <b>
                      Timezone: {timezone !== undefined && timezone}&nbsp; { }
                    </b>
                  </div>
                  <div className="ondemandpractice-wrapper-medium-practice position-relative">
                    {disabledfilters && <div className="ondemandpractice-wrapper-medium-practice-loader">

                      <div className="ondemandpractice-wrapper-medium-practice-spinner spinner-text">
                        <ClockLoader size={60} color={"#d5a67b"} />
                        <p className="mt-2 d-block">
                          {" "}
                          <div dangerouslySetInnerHTML={{ __html: 'Please Wait' }} />{" "}
                        </p>
                      </div>

                    </div>}
                    <div></div>
                    <div className="ondemandpractice-wrapper-medium-practice-filter-secion justify-content-between  d-flex-flex-wrap-wrap">
                      <div className="search-box search-container ">
                        <input
                          value={inputValue}
                          onChange={inputChanged}
                          className="search-input"
                          // type={filterValues === "created_at" ? "date" : "text"}
                          placeholder="Search"
                        />
                      </div>
                      <div className="custom-select-goondemand-wrapper">
                        <div class="custom-select-goondemand">
                          <div class="select-box-goondemand">
                            <select name="sessions_name" value={session_id} onChange={getEventsFromName}>
                              <option value={""} selected disabled>{'Session Type'}</option>
                              {sessions.map((info) => <option value={info.id}>{info.sub_session_name}</option>)}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="filter-wrapper filter-container-box">
                        <MultiDatePicker
                          style={{ height: "45px" }}
                          className="search-input "
                          numberOfMonths={2}
                          disableMonthPicker
                          disableYearPicker
                          range
                          value={values}
                          onChange={handleValueChange}
                          placeholder="Select date range"
                          minDate={new Date()}
                        // maxDate={new Date()}
                        />
                      </div>
                      <div className="ondeand-practive-reset-btn-container" >

                        <button className="ondeand-practive-reset-btn ml-2" onClick={resetEvents} title="Reset">
                          Reset
                        </button>
                      </div>

                    </div>
                    <div>
                      <h3 className="ondemandpractice-heading-ractice-mediums mt-3 pad-20">
                        Events
                      </h3>
                    </div>
                    <div>
                      <ul className="godemantevent-box-list min-height-50vh pad-20">
                        {eventsList
                          .slice(0, visibleCount)
                          .map((item, index) => (
                            <>
                              {item.is_group_event ? (
                                <li>
                                  <div className="godemantevent-box">
                                    <div className="godemantevent-internal-box d-flex-flex-wrap-wrap">
                                      <div className="godemantevent-date-box">
                                        <div className="ondemand-calendar-box">
                                          <div className="ondemand-calendar-box-internal">
                                            <div className="month-name">
                                              <span>{item.monthname}</span>
                                            </div>
                                            <div className="month-date mt-1">
                                              <span>{item.date}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="godemantevent-date-box width-55 pointer">
                                        <div>
                                          <span className="ondemandpractice-date-box-day">
                                            <span aria-hidden="true">
                                              <span>{item.day}</span>
                                              <span class="sc-1idcr5x-1 dieHWG">
                                                <span>
                                                  {item.time}{" "}
                                                  {getTimeImage(item.time) ? (
                                                    <img
                                                      src="localImages/moon.png"
                                                      className="star-imh-moon"
                                                      alt="time-image"
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                </span>{" "}
                                                -{" "}
                                                <span>
                                                  {item.end_time}{" "}
                                                  {getTimeImage(
                                                    item.end_time
                                                  ) ? (
                                                    <img
                                                      src="localImages/moon.png"
                                                      className="star-imh-moon"
                                                      alt="time-image"
                                                    />
                                                  ) : (
                                                    ""
                                                  )}{" "}
                                                  (Group Coaching)
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <div>
                                          <span class="sc-fyofxi-6 ivIeWB">
                                            <span class="sc-fyofxi-5 gJmuwa godemantevent-activeevent-info-heading font-size-20">
                                              Group Coaching by{" "}
                                              {item.medium_name}
                                            </span>
                                            {/* <span class="sc-fyofxi-5 gJmuwa">Resch Center</span> */}
                                          </span>
                                          <span class="sc-fyofxi-7 jWLmQR">
                                            <span class="sc-fyofxi-5 gJmuwa">
                                              {item.subtext}
                                            </span>
                                          </span>
                                        </div>
                                        {/* <div
                                      onClick={() =>
                                        getClientProfileDetails(item, index)
                                      }
                                      className="more-info-go-ondemand-practice d-flex align-items-center"
                                    >
                                      <span className="inline-block">

                                        More Details
                                      </span>
                                      <img src={'./ondemand-icons/arrow-icon-black.png'} />
                                    </div> */}
                                      </div>

                                      <div className="godemantevent-date-box godemantevent-date-button-box text-right">
                                        <div className="godemantevent-date-button-box-before"></div>
                                        <div className="ondemand-button-box text-center">
                                          {/* () => { } */}
                                          {item.spots_left}
                                          <a
                                            onClick={() =>
                                              bookDirectAppointment(item)
                                            }
                                            href="#"
                                            className={`width-175-px kCQEIv indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4 lmaHQg d-block border-radius-40 ${item.is_event_started
                                              ? "Green-bg-all"
                                              : ""
                                              }`}
                                          >
                                            <span class="indexstyles__FlexWrapper-sc-83qv1q-1 kCQEIv">
                                              <span class="indexstyles__Text-sc-83qv1q-2 jHTUWf d-flex align-items-center">
                                                Book Appointment
                                                {/* <img
                                                className="w-20-px  mr-2"
                                                src={`${!item.is_event_started ? "/ondemand-icons/clock-icon.png" : "/ondemand-icons/find-icon.png"}`}
                                                alt="alarm"
                                              />
                                          
                                              <span class="sc-1qeub3n-6 iwMkwK">
                                                <span class="sc-1qeub3n-5 eTlzst">
                                                  {item.is_event_started
                                                    ? "Find a Partner"
                                                    : "Remind Me"}
                                                </span>
                                              </span> */}
                                              </span>
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="godemantevent-box">
                                    <div className="godemantevent-internal-box d-flex-flex-wrap-wrap">
                                      <div className="godemantevent-date-box">
                                        <div className="ondemand-calendar-box">
                                          <div className="ondemand-calendar-box-internal">
                                            <div className="month-name">
                                              <span>{item.monthname}</span>
                                            </div>
                                            <div className="month-date mt-1">
                                              <span>{item.date}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="godemantevent-date-box width-55 pointer">
                                        <div>
                                          <span className="ondemandpractice-date-box-day">
                                            <span aria-hidden="true">
                                              <span>{item.day}</span>
                                              <span class="sc-1idcr5x-1 dieHWG">
                                                <span>
                                                  {item.time}{" "}
                                                  {getTimeImage(item.time) ? (
                                                    <img
                                                      src="localImages/moon.png"
                                                      className="star-imh-moon"
                                                      alt="time-image"
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                </span>{" "}
                                                -{" "}
                                                <span>
                                                  {item.end_time}{" "}
                                                  {getTimeImage(
                                                    item.end_time
                                                  ) ? (
                                                    <img
                                                      src="localImages/moon.png"
                                                      className="star-imh-moon"
                                                      alt="time-image"
                                                    />
                                                  ) : (
                                                    ""
                                                  )}{" "}
                                                  (Matching period)
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <div>
                                          <span class="sc-fyofxi-6 ivIeWB">
                                            <span class="sc-fyofxi-5 gJmuwa godemantevent-activeevent-info-heading font-size-20">
                                              {item.name}
                                            </span>
                                            {/* <span class="sc-fyofxi-5 gJmuwa">Resch Center</span> */}
                                          </span>
                                          <span class="sc-fyofxi-7 jWLmQR">
                                            <span class="sc-fyofxi-5 gJmuwa">
                                              {item.subtext}
                                            </span>
                                          </span>
                                        </div>
                                        <div
                                          onClick={() =>
                                            getClientProfileDetails(item, index)
                                          }
                                          className="more-info-go-ondemand-practice d-flex align-items-center"
                                        >
                                          <span className="inline-block">
                                            More Details
                                          </span>
                                          <img
                                            src={
                                              "./ondemand-icons/arrow-icon-black.png"
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="godemantevent-date-box godemantevent-date-button-box text-right">
                                        <div className="godemantevent-date-button-box-before"></div>
                                        <div className="ondemand-button-box">
                                          {/* () => { } */}
                                          {fifteenMinutesBefore(item) && (
                                            <a
                                              onClick={
                                                item.is_event_started
                                                  ? () =>
                                                    checkMediumInOnDemand(
                                                      item
                                                    )
                                                  : () =>
                                                    openReminderPopup(item)
                                              }
                                              href="#"
                                              className={`width-175-px kCQEIv indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4 lmaHQg d-block border-radius-40 ${item.is_event_started
                                                ? "Green-bg-all"
                                                : ""
                                                }`}
                                            >
                                              <span class="indexstyles__FlexWrapper-sc-83qv1q-1 kCQEIv">
                                                <span class="indexstyles__Text-sc-83qv1q-2 jHTUWf d-flex align-items-center">
                                                  <img
                                                    className="w-20-px  mr-2"
                                                    src={`${!item.is_event_started
                                                      ? "/ondemand-icons/clock-icon.png"
                                                      : "/ondemand-icons/find-icon.png"
                                                      }`}
                                                    alt="alarm"
                                                  />
                                                  {/* <svg
                                              class="BaseSvg-sc-yh8lnd-0 ChevronIcon___StyledBaseSvg-sc-1y4em6t-0 ckLyyv sc-1qeub3n-2 bcqnRP"
                                              viewBox="0 0 24 24"
                                              width="1.5em"
                                              height="1.5em"
                                              aria-hidden="true"
                                              focusable="false"
                                            >
                                              <path d="M3.47 8.26 4.53 7.2 12 14.67l7.47-7.47 1.06 1.06L12 16.8z"></path>
                                            </svg> */}
                                                  <span class="sc-1qeub3n-6 iwMkwK">
                                                    <span class="sc-1qeub3n-5 eTlzst">
                                                      {item.is_event_started
                                                        ? "Find a Partner"
                                                        : "Remind Me"}
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </a>
                                          )}
                                          {!item.is_event_started && (
                                            <a
                                              onClick={() => openTimer(item)}
                                              href="#"
                                              class="width-175-px kCQEIv disabled-btn-join cursor-disabled indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4 lmaHQg d-block mt-2 border-radius-40 ondemand-diabled"
                                            >
                                              <span class="indexstyles__FlexWrapper-sc-83qv1q-1 kCQEIv ondemand-diabled">
                                                <span class="indexstyles__Text-sc-83qv1q-2 jHTUWf">
                                                  <span class="sc-1qeub3n-6 iwMkwK  d-flex align-items-center">
                                                    <img
                                                      className="w-20-px mr-2"
                                                      src={`${item.is_event_started
                                                        ? "/ondemand-icons/clock-icon.png"
                                                        : "/ondemand-icons/find-icon.png"
                                                        }`}
                                                      alt="alarm"
                                                    />
                                                    <span class="sc-1qeub3n-5 eTlzst ">
                                                      Find a Partner
                                                    </span>
                                                    {/* <svg
                                                class="BaseSvg-sc-yh8lnd-0 ChevronIcon___StyledBaseSvg-sc-1y4em6t-0 ckLyyv sc-1qeub3n-2 bcqnRP"
                                                viewBox="0 0 24 24"
                                                width="1.5em"
                                                height="1.5em"
                                                aria-hidden="true"
                                                focusable="false"
                                              >
                                                <path d="M3.47 8.26 4.53 7.2 12 14.67l7.47-7.47 1.06 1.06L12 16.8z"></path>
                                              </svg> */}
                                                  </span>
                                                </span>
                                              </span>
                                            </a>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )}
                            </>
                          ))}
                      </ul>
                      {(!disableLoadMore && eventsList.length > 0) && <div className="d-flex align-items-center justify-content-center mt-5">
                        <div
                          onClick={loadMoreItems}
                          className="width-175-px pointer indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4  d-flex align-items-center justify-content-center"
                        >
                          Load More
                        </div>
                      </div>}
                    </div>

                  </div>
                </div>
              </div>
            </div>
            {/* <CountdownTimer counterStatus={counterStatus} hideCountdownTimer={hideCountdownTimer} /> */}
          </div>
        </div>
      )}
      {loaderType == "7" && (
        <CacheStep
          startLoaderOndemand={startLoaderOndemand}
          onCancelQuestions={() => setloaderType("1")}
        />
      )}
      {loaderType == "2" && (
        <Questions
          setCheckYesMain={setCheckYes}
          startLoaderNow={startLoaderNow}
          onCancelQuestions={() => setloaderType("1")}
        />
      )}

      {loaderType == "4" && (
        <div className="ondemandpractice-wrapper margin-auto min-height-500px mt-5">
          <div className="">
            <div className="godemantevent-box-list">
              <p className="text-center mt-5 font-size-26">
                {" "}
                Your Appointment Is Confirmed!
              </p>

              {shoHostStatus && (
                <h3 className="text-center mt-5 line-height-32px">
                  You are the HOST.
                  <br />
                  <br />
                  {/* <b className="text-underline"> */} Be sure you are logged
                  into your Zoom <br />
                  {/* </b>{" "} */}
                  account to start the session.
                </h3>
              )}
              {/* <p className="text-center mt-4 font-size-19">{message}</p> */}
              <p className="text-center mt-4 font-size-19">
                {"Please join within 2 minutes."}
              </p>
              {counterStatus && (
                <CountdownTimerReact
                  hideCountdownTimer={hideCountdownTimer}
                  meeting_link={meeting_link}
                  getZoomLink={getZoomLink}
                  appoinmentId={appoinmentId}
                />
              )}
              {/* <p className="text-center mt-3 font-size-19">
                You can either joining the session now, or go to your
                appointments
                <br /> page to find the zoom link to join the session.
              </p> */}
            </div>
            <br />
            {(meeting_link == "" ||
              meeting_link == null ||
              meeting_link == undefined) && (
                <div className="text-center mb-3 mt-1">
                  <b> Zoom Link is being created</b>
                </div>
              )}
            {/* {meeting_link !== "" && */}
            {/* // meeting_link !== null &&
              // meeting_link !== undefined && ( */}
            <div className="d-flex align-items-center justify-content-center flex-column">
              {console.log(meeting_link, "meeting_link")}
              {/* {meeting_link !== "" && ( */}
              <a
                href={
                  meeting_link !== "" &&
                    meeting_link !== null &&
                    meeting_link !== undefined
                    ? meeting_link
                    : "javascript:void();"
                }
                target={
                  meeting_link !== "" &&
                    meeting_link !== null &&
                    meeting_link !== undefined
                    ? "_blank"
                    : ""
                }
                // onClick={() => {

                //   setloaderType('1'); setTime(0);
                //   setOndamandStatus(false)
                // }}
                className={`${meeting_link !== "" &&
                  meeting_link !== null &&
                  meeting_link !== undefined
                  ? ""
                  : "disabled-btn-join"
                  } width-415-px  font-size-22 pointer mb-3  indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4  d-flex align-items-center justify-content-center`}
              >
                Join Zoom Session Here
              </a>
              {/* )} */}
              <div
                onClick={() => {
                  navigate("/appointments?from=goondemand");
                  setloaderType("1");
                  setTime(0);
                  setOndamandStatus(false);
                }}
                className="width-415-px  pointer margin-unset indexstyles__StyledButton-sc-83qv1q-0 jdbotF-blacnk sc-1qeub3n-4  d-flex align-items-center justify-content-center"
              >
                {!shoHostStatus
                  ? "Find Zoom Link in My Appointments"
                  : "I Prefer to Find Zoom Link in Appointments Area"}
              </div>
            </div>
            {/* // )} */}

            <div className="d-flex align-items-center justify-content-center mt-5 go-ondemand-practice-hover">
              <div className="col-md-4 hoverContent">
                <div className="hover-container d-flex justify-content-center ">
                  Having issues?{" "}
                  <p className="hover-target" tabindex="0">
                    <img src="/images/qu.png" className="ondemant-i-btn" />
                  </p>
                  <aside className="hover-popup-r">
                    <p className="mainpre">
                      Download the Zoom app to your device, then try connecting
                      again.
                    </p>
                    <p className="mainpre">
                      Contact us at{" "}
                      <a href="mailto:support@verysoul.com">
                        {" "}
                        support@verysoul.com
                      </a>{" "}
                      for further assistance.
                    </p>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {loaderType == "5" && (
        <ChooseMedium
          rejectPaired={rejectPaired}
          acceptPaired={acceptPaired}
          hangon={hangon}
          seconds={seconds}
          matchName={matchName}
        />
      )}
      {/* {console.log(activeEvent, "timeLeft")} */}
      <ScheduledetailsModal
        info={info}
        setloaderType={setloaderType}
        Index={Index}
        checkMediumInOnDemand={checkMediumInOnDemand}
        cacheCheck={cacheCheck}
      />
      <ReminderPopUp activeEvent={activeEvent} />
      <ExitQueuePopup onYesClick={ExitQueue} />
      <AlredyJoined checkONdemandMessage={checkONdemandMessage} />
      <ConfirmOut />
      <TimeEndedPopup setloaderType={setloaderType} />
      <EmerGencyPopUp closePopup={closePopup} />
      <BookAppointmentPopUp
        directAppMediumInfo={directAppMediumInfo}
        booKGroupEvent={booKGroupEvent}
      />
      {loaderType == "6" && (
        <PartnerEndedPopup
          matchName={matchName}
          addMediumToQueueAgain={addMediumToQueueAgain}
        />
      )}
      {loaderType == "CONFIRM" && (
        <BookingConfirm slotDetails={slotDetails} userDetails={userDetails} />
      )}
      {activeEvent !== undefined && <TimerForEvent activeEvent={activeEvent} />}
    </>
  );
}
