import React, { useState } from 'react'
import WhatsappImg from '../subcomponetnts/contactImages/whatsapp.png'
import phoneImg from '../subcomponetnts/contactImages/old-typical-phone.png';
import emailImg from '../subcomponetnts/contactImages/email.png'
import CopyComponent from '../../../../components/copyComponent';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import './index.css';
import flags from 'emoji-flags';
import CountryFlag from './countryflag';

export default function ZoomLinkPopup({ activeContact }) {

    const { meeting_link= "" } = activeContact
   

 


  
    return (
        <div className="confirm-delete-wrapper register-medium">
            <div data-backdrop="static" className="modal fade" id={'ZoomLinkPopup'} role="dialog">
                <div className="modal-dialog padding-unset modal-dialog-preview">
                    <div className="modal-content">
                        <div className="cross-icon-popup-1 position-relative">
                            <img src="localImages/crossicon.png" onClick={() => window.loadModal2("#ZoomLinkPopup", "hide")} />
                        </div>
                        <div className="modal-body">
                            <h3>Zoom Link
                                {/* <br />
                                (Country {client_country} - {countryCodeToFlag(countryCode)}) */}
                            </h3>

                            <div className="contactbox-container ">
                              
                                
                                {meeting_link !== "" && <div className='contactbox-wrapper mt-2'>
                                    {/* <img className='w-20-px' src={emailImg} alt='whatsapp' /> */}
                                    {/* <span>{client_email}</span> */}
                                    <a
                                        className='contactbox-contact'
                                        href={meeting_link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                       
                                       >
                                        {meeting_link.substring(0, 30).concat("...")}
                                    </a>
                                    <CopyComponent value={meeting_link} />
                                </div>}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
