import React from 'react';
import Flag from 'react-world-flags';

const CountryFlag = ({ countryCode }) => {
  return (
    <div>
      {countryCode ? (
        
        <Flag
          code={countryCode.toLowerCase()} // Ensure lowercase for flag codes
          style={{ width: '40px', height: '30px' }} // Set dimensions
        />
      ) : (
        <span>🏳️</span> // Fallback for invalid or empty codes
      )}
    </div>
  );
};

export default CountryFlag;
