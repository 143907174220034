import React, { useEffect, useState } from "react";
// import moment from "moment";
import { filterList } from "../data";
import { useSelector } from "react-redux";
import Select from "react-select";
import { useNavigate, useSearchParams } from "react-router-dom";
import Search from "../../../../components/search";
// import Button from "../../../../components/button";
import { screenNameHandler, session_id_beg, session_id_end } from "../../../../utils/helpers";
import Sorting from "../../../../components/sorting";
import Pagination from "../../../../components/tablePagination";

import { fireFilter } from "../functions";
import CsvDownload from "../../../../components/csvdownload";
import CopyComponent from "../../../../components/copyComponent";
import Button from "../../../../components/button";
import {
  cancelGroupAppointments,
  confirmAppointmentstatus,
  getAllClients,
  getAllMediums,
  getAppointmentsDetails,
  getCreateAppointmentSubseesions,
  noshowGroupAppointments,
  noshowRemoveGroupAppointments,
  regenerateZoomLink,
} from "../../../../services/admin";
import { toast } from "react-toastify";
import ConfirmCancel from "../subcomponents/cancelpopup";
import { emptyAllSelectBox } from "../../../../utils/helpers";
import CreateNewAppointment from "../../Subcomponents/createappointment";
import Toggle from "../subcomponents/toggle";
import ConfirmDeleteModal from "../../../../components/deleteConfirmModal";
import ReScheduleAppointment from "../../Subcomponents/rescheduleappt";
import moment from "moment";
import Filter from "../../../../components/filter";
import NoshowBox from "../subcomponents/noshowbox";

const headers = [
  { label: "Medium Name", key: "medium_name" },
  { label: "Client Name", key: "client_name" },
  { label: "Date", key: "date" },
  { label: "Time", key: "time" },
  { label: "Session Name", key: "session_type" },
  { label: "Medium Email", key: "medium_emails" },
  { label: "Client Email", key: "client_emails" },

  { label: "Meeting Link", key: "meeting_link" },
  // { label: 'Feedback Links', key: "" },
  // { label: 'Date and Time Cancelled', key: 'date_time_canceled' },
  // { label: "Cancelled By", key: 'userby' },
  // { label: "No Show By", key: 'userby' },
];
export default function Upcoming(props) {
  const {
    upcomingAppointments,
    changeHandler,
    filterListAppointments,
    filterReset,
    getPaginationData,
    setFilterValues,
    setSearchValues,
    getAllAppointmentsList,
    activeTab,
    upcomingAppointmentsPage,
    upcomingAppointmentsrowsPerPage,
    searchValue,
    filterValues,
    serviceTypeList,
    changeHandlerFilter,
    serviceFilterValue,
    setSortingNameOrderBy,
    exportDownload,
    setSortingTabType,

    setOptionsList,
    optionsList,
    operatorList,
    setOperatorList,
    showOprater,
    setShowOprater,
    setFilterOprater,
    filterOprater,
    setFilterValueOptions,
    filterValueOptions,
    setServiceTypeShow,
    serviceTypeShow,
  } = props;
  const navigate = useNavigate();
  const { allUpcomingCount } = useSelector((state) => state.users);
  const { first_name, last_name, id } = useSelector((state) => state.user.user);

  const [active, setActive] = useState({});
  const [activeUser, setActiveUser] = useState();
  const [allClients, setAllClients] = useState([]);
  const [allMediums, setAllMediums] = useState([]);

  const [subsessionsList, setSubsessionsList] = useState([]);

  const [reschedeId, setReschedeId] = useState("");
  const [reschedeData, setReschedeData] = useState([]);

  const [appointmentInfo, setAppointmentInfo] = useState({});

  const [usersListMedium, setUsersListMedium] = useState([]);
  const [usersListClient, setUsersListClient] = useState([]);

  const [selectedIndex, setSelectedIndex] = useState("");
  const [attendeesList, setattendeesList] = useState([]);
  const [noshowFrom, setNoShowFrom] = useState('');
  const [noShowActive, setNoShowActive] = useState();

  useEffect(() => {
    setUsersListMedium(
      upcomingAppointments.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.medium_name,
        };
      })
    );
    setUsersListClient(
      upcomingAppointments.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.client_name,
        };
      })
    );
    // !allMediums.length > 0 && getMediumList(data);
  }, [upcomingAppointments]);
  const regenerateZoom = (id) => {
    let body = {
      appointment_id: id,
    };
    regenerateZoomLink(body)
      .then((data) => {
        if (data.data.status) {
          toast.success("Zoom link regenerating in background!!", {
            position: "top-center",
          });
          const data = {
            page: 1,
            rowsPerPage: 10,
          };
          getAllAppointmentsList(data, activeTab);
        } else {
          toast.error("Something went wrong", { position: "top-center" });
        }
      })
      .catch(() => { });
  };
  const changeHandlerLocal = (e, type, id) => {
    setActive({ e: e, type: type, id: id });
    window.loadModal2("#ConfirmCancelAdmin", "show");
  };

  const cancelRequest = () => {
    window.loadModal2("#ConfirmCancelAdmin", "hide");
    emptyAllSelectBox();
  };
  const confirmRequest = () => {
    let { e, type, id } = active;
    changeHandler(e, type, id);
  };
  const openConfirmAppointment = () => {
    window.loadModal2("#ConfirmAppointment", "show");
  };
  const confirmAppointment = () => {
    confirmAppointmentstatus(activeUser.appointmentId)
      .then((data) => {
        if (data.data.status) {
          toast.success("Status Confirmed", { position: "top-center" });
          const data = {
            page: 1,
            rowsPerPage: 10,
          };
          getAllAppointmentsList(data, activeTab);
        } else {
          toast.error("Something went wrong", { position: "top-center" });
        }
      })
      .catch(() => { });
  };
  const getAllFreeClients = (data) => {
    getAllClients(data)
      // getAllClients("outreach")
      .then((data) => {
        if (data.data.status) {
          setAllClients(data.data.data.data);
          // dispatch(setAllOutreachClientsCount(data.data.data.total));

          // dispatch(setAllClients(data.data.data.data));
          // setbackupClients(JSON.parse(JSON.stringify(data.data.data.data)));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getMediumList = (item) => {
    getAllMediums(item)
      .then((data) => {
        if (data.data.status) {
          setAllMediums(data.data.data.data);
          // dispatch(setAllMediumsCount(data.data.data.total))
          // dispatch(setAllMediums(data.data.data.data));
          // setbackupMediums(JSON.parse(JSON.stringify(data.data.data.data)));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSubsessions = (id) => {
    getCreateAppointmentSubseesions(id)
      .then((data) => {
        if (data.data.status) {
          setSubsessionsList(data.data.data);
        }
      })
      .catch(() => { });
  };
  const openReSchedulePopUp = (item, index) => {

    // setAppointmentInfo

    let body = {
      appointment_id: item.id,
    };
    getAppointmentsDetails(body)
      .then((data) => {
        setReschedeData(data.data.data);
        setReschedeId(item);
        setSelectedIndex(index);
        window.loadModal2("#ReScheduleAppt", "show");
      })
      .catch(() => { });
  };
  const getTimeDiff = (appoinment) => {
    let now = moment().format();
    let datetimeB = moment(appoinment.createdDate);
    // console.log(appoinment.date,appoinment.time, "now",now)
    // console.log(datetimeB,"datetimeB")
    // var a = moment('2016-06-06T21:03:55');//now
    // var b = moment('2016-05-06T20:03:55');

    let final = datetimeB.diff(now, "minutes");
    // console.log(final)
    let status;
    final < -10 ? (status = true) : (status = false);
    return status;
  };

  const refreshScreen = () => {
    const data = {
      page: 1,
      rowsPerPage: 10,
    };
    getAllAppointmentsList(data, activeTab, "refresh");
  };
  const openNoShowBox = (list, from, item) => {
    setNoShowActive(item);
    setNoShowFrom(from);
    setattendeesList(list)
    window.loadModal('#NoshowBox', 'show');
  }
  const cancelGroupAppointment = (id) => {
    let body = {
      appointment_id: id
    }
    cancelGroupAppointments(body).then((data) => {
      if (data.data.status) {
        toast.success("Status updated succesfully", {
          position: "top-center",
        });
        const data = {
          page: 1,
          rowsPerPage: 10,
        };
        getAllAppointmentsList(data, activeTab,);
      }
      else {
        toast.error("Something went wrong", { position: "top-center" });
      }
    })
  }
  const noshowGroupAppointment = (item) => {
    console.log(item);
    let body = {
      appointment_id: noShowActive.id,
      user_id: item.user_id
    }
    noshowGroupAppointments(body).then((data) => {
      if (data.data.status) {
        toast.success("Status updated succesfully", {
          position: "top-center",
        });
        const data = {
          page: 1,
          rowsPerPage: 10,
        };
        getAllAppointmentsList(data, activeTab,);
      }
      else {
        toast.error("Something went wrong", { position: "top-center" });
      }
    })
  }
  const noshowRemoveGroupAppointment = (item) => {
    console.log(item);
    let body = {
      appointment_id: noShowActive.id,
      user_id: item.user_id
    }
    noshowRemoveGroupAppointments(body).then((data) => {
      if (data.data.status) {
        toast.success("Status updated succesfully", {
          position: "top-center",
        });
        const data = {
          page: 1,
          rowsPerPage: 10,
        };
        getAllAppointmentsList(data, activeTab,);
      }
      else {
        toast.error("Something went wrong", { position: "top-center" });
      }
    })
  }
  let info = JSON.parse(localStorage.getItem('pagination'))
  const navigateEdit = (e, id) => {
    window.renderFirst = false
    if (e.target.className == "capitalizeMedium") {
      navigate(`/profile-edit/${id.medium_id}`);
    }
    if (e.target.className == "capitalize") {
      if (id.client_user_type === 3) {
        navigate(`/edit/${id.client_id}`);
      } else {
        navigate(`/profile-edit/${id.client_id}`);
      }
    }
  };
  const checkAppId = (appoinment) => {
    if (appoinment.is_group_session === 0) {
      return appoinment.id
    }
    else if (appoinment.is_group_session === 1) {
      if (appoinment.attendees == undefined) {
        if (appoinment.parent_id == null) {
          return appoinment.id
        }
        else {
          return appoinment.parent_id
        }

      }
      else {
        return appoinment.id
      }

    }
    else {
      return appoinment.id
    }

  }
  return (
    <>
      <div className="d-flex justify-content-end gap-10 flex-wrap">
        <Search
          callApi={filterListAppointments}
          list={filterList}
          setSearchValues={setSearchValues}
          setFilterValues={setFilterValues}
          searchValue={searchValue}
          filterValues={filterValues}
          setOptionsList={setOptionsList}
          optionsList={optionsList}
          operatorList={operatorList}
          setOperatorList={setOperatorList}
          showOprater={showOprater}
          setShowOprater={setShowOprater}
          setFilterOprater={setFilterOprater}
          filterOprater={filterOprater} setFilterValueOptions={setFilterValueOptions} filterValueOptions={filterValueOptions} isOperator={1}
          isDatepickerShow={1}
          isAppointment={1} setServiceTypeShow={setServiceTypeShow}
          serviceTypeShow={serviceTypeShow} changeHandlerFilter={changeHandlerFilter}

        />


        {/* // <Filter
            //   list={serviceTypeList}
              
            //   filterValue={serviceFilterValue}
            //   name={'Service Type'} 
            // /> */}
        <Select
          id="serviceType"

          name={'Service Type'}
          className="selection-box"
          options={serviceTypeList}
          multi={true}
          isMulti
          onChange={(value) => changeHandlerFilter(value, 'upcoming')}
          placeholder="Service Type"
          value={serviceFilterValue}
          isClearable={true}
          closeMenuOnSelect={false}
          closeMenuOnScroll={false}
        />

        <Button
          status={0}
          type="button"
          onClick={filterReset}
          className={`resend-btn  width-80px  reset-btn-123`}
          title="Reset"
        />
      </div>
      {/* <CsvDownload
        href={
          `${process.env.REACT_APP_API_URL_EXPORT}` +
          "export-appoitment-list?appoitment_type=upcoming"
        }

      /> */}
      <Button
        status={0}
        type="button"
        onClick={() =>
          window.open(
            `${process.env.REACT_APP_API_URL_EXPORT}export-appoitment-list?appoitment_type=upcoming&appoitment_value=0`,
            '_blank'
          )
        }
        className="resend-btn min-width-135 create-app-sm-screen"
        title="Export Table"
      />

      {exportDownload !== "" && exportDownload.type === "upcoming" && (
        <Button
          status={0}
          type="button"
          onClick={() => window.open(`${exportDownload.path}`, '_blank')}
          className={`resend-btn min-width-210 create-app-sm-screen`}
          title="Download Export"
        />
      )}
      <Button
        status={0}
        type="button"
        onClick={() => {
          window.loadModal2("#CreateNewAppointment", "show");
        }}
        className={`resend-btn  min-width-210 create-app-sm-screen`}
        title="Create Appointment"
      />
      <div className="table-box-medium admin-appoi admin-upcoming-app overflow-scroll">
        <table className="caseTable">
          <tbody>
            <tr className="dark">
              <th className="min-width-100">Session #</th>
              <th>
                {/* <input type="checkbox" id="one" />
                <label htmlFor="one"></label> */}
                Medium Name
              </th>
              <th>Attendees</th>
              <th>Client Confirm</th>
              <th>
                {" "}
                <div className="d-inline-flex align-items-center align-items-center">
                  {" "}
                  <span>Date</span> <Sorting tab={1} callApi={filterListAppointments} view={'admin'} types={'date'} setSortingNameOrderBy={setSortingNameOrderBy}
                    setSortingTabType={setSortingTabType} />
                </div>
              </th>
              <th>
                <div className="d-inline-flex align-items-center align-items-center">
                  <span> Time</span>{" "}
                  <Sorting tab={1} callApi={filterListAppointments} view={'admin'} types={'time'} setSortingNameOrderBy={setSortingNameOrderBy}
                    setSortingTabType={setSortingTabType} />
                </div>
              </th>
              <th>Session Name</th>
              <th>Emails</th>
              <th>Meeting Link</th>
              <th>Cancel</th>
              <th>No Show</th>
              <th><div className="d-inline-flex align-items-center align-items-center">
                <span> Created at</span>{" "}
                <Sorting tab={1} callApi={filterListAppointments} view={'admin'} types={'created_at'} setSortingNameOrderBy={setSortingNameOrderBy}
                  setSortingTabType={setSortingTabType} />
              </div></th>
            </tr>
            {upcomingAppointments.length > 0 ? (
              upcomingAppointments.map((appoinment, index) => (
                <tr onClick={(e) => navigateEdit(e, appoinment)} className="innr">
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="mr-1 mt-1"><CopyComponent value={session_id_beg + checkAppId(appoinment) + session_id_end} /></div> <div className="mt-1">{session_id_beg + checkAppId(appoinment) + session_id_end}</div>
                      {appoinment.give_add1_highlight ? <img className="ml-1 star-app-hightlight" src="../images/star.png" /> : ''}
                      <div className="mr-1 mt-1 ml-1">
                        {appoinment.backfill_code !== null ? <>
                          ({appoinment.backfill_code})
                        </> : <></>
                        }
                      </div>
                    </div>
                  </td>
                  <td className="pointer">
                    {/* <input type="checkbox" id="two" />
                    <label htmlFor="two"></label> */}
                    <span className="capitalizeMedium">{appoinment.medium_name} {(appoinment.medium_comment === true) && (<b style={{
                      fontSize: '22px',
                    }}>*</b>)}</span>
                  </td>
                  <td className="pointer">
                    {console.log(appoinment.noOfAttendees)}
                    {appoinment.is_group_session !== 1 ?
                      <span className="capitalize">{
                        appoinment.client_name} {(appoinment.client_comment === true) && (<b style={{
                          fontSize: '22px',
                        }}>*</b>)}</span>
                      :
                      <span className="text-underline pointer p-1" onClick={() => openNoShowBox(appoinment.attendees, 'remove', appoinment)}>{appoinment.noOfAttendees.length}&nbsp;Attendee(s)</span>
                    }
                  </td>
                  <td>
                    <div className="min-width-70px ">
                      <Toggle
                        openConfirmAppointment={openConfirmAppointment}
                        item={appoinment}
                        setActiveUser={setActiveUser}
                      />
                    </div>
                  </td>
                  <td>
                    {appoinment.date}&nbsp;
                    {/* ,{appoinment.time} */}
                    {/* {moment
                      .utc(appoinment.date)
                      .local()
                      .format("LL")} */}
                  </td>
                  <td>
                    {appoinment.time}
                    {appoinment.session_name !==
                      "Donated - 30 Min Mediumship Session" && appoinment.is_group_session !== 1 ? (
                      <img
                        src="images/icon-feather-edit.png"
                        onClick={() => openReSchedulePopUp(appoinment, index)}
                        title="Reschedule this appointment"
                        className="pointer"
                      />
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="width-190px ">{appoinment.session_name}</td>
                  <td>
                    <div>
                      <p className="d-flex">
                        <div> Client:</div>{" "}
                        <div className="d5a67b ml-1 d-flex" >
                          {appoinment.client_email} <CopyComponent value={appoinment.client_email} />{" "}
                        </div>
                      </p>
                      <p className="d-flex">
                        {" "}
                        <div>Medium:</div>{" "}
                        <div className="d5a67b ml-1 d-flex">
                          {appoinment.medium_email} <CopyComponent value={appoinment.medium_email} />
                        </div>
                      </p>
                    </div>
                  </td>
                  {getTimeDiff(appoinment)}
                  <td className="googl-link min-width-210">
                    {appoinment.meeting_link === "" ||
                      appoinment.meeting_link === null ||
                      appoinment.meeting_link === "NULL" ? (
                      getTimeDiff(appoinment) ? (
                        <>
                          <Button
                            className={`resend-btn w-unset height-35 min-width-130px`}
                            title="Regenerate"
                            status={0}
                            onClick={() => regenerateZoom(appoinment.id)}
                          />
                        </>
                      ) : (
                        <>
                          <span className="w-unset loading-regenrate">
                            {"Generating Zoom link"}
                          </span>

                          <img
                            title="Refresh"
                            onClick={(e) => refreshScreen("refresh")}
                            className="googl-link-copy-img copy-image-animate refresh-btn-app mt-4"
                            alt="copy"
                            src="images/refresh.png"
                          />
                        </>
                      )
                    ) : (
                      <>
                        <a href={appoinment.meeting_link} target={"_blank"} >
                          {appoinment.meeting_link.substring(0, 20).concat("...")}
                        </a>
                        <CopyComponent value={appoinment.meeting_link} />
                      </>
                    )}
                    {/* <a
                      target={"_blank"}
                      className={`d-flex align-items-center justify-content-center resend-btn new-btn-click-here height-35 min-width-130px`}
                      href={appoinment.meeting_link}
                    >
                      Click Here
                    </a> */}
                    {/* <a
                      className="appointment-links"
                      href={appoinment.meeting_link}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {appoinment.meeting_link}
                    </a>
                    <img
                      onClick={() => copyMeetingLink(appoinment.meeting_link)}
                      className="googl-link-copy-img "
                      src="images/copy.png"
                      alt="copy"
                    /> */}
                  </td>
                  <td>
                    {appoinment.is_group_session !== 1 ?
                      <select
                        onChange={
                          (e) =>
                            changeHandlerLocal(e, 3, appoinment.appointmentId)
                          // changeHandler(e, 3, appoinment.appointmentId)
                        }
                        // value={appoinment.statusValue}
                        className="appointment-select-box"
                      >
                        <option value="" selected disabled>
                          Select
                        </option>
                        {Object.entries(appoinment.userArray).map((show) => (
                          <option value={show[0]}>{show[1]}</option>
                        ))}
                        <option value={id}>{screenNameHandler(first_name, last_name)}</option>
                      </select>
                      :
                      <>
                        <Button
                          status={0}
                          onClick={(e) => cancelGroupAppointment(appoinment.id)}
                          type="button"
                          className={`resend-btn w-130`}
                          title="Cancel"
                        />
                      </>
                    }
                  </td>
                  <td>
                    {appoinment.is_group_session !== 1 ?

                      <select
                        // value={appoinment.nostatusValue}
                        onChange={
                          (e) =>
                            changeHandlerLocal(e, 2, appoinment.appointmentId)
                          // changeHandler(e, 2, appoinment.appointmentId)
                        }
                        className="appointment-select-box"
                      >
                        <option value="" selected disabled>
                          Select
                        </option>
                        {Object.entries(appoinment.userArray).map((show) => (
                          <option value={show[0]}>{show[1]}</option>
                        ))}
                      </select>
                      :
                      <>
                        <Button
                          status={0}
                          onClick={() => openNoShowBox(appoinment.attendees, '', appoinment)}
                          type="button"
                          className={`resend-btn w-130`}
                          title="No Show"
                        />
                      </>

                    }
                  </td>
                  <td>{appoinment.createdDate}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Appointments Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* <div className="pagination-box">
          <div className="left-pagi">
            <span>Showing 1 to 10 of 18 entries</span>
          </div>
          <div className="right-pagi">
            <ul>
              <li>Previous</li>
              <li className="active">
                <a href="#">1</a>
              </li>
              <li>
                <a href="#">2</a>
              </li>
              <li>
                <a href="#">3</a>
              </li>
              <li>Next</li>
            </ul>
          </div>
        </div> */}
        <Pagination
          rowsPerPageOptions={[5, 10, 25]}
          count={allUpcomingCount}
          activeTab={"upcomingAdmin"}
          getPaginationData={getPaginationData}
          upcomingAppointmentsPage={upcomingAppointmentsPage}
          upcomingAppointmentsrowsPerPage={upcomingAppointmentsrowsPerPage}
        />
        <ConfirmCancel
          deleteUser={confirmRequest}
          cancelRequest={cancelRequest}
          id={"ConfirmCancelAdmin"}
        />
        <ConfirmDeleteModal
          deleteUser={confirmAppointment}
          id={"ConfirmAppointment"}
          label={"Are you sure ?"}
          btnLabel={"Continue"}
        />
        <CreateNewAppointment
          getAllFreeClients={getAllFreeClients}
          getMediumList={getMediumList}
          mediums={allMediums}
          clients={allClients}
          getAllAppointmentsList={getAllAppointmentsList}
          activeTab={activeTab}
          getSubsessions={getSubsessions}
          subsessionsList={subsessionsList}
          setAllMediums={setAllMediums}
          setAllClients={setAllClients}
        />

        <NoshowBox
          noShowActive={noShowActive}
          list={attendeesList}
          noShowRequest={noshowGroupAppointment}
          noshowRemoveGroupAppointment={noshowRemoveGroupAppointment}
          noshowFrom={noshowFrom}
        />
      </div>
      <ReScheduleAppointment
        // usersListMedium={usersListMedium}
        // usersListClient={usersListClient}
        reschedeId={reschedeId}
        reschedeData={reschedeData}
        getAllAppointmentsList={getAllAppointmentsList}
        activeTab={activeTab}
        setReschedeId={setReschedeId}
        setReschedeData={setReschedeData}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
      />
    </>
  );
}
