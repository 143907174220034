import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import LoggedOutRoutes from "./routes/loggedOutRoutes";
import LoggedInRoutes from "./routes/loggedInRoutes";
import MediumRoute from "./routes/mediumRoute";
import AdminRoutes from "./routes/adminRoute";
import MediumRouteApproved from "./routes/mediumRouteApproved";
import Layout from "./layout/layout";

import { setCredentials, setLoading, setRole, setUpAuthTrue } from "./reducer/auth/auth";

import "./App.css";
import { GridLoader } from "react-spinners";
import LoaderText from "./components/loader";

import ConfirAdvanceModal from "./pages/client/appointment/advanceappointment/confirmadvancemodal";
import CommonRoute from "./routes/commonroute";
import Help from "./components/help";

import "react-toastify/dist/ReactToastify.css";
import ClientRoute from "./routes/clientRoute";
import { fetchUser, getLimitunderMaintenance, logout } from "./services/auth";
import { setUser, setSaveTerms } from "./reducer/userSlice";
import { userType } from "./utils/utils";
import { getAllRequests } from "./services/admin";
import { ADMIN } from "./utils/utils";
import MediumIntegrationRoute from "./routes/mediumIntegrationRoute";
import CheckTimeZone from "./components/checkTimeZone";
import { useNavigate, useSearchParams } from "react-router-dom";
import AlertPopUp from "./components/alertpopup";
import moment from "moment-timezone";
import { getTimezones } from "./services/uidata";
import { filterVisibleTimeZones } from "./utils/helpers";
import UpdateTimeZone from "./components/updateTimeZone";
import MediumOnBoardingRoute from "./routes/mediumOnBoarding";
import { changeOnlineStatusFunc } from "./commonfunctions";


const App = (props) => {

  const { history } = props
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const redirect_to = searchParams.get("redirect_to")
  const from = searchParams.get("from");
  let currentPathname = window.location.pathname;
  let currentSearch = window.location.search

  const dispatch = useDispatch();
  const [underMaintenance, setunderMaintenance] = useState(0);
  const [newTimezoneData, setnewTimezoneData] = useState([]);

  const auth = useSelector((state) => state.auth);
  const { status, medium_status, provider, is_first_appointment, timezone, onboarding_completed, banner_display, timezone_id ,id } = useSelector(
    (state) => state.user.user
  );
  const underMaintenanceStatus = () => {
    getLimitunderMaintenance("under_maintenance")
      .then((data) => {
        if (data.data.data) {
          setunderMaintenance(parseInt(data.data.data.value));
        }
      })
      .catch(() => { });
  };

  useEffect(() => {
    console.log(redirect_to, "redirect_to");
    let token = localStorage.getItem('access_token')
    if (token && redirect_to && redirect_to == "medium-signup") {
      localStorage.setItem("medium-signup", redirect_to)
    }
    else {
      localStorage.removeItem("medium-signup")
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete("redirect_to");
      window.history.replaceState({}, document.title, newUrl.href);
    }
  }, [])

  useEffect(() => {
    let token = localStorage.getItem('access_token')
    if (token && redirect_to && redirect_to == "starter-booking") {
      console.log("auth.role", auth.role, redirect_to);

      if (auth.role == "medium" || auth.role == "admin") {
        localStorage.setItem('dorefresh', 'no')
        logoutUser('/login?redirect_to=starter-booking');
      }
      else {
        localStorage.setItem("redirect_to", redirect_to)
      }
    }
  }, [auth.role])

  useEffect(() => {
    let mediumSignup = localStorage.getItem("medium-signup")
    if (mediumSignup == "medium-signup") {
      console.log(mediumSignup, "mediumSignup")
      logoutUser();
    }
    else {
      localStorage.removeItem("medium-signup")
    }

  }, [localStorage])

  useEffect(() => {
    let sendBack = localStorage.getItem("sendBack")
    if (auth.role == "client" && sendBack) {
      localStorage.removeItem("sendBack")
      window.open(`${process.env.REACT_APP_API_URL_EXPORT}starter-intro/${id}`,'_self');
    }
  }, [auth.role,])
  //logout user
  const moveRoute = (url = "/registration") => {
    navigate(url);
    localStorage.clear();
    window.location.reload();
    dispatch(setUpAuthTrue());
  };
  const logoutUser = (url) => {
    console.log(url)
    //   let url = "/thanks";
    changeOnlineStatusFunc(0, dispatch);
    // dispatch(setLoading(true));
    logout()
      .then((data) => {
        // dispatch(setLoading(false));
        // if (data.data.status) {
        console.log(url)
        moveRoute(url);
        localStorage.removeItem("medium-signup")
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.delete("redirect_to");
        window.history.replaceState({}, document.title, newUrl.href);
        // }

      })
      .catch((err) => {
        // dispatch(setLoading(false));
        console.log(err);
      });
  };

  useEffect(() => {
    //
    if (currentPathname === '/appointment') {
      history.listen((newLocation, action) => {
        if (action === "PUSH") {
          if (
            newLocation.pathname !== currentPathname ||
            newLocation.search !== currentSearch
          ) {
            // Save new location
            currentPathname = newLocation.pathname;
            currentSearch = newLocation.search;

            // Clone location object and push it to history
            history.history.push({
              pathname: newLocation.pathname,
              search: newLocation.search
            });
          }
        } else {
          // Send user back if they try to navigate back
          history.go(1);
        }
      });
    }

    //


    underMaintenanceStatus();
    clearLocalStorage();
    dispatch(setCredentials());
    if (!auth.auth && status === undefined) callProfile();
  }, []);

  useEffect(() => {
    updateTimezones();
  }, []);

  //handlers
  const updateTimezones = () => {
    getTimezones()
      .then((data) => {
        if (data.data.status) {
          let items = data.data.data;
          setnewTimezoneData(items);
        }
      })
      .catch((err) => console.log(err));
  };
  // useEffect(() => {

  //   if (timezone !== "" && newTimezoneData.length > 0) {


  //     console.log('start')
  //     console.log(moment.tz.guess(), "moment.tz.guess()");
  //     console.log(newTimezoneData, "newTimezoneData");
  //     console.log(timezone, "timezone")


  //     let items = newTimezoneData.filter((item) => {
  //       if (item.front_name === timezone)
  //         return item;
  //     })
  //     console.log(items, "items")
  //     if (items.length == 1 && items[0].status === 0) {
  //       window.loadModal2("#updateTimeZoneId", "show");
  //     }
  //     else {
  //       window.loadModal2("#updateTimeZoneId", "hide");
  //     }
  //     if (items.length > 1 && checkTime(items, timezone_id)) {
  //       window.loadModal2("#updateTimeZoneId", "show");
  //     }
  //     else {
  //       window.loadModal2("#updateTimeZoneId", "hide");
  //     }

  //   }

  // }, [newTimezoneData, timezone])

  // const checkTime = (items, timezone_id) => {
  //   let time = items.filter((item) => {
  //     if (item.id == parseInt(timezone_id)) {
  //       return item;
  //     }
  //   })
  //   if (time[0].status == 1) {
  //     return false
  //   }
  //   else {
  //     return true
  //   }

  // }
  useEffect(() => {


    if (banner_display === 1) {
      window.loadModal2("#alertPopup", "show");
    }
    let user_status = medium_status;
    // setTimeout(() => {


    if (auth.role === "client" && user_status === 1 && (timezone === "" || timezone === null)) {
      {

        window.loadModal2("#checkTimeZone", "show");
      }
    }
    else {
      window.loadModal2("#checkTimeZone", "hide");
    }
    if (
      (auth.role === "medium" &&
        status === 1 &&
        user_status === 1 &&
        provider === "Zoom" && (timezone === "" || timezone === null))
    ) {
      window.loadModal2("#checkTimeZone", "show");
    }
    else {
      window.loadModal2("#checkTimeZone", "hide");
    }
    // }, 2000);

  }, [auth.role !== "", timezone])
  const clearLocalStorage = () => {
    let local_state_user = localStorage.getItem("user");
    let local_state_medium_status = localStorage.getItem("medium_status");
    let local_state_status = localStorage.getItem("status");
    let local_state_role = localStorage.getItem("role");
    if (
      local_state_user !== null ||
      local_state_medium_status !== null ||
      local_state_status !== null ||
      local_state_role !== null
    ) {
      localStorage.clear();
      window.location.reload();
    }
  };
  useEffect(() => {
    if (auth.role === "admin") {
      getRequests();
    }
  }, [auth]);
  const getRequests = () => {
    getAllRequests()
      .then((data) => {
        if (data.data.status) {
          ADMIN.map((item) => {
            if (item.name === "Requests" && data.data.data.length > 0) {
              item.count = data.data.data.length;
            }
          });
        }
      })
      .catch(() => { });
  };
  const callProfile = async (token) => {
    dispatch(setLoading(true));
    await fetchUser(token)
      .then((data) => {
        dispatch(setLoading(false));
        dispatch(setUser(data.data.data));
        // localStorage.setItem("user", JSON.stringify(data.data.data));
        // localStorage.setItem("medium_status", data.data.data.medium_status);
        // localStorage.setItem("status", data.data.data.status);
        renderUserRole(data.data.data.usertype, data.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };
  const renderUserRole = (type, users) => {
    const user = userType(type);
    dispatch(setRole(user));
  };
  const renderUserType = (from) => {
    // let status = localStorage.getItem("status");
    // let user_status = localStorage.getItem("medium_status");
    // console.log(auth);
    // console.log(status, "useruser");
    // console.log(localStorage.getItem("status"), "localStorage.getItem(status)");

    let user_status = medium_status;
    if (
      auth.role === "medium" &&
      status === 1 &&
      user_status === 1 &&
      (
        onboarding_completed === 1 ||
        from === "onboarding") &&
      provider === "Zoom"
    ) {
      return <Layout children={

        <><MediumRouteApproved />
          <AlertPopUp />
        </>} />;
    }
    //
    else if (
      auth.role === "medium" &&
      status === 1 &&
      user_status === 1 &&
      onboarding_completed === 0
      &&
      from !== "onboarding"
      // (provider === "" || provider === null)
    ) {
      return <MediumOnBoardingRoute />;
    }
    else if (auth.role === "medium" &&
      status === 1 &&
      user_status === 1 &&

      onboarding_completed === 1 && (provider === "" || provider === null)) {
      return <MediumIntegrationRoute />

    }
    else if (
      auth.role === "medium" &&
      ((status === 0 && user_status === 0) ||
        (status === 0 && user_status === 1))
    ) {
      return <MediumRoute />;

    } else if (auth.role === "client" && user_status === 1) {
      // if (window.location.pathname === "/appointment") {
      //   return <LoggedInRoutes />;
      // }
      // if (window.location.pathname !== "/appointment") {
      return (
        <Layout
          children={
            <>
              <LoggedInRoutes is_first_appointment={is_first_appointment} from={from} />
              <AlertPopUp />
              <Help />
            </>
          }
        />
      );
      // }
    } else if (auth.role === "admin") {
      return <Layout children={<AdminRoutes />} />;
    } else if (auth.role === "client" && user_status === 0) {
      return <ClientRoute />;
    } else {
      return (
        <div className="min-height-80vh d-flex align-items-center justify-content-center">
          <div className="">
            <img src="../images/logo-verysoul.png" alt="logo" />
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      {window.location.pathname === "/terms-conditions" ||
        window.location.pathname === "/privacy-policy" ||
        window.location.pathname === "/starter-volunteer-onboarding"
        ? (
          <CommonRoute />
        ) : (
          <>
            {auth.auth ? <LoggedOutRoutes /> : renderUserType(from)}
            {auth.isLoading && (
              <div className="spinner">
                <GridLoader size={50} />
              </div>
            )}
            {auth.isLoadingText && (
              <LoaderText text={auth.loadingContent} />
            )}


            <div className="continue ">
              <div className="dont-have-acc display-unset">
                <UpdateTimeZone newTimezoneData={newTimezoneData} />
                <CheckTimeZone />
                <ConfirAdvanceModal />
              </div>
            </div>
          </>
        )}
      <ToastContainer />
    </div>
  );
};

export default App;
