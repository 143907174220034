import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import VideoPopover from "../popovers";
import { setLoading, setRole, setUpAuth } from "../../../reducer/auth/auth";
import { fetchUser, login } from "../../../services/auth";
import API from "../../../api/api";
import { setUser } from "../../../reducer/userSlice";
import { userType } from "../../../utils/utils";
import Slider from "../../../components/slider";
import { getBrowserData } from "../../../utils/helpers";
import {
  sendVerificationCode,
} from "../../../services/auth";

const RegisterMedium = () => {
  const [emailError, setEmailError] = useState("");
  const [popover, setPopover] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [typePass, SetTypePass] = useState(true);
  const [confirmtypePass, SetTypeconfirmPass] = useState(true);
  const [oldEmail, setOldEmail] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [backupData, setBackupData] = useState();

  const changePassTypeConfirm = (e) => {
    SetTypeconfirmPass(!confirmtypePass);
  };
  const changePassType = (e) => {
    SetTypePass(!typePass);
  };
  useEffect(() => {
    window.loadCarousel();
  }, []);
  const LoginUser = async (formData) => {
    // dispatch(setLoading(true));
    let item = {
      email: formData.email,
      password: formData.password,
    };
    let browserinfo = await getBrowserData();
    Object.assign(item, browserinfo);
    login(item)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          localStorage.setItem("access_token", data.data.data.access_token);
          dispatch(setUpAuth());
          callProfile(data.data.data.access_token);
          sendVerificationCodeHandler();
        }
        if (
          !data.data.status &&
          data.data.error &&
          data.data.error !== "Unauthorized"
        ) {
          setEmailError(data.data.error);
        }
        if (!data.data.status && data.data.error === "Unauthorized") {
          setEmailError("Invalid Email or Password");
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };
  const sendVerificationCodeHandler = () => {

    let body = {
      verification_code: "",
    };
    sendVerificationCode(body)
      .then((data) => {
        if (
          !data.data.status &&
          data.data.message === "OTP sent Successfully"
        ) {
          console.log("OTP sent Successfully");
        }
      })
      .catch((data) => {
        console.log(data);
      });
  };

  const callProfile = async (token) => {
    dispatch(setLoading(true));
    await fetchUser(token)
      .then((data) => {
        dispatch(setLoading(false));
        dispatch(setUser(data.data.data));
        // localStorage.setItem("user", JSON.stringify(data.data.data));
        // localStorage.setItem("medium_status", data.data.data.medium_status);
        // localStorage.setItem("status", data.data.data.status);
        renderUserType(data.data.data.usertype, data.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  const renderUserType = (type, users) => {
    const user = userType(type);
    // localStorage.setItem("role", user);
    dispatch(setRole(user));
    if (user === "medium") {
      if (users.status === 1 && users.medium_status === 1) {
        navigate("/dashboard");
      } else {
        navigate("/profile");
      }
    }
    if (user === "admin") {
      navigate("/dashboard");
    }
    if (user === "client") {
      navigate("/dashboard");
    }
  };
  const RegisterUser = async (formData) => {
    setBackupData(formData);
    dispatch(setLoading(true));
    let item = {
      email: formData.email,
      password: formData.password,
      password_confirmation: formData.confirmPassword,
      is_adult: formData.check ? 1 : 0,
      usertype: 2,

    };
    if(formData.override_client){
      Object.assign(item, { override_client: 1 })
    }
    let browserinfo = await getBrowserData();
    Object.assign(item, browserinfo);
    API.post("register", item)
      .then((data) => {
        // dispatch(setLoading(false));
        if (data.data.status) {
          LoginUser(formData);
          // window.openModal();
        }
        console.log(data.data, "data.data")
        if (!data.data.status && data.data.data.is_client == 1) {
          console.log('dewjndjknejd')
          window.loadModal2('#RegisterasMediumPopup', 'show')
          setOldEmail(data.data.data.from_email);
          setNewEmail(data.data.data.to_email);
        }
        if (!data.data.status && data.data.error.email) {
          dispatch(setLoading(false));
          setEmailError("This email address already exists in our system.");
        }
        if (
          !data.data.status &&
          data.data.error &&
          data.data.error === "Your Account is Restricted/Freezed"
        ) {
          dispatch(setLoading(false));
          setEmailError("You are not allowed to create new accounts. Please contact support for more help.");
        }
      })
      .catch((error) => {
        dispatch(setLoading(false));
        console.log(error);
      });
  };
  const redirectRoute = (item) => {
    navigate(item);
  };

  const newEmailHandler = () => {
    let newData = Object.assign(backupData, { override_client: 1 });
    console.log(newData, "newData");
    RegisterUser(newData)
  }
  return (
    <>
      <div className="login-registerheader">
        <div className="container">
          <div className="logo-top">
            {" "}
            <img src="images/logo-verysoul.png" alt="logo" />
          </div>
          <div className="right-login-register">
            <p>
              Already have an account?<Link to={"/login"}>Login</Link>
            </p>
          </div>
        </div>
      </div>

      <div className="login-register-saction register-medium">
        {popover && (
          <VideoPopover
            src={"https://vimeo.com/678206499/ba93cdbfe1"}
            close={() => setPopover(false)}
          />
        )}
        <div className="container black-container">
          <div className="row">
            <div className="col-md-6 ">
              <Slider />
            </div>

            <div className="col-md-6 align-items-center d-flex">
              <div className="login-saction-right register-right">
                <div className="logo">
                  <img src="images/logo-verysoul.png" alt="logo" />
                </div>
                <h2>Register as Medium</h2>
                {emailError === "This email address already exists in our system." &&
                  <div className="dont-have-acc margin-unset">
                    <p>
                      <span className="red font-size-17">{emailError}</span><br />
                      <Link to="/login">Click here to Log in.</Link>
                    </p>
                  </div>
                }
                {emailError === "You are not allowed to create new accounts. Please contact support for more help." &&
                  <div className="dont-have-acc margin-unset">
                    <p>
                      <span className="red font-size-17">{emailError}</span><br />
                    </p>
                  </div>
                }

                <div className="form-l---r">
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                      confirmPassword: "",
                      check: false,
                    }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.email) {
                        errors.email = "Required";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address";
                      }
                      // if (!values.username) errors.username = "Required";
                      if (!values.password) errors.password = "Required";
                      if (values.password && values.password.length < 6)
                        errors.password = "Minimum length 6 character";
                      if (
                        values.password &&
                        !/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(
                          values.password
                        )
                      ) {
                        errors.password =
                          "Password must be alphanumeric with one uppercase and one special character";
                      }
                      if (!values.confirmPassword)
                        errors.confirmPassword = "Required";
                      if (values.password !== values.confirmPassword)
                        errors.confirmPassword = "password didn't matched";
                      if (!values.check) {
                        errors.check = "Required";
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      RegisterUser(values);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="from-fild">
                          <div className="icon">
                            <img src="images/mail.png" alt="email" />
                          </div>
                          <label>
                            Email Address<i>*</i>
                          </label>
                          <input
                            className={`${errors.email && touched.email && errors.email
                              ? "error-boundry"
                              : ""
                              }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            type="email"
                            id="email"
                            name="email"
                            placeholder="myemail@address.com"
                          />
                          <span className="error">
                            {errors.email && touched.email && errors.email}
                          </span>
                        </div>

                        <div className="from-fild">
                          <div className="icon">
                            <img src="images/pasword.png" alt="lock" />
                          </div>
                          <label>
                            Password<i>*</i>
                          </label>
                          <input
                            className={`${errors.password &&
                              touched.password &&
                              errors.password
                              ? "error-boundry"
                              : ""
                              }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            id="password"
                            type={typePass ? "password" : "text"}
                            name="password"
                            placeholder="Password"
                          />
                          <div className="icon-right">
                            <img
                              className={`pointer ${typePass ? "" : "openeye"}`}
                              onClick={changePassType}
                              src={
                                typePass
                                  ? "images/eye.png"
                                  : "localImages/openeye.svg"
                              }
                              alt="eye"
                            />
                          </div>
                          <span className="error">
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </span>
                        </div>

                        <div className="from-fild">
                          <div className="icon">
                            <img src="images/pasword.png" alt="lock" />
                          </div>
                          <label>
                            Confirm Password<i>*</i>
                          </label>
                          <input
                            className={`${errors.confirmPassword &&
                              touched.confirmPassword &&
                              errors.confirmPassword
                              ? "error-boundry"
                              : ""
                              }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            id="confirmPassword"
                            type={confirmtypePass ? "password" : "text"}
                            name="confirmPassword"
                            placeholder="Confirm Password"
                          />
                          <div className="icon-right">
                            <img
                              className={`pointer ${confirmtypePass ? "" : "openeye"
                                }`}
                              onClick={changePassTypeConfirm}
                              src={
                                confirmtypePass
                                  ? "images/eye.png"
                                  : "localImages/openeye.svg"
                              }
                              alt="eye"
                            />
                          </div>
                          <span className="error">
                            {errors.confirmPassword &&
                              touched.confirmPassword &&
                              errors.confirmPassword}
                          </span>
                        </div>

                        <div className="check-box">
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            checked={values.check}
                            type="checkbox"
                            id="check"
                            name="check"
                          />{" "}
                          <label htmlFor="check">I am over 18</label>
                        </div>
                        <span className="error">
                          {errors.check && touched.check && errors.check}
                        </span>

                        <div className="bution">
                          <button type="submit" className="btn btn-info btn-lg">
                            Sign Up
                          </button>
                          <div
                            data-backdrop="static"
                            className="modal fade"
                            id="myModal"
                            role="dialog"
                          >
                            <div className="modal-dialog modal-dialog-preview">
                              <div className="modal-content">
                                <div className="modal-body">
                                  <div className="icon-pop">
                                    <img
                                      src="images/Union-icon.png"
                                      alt="Union-icon"
                                    />
                                  </div>
                                  <h3>
                                    Begin Registration to <br />
                                    Be a Verysoul Medium
                                  </h3>
                                  <p>
                                    You have successfully created your account!
                                    Please proceed with the logins and fill the
                                    other required info.
                                  </p>
                                  <div
                                    className="register-medium-confirnation"
                                    onClick={() => redirectRoute("/thank-you")}
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="dont-have-acc">
                          <p>
                            Already have an account?{" "}
                            <Link to="/login">Login</Link>
                          </p>
                        </div>

                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <p className="text-center"><b>Note: For optimum performance: use Chrome, Safari, or Edge browser</b></p>
      <div className="confirm-delete-wrapper register-medium">
        <div data-backdrop="static" className="modal fade" id={'RegisterasMediumPopup'} role="dialog">
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-body m-4">
                {/* <div className="icon-pop">
                  <img src="images/Union-icon.png" alt="Union-icon" />
                </div> */}
                <h3 className="">
                  You are already registered as client. Do you want to replace <b className="text-underline">{oldEmail}</b> to <b className="text-underline">{newEmail}</b> in your client account ?</h3>
                <div className="confirm-btn-box">
                  <div
                    className="register-medium-confirnation model-close-btn"
                    onClick={() => window.loadModal2('#RegisterasMediumPopup', 'hide')}
                    data-dismiss="modal"
                  >
                    No
                  </div>
                  <div
                    className="register-medium-confirnation"
                    data-dismiss="modal"
                    onClick={newEmailHandler}
                  >
                    Yes
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RegisterMedium;
