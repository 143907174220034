const UpcomingHeaders = [
  { id: 9, title: "Session #" },
  { id: 1, title: "Meeting Link" },
  { id: 2, title: "Medium Name" },
  { id: 7, title: "Session Name" },
  // { id: 8, title: "Session Name" },
  { id: 3, title: "Date" },
  { id: 4, title: "Time" },
  { id: 5, title: "Pay My Medium" },
  { id: 6, title: "Cancel/Reschedule" },
  { id: 8, title: "Session Issues" }
];

const completedHeaders = [
  { id: 9, title: "Session #" },
  { id: 1, title: "Medium Name" },
  { id: 7, title: "Session Type" },
  { id: 8, title: "Session Name" },
  { id: 2, title: "Date" },
  { id: 3, title: "Time" },
  // { id: 4, title: "No Show" },
  { id: 6, title: "Complete Survey" },
  { id: 5, title: "Pay Medium" },
  { id: 8, title: "Session Issues" },
  { id: 10, title: "Actions" },
];
const CancelledHeaders = [
  { id: 6, title: "Session #" },
  { id: 1, title: "Medium Name" },
  { id: 5, title: "Session Type" },
  { id: 2, title: "Date" },
  { id: 3, title: "Time" },
  { id: 4, title: "Cancelled By" },
];
const NoShowsHeaders = [
  { id: 6, title: "Session #" },
  { id: 1, title: "Medium Name" },
  { id: 5, title: "Session Type" },
  { id: 2, title: "Date" },
  { id: 3, title: "Time" },
  { id: 4, title: "No show By" },
];
const list = [
  { id: 1, name: "Upcoming", count: 0 },
  { id: 2, name: "Completed", count: 0 },
  { id: 3, name: "Cancelled", count: 0 },
  { id: 3, name: "No Shows", count: 0 },
];
export {
  UpcomingHeaders,
  list,
  completedHeaders,
  CancelledHeaders,
  NoShowsHeaders,
};
