import React, { useEffect, useState } from "react";
import moment from "moment";
// import Search from "../../../../components/search";
import Button from "../../../../../components/button";
import Pagination from "../../../../../components/tablePagination";
import { useSelector } from "react-redux";
import PayOptionsPopUp from "../../../../../components/payoptionspopup";
import {
  doAPayItForwardPayment,
  payWithPaypal,
  payWithPaypalCompleted,
  payWithSquareCompleted,
  payWithStripe,
  payWithStripeCompleted,
} from "../../../../../services/client";
import ConfirmCancel from "../../../../admin/appointments/subcomponents/cancelpopup";
import { emptyAllSelectBox, session_id_beg, session_id_end } from "../../../../../utils/helpers";
import CheckPayOptionsPopUp from "../../components/checkpaymentoptions";
import PaymentPrices from "../../../appointment/premiumsessions/practitonerInfo/payment/payment";
import GiftYourMediums from "../../components/giftyourmediums";
import { toast } from "react-toastify";
import PayItForwardPopUp from "../../components/payitforward";
import ZoomLinkPopup from "../../../../medium/appointments/subcomponetnts/zoomlinkpopup";
// import { getLink } from "../../../../../utils/helpers";
export default function Completed(props) {
  const {
    tableData = [],
    changeHandler,
    onClickHandler,
    tableHeaders = [],
    // filterListAppointments,
    openSurveyLink,
    getPaginationData,
    blinkRequest,
    setFilterValues,
    setSearchValues,
    setCounterStatus
  } = props;
  const { allCompletedCount } = useSelector((state) => state.client);
  const { id } = useSelector(
    (state) => state.user.user
  );
  const [activePay, setActivePay] = useState("");
  const [checkBlink, setcheckBlink] = useState();
  const [active, setActive] = useState({});
  const [paymentAmount, setPaymentAmount] = useState('');
  const [paymentCurrency, setPaymentCurrency] = useState('USD');
  const [paymentType, setPaymentType] = useState("");
  const [paymentstatus, setPaymentstatus] = useState(false);
  const [payItforwardValue, setpayItforwardValue] = useState('');
  const [payItforwardFixPrice, setpayItforwardFixPrice] = useState("")
  const [activeContact, setactiveContact] = useState('');

  useEffect(() => {

    console.log(openSurveyLink, "openSurveyLink");
    checkBlinkType(blinkRequest);
    setTimeout(() => {
      setcheckBlink();
    }, 10000);
  }, [blinkRequest]);
  const checkBlinkType = (blink, index) => {
    if (blink === "givefeedback") {
      setcheckBlink(1);
    }
    if (blink === "paymedium") {
      setcheckBlink(2);
    }
  };
  const payItForwardHandler = () => {
    let appoinmentType;
    if (activePay.session_type == "5") {
      appoinmentType = 'donated'
    } else {
      appoinmentType = 'payafter'
    }
    let body = {
      medium_id: activePay.medium_id,
      appointment_id: activePay.id,
      amount: payItforwardValue,
      appointment_type: appoinmentType
    }
    doAPayItForwardPayment(body).then((data) => {
      if (data.data.status) {
        window.open(data.data.data.approvedUrl, "_self");
        // openWaitingContainer();
      }
    }).catch(() => {

    })
  }
  const openPaymentModal = (item) => {
    setActivePay(item);
    if (item.is_pay_forward) {
      if (item.payitforward_pricing !== null) {
        const cleanString = item.payitforward_pricing.slice(1, -1); // remove the outer quotes
        const parsedString = cleanString.replace(/\\/g, ''); // remove backslashes

        // Step 2: Parse the cleaned string into an object
        let parsedPricing;
        try {
          parsedPricing = JSON.parse(parsedString);
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
        let priceValue = parsedPricing[item.duration];
        setpayItforwardValue(item.payitforward_pricing !== null ? priceValue : '');
        setpayItforwardFixPrice(item.payitforward_pricing !== null ? priceValue : '');
        window.loadModal2("#PayItForwardPopUp", "show");
      } else {
        setpayItforwardFixPrice(item.payitforward_pricing !== null ? '' : '');
        setpayItforwardValue(item.payitforward_pricing !== null ? '' : '');
        window.loadModal2("#f", "show");
      }

      return;
    }
    window.loadModal2("#payoptionspopup", "show");
  };

  const openPaymentModalIfYouWish = (item) => {
    if (item.is_pay_forward) {
      openPaymentModal(item);
    } else {
      setActivePay(item);
      setPaymentstatus(!paymentstatus);
      window.loadModal2("#GiftYourMediums", "show");
    }
  }
  const runCallback = (type) => {

    console.log(activePay, "")
    let body = {
      id: activePay.id,
      medium_id: activePay.medium_id,
      amount: activePay.amount,
      currency: activePay.currency,
      appointment_type: 'payafter',
      client_id: id,
    };
    if (type === "paypal") {
      payWithPaypalCompleted(body)
        .then((data) => {
          console.log(data.data, "cdqe")
          if (data.data.status) {
            setCounterStatus(true)
            // window.loadModal2("#payoptionspopup", "hide");
            window.open(data.data.data.approvedUrl, "_self");
          }
          else {
            toast.error(data.data.message, {
              position: "top-center",
            });
          }
        })
        .catch(() => { });
    } else if (type === "stripe") {
      payWithStripeCompleted(body)
        .then((data) => {
          if (data.data.status) {
            setCounterStatus(true);
            // window.loadModal2("#payoptionspopup", "hide");
            window.open(data.data.data.approvedUrl, "_self");
          }
          else {
            toast.error(data.data.message, {
              position: "top-center",
            });
          }
        })
        .catch(() => { });
    }
    else {
      payWithSquareCompleted(body)
        .then((data) => {
          if (data.data.status) {
            setCounterStatus(true);
            // window.loadModal2("#payoptionspopup", "hide");
            window.open(data.data.data.approvedUrl, "_self");
          }
          else {
            toast.error(data.data.message, {
              position: "top-center",
            });
          }
        })
        .catch(() => { });
    };
  }
  const handleNoShow = (e, appoinment) => {
    let value = e.target.value;
    if (value === "me") {
      changeHandler(
        e,
        2,
        appoinment.appointmentId,
        appoinment.client_id,
        "client",
        "client_id"
      );
    } else {
      changeHandler(
        e,
        2,
        appoinment.appointmentId,
        appoinment.medium_id,
        "client",
        "medium_id"
      );
    }
  };

  const changeHandlerLocal = (e, type, id, targetId, targetType, targetTypeID) => {
    setActive({ e, type, id, targetId, targetType, targetTypeID })
    window.loadModal2('#ConfirmCancelcompletedclient', 'show');
  }

  const cancelRequest = () => {
    window.loadModal2('#ConfirmCancelcompletedclient', 'hide');
    emptyAllSelectBox()
  }
  const confirmRequest = () => {
    let { e, type, id, targetId, targetType, targetTypeID } = active
    console.log(e, type, id, targetId, targetType, targetTypeID);
    changeHandler(e, type, id, targetId, targetType, targetTypeID)
  }

  const priceCheck = (item) => {
    let prices = item.amount
    let cur = item.currency;
    // let prices = item.session_length !== undefined && item.session_length !== "" && item.session_length !== null ? Object.values(
    //   JSON.parse(item.session_length)
    // )[0] : 20
    // let cur = item.session_currency !== undefined && item.session_currency !== "" && item.session_currency !== null ? Object.values(
    //   JSON.parse(item.session_currency)
    // )[0] : 'USD'
    let price = prices !== "" && prices !== null ? prices : 20
    let currency = cur !== "" && cur !== null ? cur : 'USD'
    return `Pay $ ${price} ${currency} `
  }
  const paymentMethodHandler = (type) => {

    let body = {
      id: activePay.id,
      medium_id: activePay.medium_id,
      amount: paymentAmount,
      currency: paymentCurrency,
      appointment_type: 'pay_if_wish',
      client_id: id,
    };
    if (type === "paypal") {
      payWithPaypalCompleted(body)
        .then((data) => {
          if (data.data.status) {
            // setCounterStatus(true)
            // window.loadModal2("#payoptionspopup", "hide");
            // setCounterStatus(true)
            window.open(data.data.data.approvedUrl, "_self");
            // window.loadModal2('#GiftYourMediums', "hide");

          }
          else {
            toast.error(data.data.message, {
              position: "top-center",
            });
          }
        })
        .catch(() => { });
    } else if (type === "stripe") {
      payWithStripeCompleted(body)
        .then((data) => {
          if (data.data.status) {
            // setCounterStatus(true);
            window.open(data.data.data.approvedUrl, "_self");
            // window.loadModal2("#GiftYourMediums", "hide");
          }
          else {
            toast.error(data.data.message, {
              position: "top-center",
            });
          }
        })
        .catch(() => { });
    }
    else {
      payWithSquareCompleted(body)
        .then((data) => {
          if (data.data.status) {
            // setCounterStatus(true);
            window.open(data.data.data.approvedUrl, "_self");
            // window.loadModal2("#GiftYourMediums", "hide");
          }
          else {
            toast.error(data.data.message, {
              position: "top-center",
            });
          }
        })
        .catch(() => { });
    };
  }
  const changesubmissionIssue = (e, appoinment) => {
    let value = e.target.value;
    console.log(value, "value")
    if (value === "no-show") {


      changeHandlerLocal(
        e,
        2,
        appoinment.appointmentId,
        appoinment.medium_id,
        "client",
        "medium_id"
      );

    }
    else if (value === "mail") {
      window.open("mailto:mailto:concerns@verysoul.com")
    }
  }

  const openZoomLinkPopup = (item) => {
    window.loadModal2("#ZoomLinkPopup", "show")
    setactiveContact(item)
  }
  return (
    <>
      <div className="d-flex justify-content-end gap-10 flex-wrap">
        {/* <Search callApi={filterListAppointments} list={filterList} /> */}
      </div>
      <div className="table-box-medium admin-appoi">
        <table className="caseTable">
          <tbody>
            <tr className="dark">
              {React.Children.toArray(
                tableHeaders.map((item) => <th>{item.title}</th>)
              )}
            </tr>
            {tableData.length > 0 ? (
              tableData.map((appoinment, index) => (
                <>
                  {appoinment.transaction_status != 1 && appoinment.session_type == 8 ? <></> :
                    <tr className="innr">
                      <td>{session_id_beg + appoinment.id + session_id_end}</td>
                      <td>
                        <span className="capitalize">
                          {" "}
                          {appoinment.medium_name}
                        </span>
                      </td>
                      <td>{appoinment.session_name}</td>
                      <td>
                        {appoinment.session_type === "5" || appoinment.session_type === "3"
                          ? "Free"
                          : appoinment.session_type === "7"
                            ? "Pay After"
                            : appoinment.session_type === "8"
                              ? "Pre Pay"
                              : ""}
                      </td>
                      <td>{appoinment.date}</td>
                      <td> {appoinment.time}</td>

                      {/* <td>
                    <select
                      onChange={(e) => handleNoShow(e, appoinment)}
                      className="appointment-select-box"
                    >
                      <option selected disabled>
                        Select
                      </option>

                      <option value={"me"}>{"No Show By Me"}</option>
                      <option value={"other"}>{"No Show By Other"}</option>
                    </select>
                  </td> */}
                      <td>
                        {appoinment.survey_link !== "" &&
                          appoinment.survey_status === "completed" ? (
                          <div className="color-d5a67b d-flex align-items-center height-45"> Completed</div>
                        ) : (
                          <Button
                            status={0}
                            onClick={(e) => openSurveyLink(appoinment.survey_link)}
                            type="button"
                            className={`resend-btn w-130 ${checkBlink === 1 && index === 0 ? "alerts-border" : ""
                              }`}
                            title="Take Survey"
                          />
                        )}
                      </td>
                      <td>
                        {" "}
                        {appoinment.session_type !== "5" && appoinment.session_type !== "3" ? (
                          appoinment.amount !== "0" ? (
                            appoinment.transaction_status === null ||
                              appoinment.transaction_status === 0 ? (
                              <Button
                                status={0}
                                onClick={() => openPaymentModal(appoinment)}
                                type="button"
                                className={`resend-btn w-150 ${checkBlink === 2 && index === 0
                                  ? "alerts-border"
                                  : ""
                                  }`}
                                title={appoinment.is_pay_forward ? 'Pay' : priceCheck(appoinment)}
                              />
                            ) : (
                              <span className="color-d5a67b ml-5"> Paid</span>
                            )
                          ) :
                            (
                              <span className="color-d5a67b ml-5"> Paid</span>
                            )
                        ) :

                          appoinment.display_button ?

                            <Button
                              status={0}
                              onClick={() => openPaymentModalIfYouWish(appoinment)}
                              type="button"
                              className={`resend-btn w-150 ${checkBlink === 2 && index === 0
                                ? "alerts-border"
                                : ""
                                }`}
                              title={'Pay if you wish'}
                            />
                            :
                            appoinment.transaction_status === null ||
                              appoinment.transaction_status === 0 ?
                              <span className="color-d5a67b ml-5"> Free</span>
                              :
                              <span className="color-d5a67b ml-5"> Paid</span>

                        }

                      </td>

                      {/* <td className="dot-image">
                        <img
                          onClick={(e) =>
                            onClickHandler(e, appoinment.appointmentId, "completed")
                          }
                          src="../images/Unionblck.png"
                          className={`pointer dot-image-inside`}
                          alt="dots"
                        />
                        {appoinment.status && (
                          <div className="approve-btn-1 approve-btn z-index-1">
                            <span
                              className="w-100"
                              onClick={(e) => {
                                changeHandlerLocal(
                                  e,
                                  2,
                                  appoinment.appointmentId,
                                  appoinment.medium_id,
                                  "client",
                                  "medium_id"
                                );

                              }}
                            >
                              No Show
                            </span>
                          </div>
                        )}
                      </td> */}
                      <td>
                        <select
                          onChange={(e) => changesubmissionIssue(e, appoinment, index)}

                          className="appointment-select-box"
                        >
                          <option value="" selected disabled>
                            Select
                          </option>
                          {appoinment.is_no_show && <option
                            value={'no-show'}
                          >
                            No Show
                          </option>}


                          <option value={'mail'}> <a
                            href={"mailto:concerns@verysoul.com"}
                            target="_blank"
                            className="report-url"
                          >
                            Report
                          </a></option>


                        </select>
                      </td>
                      <td className="dot-image">
                        <img
                          onClick={(e) =>
                            onClickHandler(e, appoinment.appointmentId, "completed")
                          }
                          src="../images/Unionblck.png"
                          className="pointer dot-image-inside"
                          alt="dots"
                        />
                        {appoinment.status && (
                          <div className="approve-btn-1 approve-btn z-index-1">
                          {(appoinment.meeting_link !== 'NULL' && appoinment.meeting_link !== ''&& appoinment.is_zoom_link == "1") &&   <span className="w-100 anchor-white ">
                              <a className="width-80px d-inline-block" onClick={() => openZoomLinkPopup(appoinment)}
                                href={`javascript:void();`}>
                                Find Zoom
                              </a>
                            </span>}
                          </div>
                        )}
                      </td>
                    </tr>
                  }
                </>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Appointments Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <PayOptionsPopUp activePay={activePay} from="appointments" runCallback={runCallback} />
        <Pagination
          rowsPerPageOptions={[5, 10, 25]}
          count={allCompletedCount}
          activeTab={"completed"}
          getPaginationData={getPaginationData}
        />
      </div>

      <ConfirmCancel
        deleteUser={confirmRequest}
        cancelRequest={cancelRequest}
        id={'ConfirmCancelcompletedclient'}
      />
      <CheckPayOptionsPopUp activePay={activePay} setPaymentType={setPaymentType} />
      <GiftYourMediums
        activePay={activePay}
        paymentMethodHandler={paymentMethodHandler}
        setPaymentAmount={setPaymentAmount}
        setPaymentCurrency={setPaymentCurrency}
        paymentstatus={paymentstatus}
      />
      <PayItForwardPopUp
        activePay={activePay}
        paymentMethodHandler={payItForwardHandler}
        setpayItforwardValue={setpayItforwardValue}
        setPaymentCurrency={setPaymentCurrency}
        payItforwardFixPrice={payItforwardFixPrice}
        paymentstatus={paymentstatus}
        payItforwardValue={payItforwardValue}
      />
      <ZoomLinkPopup
        activeContact={activeContact}
      />
    </>
  );
}
