import React, { useEffect, useState } from "react";
import moment from "moment";
// import Search from "../../../../components/search";
import { useSelector } from "react-redux";
import Pagination from "../../../../../components/tablePagination";
import Button from "../../../../../components/button";
import CopyComponent from "../../../../../components/copyComponent";
import { emptyAllSelectBox, getLink, session_id_beg, session_id_end } from "../../../../../utils/helpers";
import PayOptionsPopUp from "../../../../../components/payoptionspopup";
import {
  payWithPaypalCompleted,
  payWithSquareCompleted,
  payWithStripeCompleted,
} from "../../../../../services/client";
import ConfirmCancel from "../../../../admin/appointments/subcomponents/cancelpopup";
import { confirmAppointmentstatus, getAppointmentsDetails } from "../../../../../services/admin";
import RescheduleAppointmentNotAllowModel from "../../../appintmentNotAllow/rescheduleAppointmentNotAllowModel";
// import ReScheduleAppointment from "../../components/rescheduleappt";
import UpdatedReScheduleAppointment from "../../components/updatedrescheduleappt";
import { useNavigate } from "react-router-dom";
export default function Upcoming(props) {
  const {
    tableData = [],
    changeHandler,
    onClickHandler,
    tableHeaders = [],
    // filterListAppointments,

    openSurveyLink,
    getPaginationData,
    getAllAppointmentsList,
    activeTab,
    blinkRequest,
    setFilterValues,
    setSearchValues,
    setCounterStatus,
  } = props;
  const { allUpcomingCount } = useSelector((state) => state.client);
  const navigate = useNavigate();
  const [activePay, setActivePay] = useState("");
  const [checkBlink, setcheckBlink] = useState();
  const [active, setActive] = useState({});
  const [reschedeId, setReschedeId] = useState('');
  const [reschedeData, setReschedeData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState('');
  const [messageNotAllow, setMessageNotAllow] = useState();
  const [selectedValue, setSelectedValue] = useState('');
  const [show, setShow] = useState(false);
  useEffect(() => {
    checkBlinkType(blinkRequest);
    setTimeout(() => {
      setcheckBlink();
    }, 10000);
  }, [blinkRequest]);
  const checkBlinkType = (blink, index) => {
    if (blink === "meetinglink") {
      setcheckBlink(1);
    }
    if (blink === "cancelbooking") {
      setcheckBlink(2);
    }
  };

  const refreshScreen = () => {
    const data = {
      page: 1,
      rowsPerPage: 10,
    };
    getAllAppointmentsList(data, activeTab, "refresh");
  };

  const openReSchedulePopUp = (item, index) => {
    console.log(item, 'aaaa')
    // setAppointmentInfo
    addQueryParams(item.medium_id)


    let body = {
      appointment_id: item.id
    }
    getAppointmentsDetails(body).then((data) => {
      setReschedeData(data.data.data)
      setReschedeId(item)
      setSelectedIndex(index)
      setShow(true);
      window.loadModal2("#ReScheduleAppt", "show");
      setTimeout(()=>{
        window.loadModal2("#prvvvvv", "show");
      },100)
    }).catch(() => { })
  }
  const addQueryParams = (id) => {
    const params = new URLSearchParams();
    params.append('id', id);

    navigate({
        pathname: '/appointments',
        search: params.toString(),
    });
};
  const handleNoShow = (e, appoinment) => {
    let value = e.target.value;
    if (value === "me") {
      changeHandler(
        e,
        2,
        appoinment.appointmentId,
        appoinment.client_id,
        "client",
        "client_id"
      );
    } else {
      changeHandler(
        e,
        2,
        appoinment.appointmentId,
        appoinment.medium_id,
        "client",
        "medium_id"
      );
    }
  };

  const openPaymentModal = (item) => {
    setActivePay(item);
    window.loadModal2("#payoptionspopup", "show");
  };

  const runCallback = (type) => {
    let prices = Object.values(JSON.parse(activePay.session_length))[0];
    let body = {
      id: activePay.id,
      medium_id: activePay.medium_id,
      amount: prices !== "" ? prices : 20,
      currency: activePay.currency,
    };
    // let body = {
    //   id: activePay.decrypt_appointmentId,
    //   medium_id: activePay.medium_id,
    //   amount: activePay.transaction_amount,
    // };

    if (type === "paypal") {
      payWithPaypalCompleted(body)
        .then((data) => {
          if (data.data.status) {
            setCounterStatus(true);
            window.loadModal2("#payoptionspopup", "hide");
            window.open(data.data.data.approvedUrl, "_blank");
          }
        })
        .catch(() => { });
    } else if (type === "stripe") {
      payWithStripeCompleted(body)
        .then((data) => {
          if (data.data.status) {
            setCounterStatus(true);
            window.loadModal2("#payoptionspopup", "hide");
            window.open(data.data.data.approvedUrl, "_blank");
          }
        })
        .catch(() => { });
    }
    else {
      payWithSquareCompleted(body)
        .then((data) => {
          if (data.data.status) {
            setCounterStatus(true);
            window.loadModal2("#payoptionspopup", "hide");
            window.open(data.data.data.approvedUrl, "_blank");
          }
        })
        .catch(() => { });
    }
  };
  const changeHandlerLocal = (
    e,
    type,
    id,
    targetId,
    targetType,
    targetTypeID
  ) => {
    setActive({ e, type, id, targetId, targetType, targetTypeID });
    window.loadModal2("#ConfirmCancelupcomingclient", "show");
  };

  const cancelRequest = () => {
    window.loadModal2("#ConfirmCancelupcomingclient", "hide");
    emptyAllSelectBox();
  };
  const confirmRequest = () => {
    let { e, type, id, targetId, targetType, targetTypeID } = active;
    console.log(e, type, id, targetId, targetType, targetTypeID);
    changeHandler(e, type, id, targetId, targetType, targetTypeID);
  };

  const priceCheck = (item) => {

    let prices = item.amount
    // item.session_length !== undefined && item.session_length !== "" && item.session_length !== null
    //   ? Object.values(JSON.parse(item.session_length))[0]
    //   : 20;
    let cur = item.currency
    // item.session_currency !== undefined && item.session_currency !== "" && item.session_currency !== null
    //   ? Object.values(JSON.parse(item.session_currency))[0]
    //   : "USD";

    let price = prices !== "" || prices !== null ? prices : 20;
    let currency = cur !== "" || cur !== null ? cur : "USD";
    return `Pay $ ${price} ${currency} `;
  };
  const getTimeDiff = (appoinment) => {
    let appdate = moment(appoinment.date).format('YYYY/MM/DD');
    let now = moment().format();
    let datetimeB = moment(appdate + " " + appoinment.time);
    // console.log(appoinment.date,appoinment.time, "now",now)
    // console.log(datetimeB,"datetimeB")
    // var a = moment('2016-06-06T21:03:55');//now
    // var b = moment('2016-05-06T20:03:55');

    let final = datetimeB.diff(now, 'minutes');

    // console.log(final)
    let status;
    final > -5 ? status = true : status = false
    return status
  }
  const checkIfAppointmentStarted = (appoinment) => {


    let appdate = moment(appoinment.date).format('YYYY/MM/DD');
    let now = moment().format();
    let datetimeB = moment(appdate + " " + appoinment.time);

    var diff = datetimeB.diff(now, 'seconds');
    var diffmin = datetimeB.diff(now, 'minutes');
    let status;
    diff > 0 ? status = true : status = false
    return status

  }

  const changeCancelReport = (e, appoinment, index) => {
    let value = e.target.value;
    console.log(value, "value")

    setSelectedValue(value);
    if (value === "cancel") {
      changeHandlerLocal(
        e,
        3,
        appoinment.appointmentId,
        appoinment.client_id
      );
    }
    else if (value === "Reschedule") {
      openReSchedulePopUp(appoinment, index)
    } else if (value === "RescheduleCancel") {
      let messageNotAllow = `We apologize but we do not allow rescheduling of appointments less than ${appoinment.timeamount} before the start of a ${appoinment.session_name}`;
      setMessageNotAllow(messageNotAllow);
      window.loadModal2("#RescheduleAppointmentNotAllowModel", "show");
    }
  }

  const changesubmissionIssue = (e, appoinment) => {
    let value = e.target.value;
    console.log(value, "value")
    if (value === "no-show") {


      changeHandlerLocal(
        e,
        2,
        appoinment.appointmentId,
        appoinment.medium_id,
        "client",
        "medium_id"
      );

    }
    else if (value === "mail") {
      window.open("mailto:mailto:concerns@verysoul.com")
    }
  }

  const reschedeDataChange = () => {
    setSelectedValue('');
    emptyAllSelectBox("");
  }
  return (
    <>
      <div className="d-flex justify-content-end gap-10 flex-wrap">
        {/* <Search callApi={filterListAppointments} list={filterList} /> */}
      </div>
      <div className="table-box-medium admin-appoi">
        <table className="caseTable">
          <tbody>
            <tr className="dark">
              {React.Children.toArray(
                tableHeaders.map((item) => <th>{item.title}</th>)
              )}
            </tr>
            {tableData.length > 0 ? (
              tableData.map((appoinment, index) => (
                <>
                  {appoinment.transaction_status != 1 &&
                    appoinment.session_type == 8 ? (
                    <></>
                  ) : (
                    <tr className="innr">
                      <td>{session_id_beg + appoinment.id + session_id_end}</td>
                      <td className="googl-link min-width-210">
                        {appoinment.meeting_link === "" ||
                          appoinment.meeting_link === null ||
                          appoinment.meeting_link === "NULL" ? (

                          <>
                            <span className="w-unset loading-regenrate">
                              {"Generating Zoom link"}
                            </span>

                            <img
                              title="Refresh"
                              onClick={(e) => refreshScreen("refresh")}
                              className="googl-link-copy-img copy-image-animate refresh-btn-app mt-4"
                              alt="copy"
                              src="images/refresh.png"
                            />
                          </>

                        ) : (
                          <>
                            <a href={appoinment.meeting_link} target={"_blank"} >
                              {appoinment.meeting_link.substring(0, 20).concat("...")}
                            </a>
                            <CopyComponent value={appoinment.meeting_link} />
                          </>

                        )}
                        {/* <a href={appoinment.meeting_link}>{appoinment.meeting_link.substring(0, 20).concat("...")}</a>
                        <CopyComponent value={appoinment.meeting_link} /> */}
                        {/* <Button
                          className={`resend-btn w-unset height-35 min-width-130px ${checkBlink === 1 && index === 0
                            ? "alerts-border"
                            : ""
                            }`}
                          title="Click Here"
                          status={0}
                          onClick={() => getLink(appoinment.appointmentId)}
                        /> */}
                      </td>
                      <td>
                        <span className="capitalize">
                          {" "}
                          {appoinment.medium_name}
                        </span>
                      </td>
                      <td>{appoinment.session_name}</td>
                      {/* <td>
                        {appoinment.session_type === "5" || appoinment.session_type === "3"
                          ? "Free"
                          : appoinment.session_type === "7"
                            ? "Pay After"
                            : appoinment.session_type === "8"
                              ? "Pre Pay"
                              : appoinment.session_type === "12" || appoinment.session_type === "13" || appoinment.session_type === "14"
                              ? "Extra Services"
                              : ""}
                      </td> */}
                      <td>{appoinment.date}</td>
                      <td> {appoinment.time}</td>
                      <td>
                        {" "}
                        {appoinment.session_type !== "5" && appoinment.session_type !== "3" ? (

                          appoinment.session_type === "8"
                            && appoinment.transaction_status === null ||
                            appoinment.transaction_status === 0 ? (
                            <Button
                              status={0}
                              // onClick={() => openPaymentModal(appoinment)}
                              type="button"
                              className={`resend-btn bg-gray-500 w-130 ${checkBlink === 2 && index === 0
                                ? "alerts-border"
                                : ""
                                }`}
                              title={priceCheck(appoinment)}
                            />
                          ) :
                            appoinment.session_type === "7" &&
                              appoinment.transaction_status === null ||
                              appoinment.transaction_status === 0 ? (
                              <Button
                                status={0}
                                // onClick={() => openPaymentModal(appoinment)}
                                type="button"
                                className={`resend-btn bg-gray-500 w-130 ${checkBlink === 2 && index === 0
                                  ? "alerts-border"
                                  : ""
                                  }`}
                                title={priceCheck(appoinment)}
                              />
                            )

                              :

                              (
                                <span className="color-d5a67b ml-5"> Paid</span>
                              )
                        )

                          : (
                            <span className="color-d5a67b ml-5"> Free</span>
                          )}
                      </td>
                      {/* <td>
                    <select
                      onChange={(e) => handleNoShow(e, appoinment)}
                      className="appointment-select-box"
                    >
                      <option selected disabled>
                        Select
                      </option>

                      <option value={"me"}>{"No Show By Me"}</option>
                      <option value={"other"}>{"No Show By Other"}</option>
                    </select>
                  </td> */}
                      <td className="dot-image">
                        <select

                          onChange={(e) => changeCancelReport(e, appoinment, index)}
                          // onChange={(e) =>
                          //   changeHandlerLocal(e,2, appoinment.appointmentId)

                          // }
                          className="appointment-select-box"
                        >
                          <option value="" selected disabled>
                            Select
                          </option>
                          {
                            checkIfAppointmentStarted(appoinment) && <option value={'cancel'}> Cancel</option>
                          }
                          {appoinment.is_reschedule == 1 && appoinment.show_reschedule == 1 ? <option>Reschedule</option> : appoinment.is_reschedule == 0 && appoinment.show_reschedule == 1 ? <option value={'RescheduleCancel'}>Reschedule</option> : ""}
                        </select>
                        {/* <img
                          onClick={(e) =>
                            onClickHandler(
                              e,
                              appoinment.appointmentId,
                              "upcoming"
                            )
                          }
                          src="../images/Unionblck.png"
                          className={`pointer dot-image-inside ${checkBlink === 2 && index === 0
                            ? "alerts-border"
                            : ""
                            }`}
                          alt="dots"
                        /> */}
                        {/* {appoinment.status && ( */}
                        {/* <div className="approve-btn-1 approve-btn z-index-1"> */}
                        {/* {checkIfAppointmentStarted(appoinment) && <span
                              className="w-100"
                              onClick={(e) => {

                                changeHandlerLocal(
                                  e,
                                  3,
                                  appoinment.appointmentId,
                                  appoinment.client_id
                                );
                              }}
                            >
                              Cancel
                            </span>} */}

                        {/* {!getTimeDiff(appoinment) && <span
                              className="w-100"
                              onClick={(e) => {

                                changeHandlerLocal(
                                  e,
                                  2,
                                  appoinment.appointmentId,
                                  appoinment.medium_id,
                                  "client",
                                  "medium_id"
                                );
                              }}
                            >
                              No Show
                            </span>} */}
                        {/* <span className="w-100 report-url">
                              <a
                                href={"mailto:concerns@verysoul.com"}
                                target="_blank"
                                className="report-url"
                              >
                                Report
                              </a>
                            </span> */}
                        {/* {appoinment.session_name !== "Donated - 30 Min Mediumship Session" ? <span
                              className="w-100"
                              onClick={() => openReSchedulePopUp(appoinment, index)}
                           
                            >
                              Reschedule
                            </span> : ''} */}
                        {/* </div> */}
                        {/* )} */}
                      </td>
                      <td>
                        <select
                          onChange={(e) => changesubmissionIssue(e, appoinment, index)}

                          className="appointment-select-box"
                        >
                          <option value="" selected disabled>
                            Select
                          </option>
                          {!getTimeDiff(appoinment) && <option
                            value={'no-show'}
                          >
                            No Show
                          </option>}


                          <option value={'mail'}> <a
                            href={"mailto:concerns@verysoul.com"}
                            target="_blank"
                            className="report-url"
                          >
                            Report
                          </a></option>

                          {/* {appoinment.session_name !== "Donated - 30 Min Mediumship Session" && <option>Reschedule</option>} */}
                        </select>
                      </td>
                    </tr>
                  )}
                </>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Appointments Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* <div className="pagination-box">
          <div className="left-pagi">
            <span>Showing 1 to 10 of 18 entries</span>
          </div>
          <div className="right-pagi">
            <ul>
              <li>Previous</li>
              <li className="active">
                <a href="#">1</a>
              </li>
              <li>
                <a href="#">2</a>
              </li>
              <li>
                <a href="#">3</a>
              </li>
              <li>Next</li>
            </ul>
          </div>
        </div> */}
        <PayOptionsPopUp activePay={activePay} from="appointments" runCallback={runCallback} />
        <Pagination
          rowsPerPageOptions={[5, 10, 25]}
          activeTab={"upcoming"}
          count={allUpcomingCount}
          getPaginationData={getPaginationData}
        />
      </div>
      <RescheduleAppointmentNotAllowModel
        cancelRequest={reschedeDataChange}
        id={'RescheduleAppointmentNotAllowModel'}
        label={messageNotAllow}
        btnLabel={"Continue"}
      />
      <ConfirmCancel
        deleteUser={confirmRequest}
        cancelRequest={cancelRequest}
        id={"ConfirmCancelupcomingclient"}
      />
      <UpdatedReScheduleAppointment
        // usersListMedium={usersListMedium}
        // usersListClient={usersListClient}
        reschedeId={reschedeId}
        reschedeData={reschedeData}
        getAllAppointmentsList={getAllAppointmentsList}
        activeTab={activeTab}
        setReschedeId={setReschedeId}
        setReschedeData={setReschedeData}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        show={show}
        setShow={setShow}
      />
    </>
  );
}
