
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Tabs from "../../../components/tabs";
import TabPanel from "../../../components/tabs/tabpanel";
import FreeClients from "./freeclients";
import UnverifiedEmails from "./unverifiedemails";
import {
  getCountry,
  getLanguages,
  getTimezones,
} from "../../../services/uidata";

import {
  filterVisibleTimeZones
} from "../../../utils/helpers";
// import CsvDownloadAll from "../../../components/csvdownloadAll";
import { list } from "./data";

//
import {
  setAllClients,
  setAllStarterClients,
  setAllOutreachClientsCount,
  setAllStarterClientsCount,
  setAllRestrictedClients,
  setAllRestrictedClientsCount,
  setAllVolunteerClients,
  setAllVolunteerClientsCount,
  setAllUnverifiedEmails,
  setAllUnverifiedEmailsCount,
  setAllPendingApprovalsPage,
  setAllPendingApprovalsrowsPerPage,
  setAllUnverifiedEmailsPage,
  setAllUnverifiedEmailsrowsPerPage,
  setAllOutreachClientsPage,
  setAllOutreachClientsrowsPerPage,

  setAllStarterClientsPage,
  setAllStarterClientsrowsPerPage,
  setAllRestrictedClientsPage,
  setAllRestrictedClientsrowsPerPage,

  setAllVolunteerClientsPage,
  setAllVolunteerClientsrowsPerPage,

} from "../../../reducer/adminSlice";

//service
import {
  approveUser,
  filterAllClients,
  getAllClients,
  resendActivationMail,
  getUnverfiedEmailsClients
} from "../../../services/admin";

import "./client.css";
import ConfirmDelete from "../Subcomponents/deleteconfirm";
import StarterClient from "./paidclients";
import RestrictedClients from "./restrictedClients";
import VolunteerClients from "./volunteerClients";
import { useLocation, useSearchParams } from "react-router-dom";


export default function Clients() {
  const dispatch = useDispatch();
  const {
    allClients = [],
    allStarterClients = [],
    allOutreachClientsCount,
    allStarterClientsCount,
    allRestrictedClients,
    allRestrictedClientsCount,
    allVolunteerClients,
    allVolunteerClientsCount,
    allunverifiedEmails,
    allUnverifiedEmailsCount,

    allPendingApprovalsPage,
    allPendingApprovalsrowsPerPage,
    allUnverifiedEmailsPage,
    allUnverifiedEmailsrowsPerPage,
    allOutreachClientsPage,
    allOutreachClientsrowsPerPage,

    allStarterClientsPage,
    allStarterClientsrowsPerPage,
    allRestrictedClientsPage,
    allRestrictedClientsrowsPerPage,

    allVolunteerClientsPage,
    allVolunteerClientsrowsPerPage,
    globalRowsPerPage,
  } = useSelector((state) => state.users);


  const [listArray, setListArray] = useState(list);
  const [activeTab, setActiveTab] = useState(0);
  const [clients, setClients] = useState([]);
  const [starterclients, setStarterClients] = useState([]);
  const [restrictedclients, setRestrictedClients] = useState([]);
  const [volunteerclients, setVolunteerClients] = useState([]);
  const [unverifiedEmails, setUnverifiedEmails] = useState([]);
  const [backupclients, setbackupClients] = useState([]);
  const [restructedbackupclients, setbackupRestrictedClients] = useState([]);
  const [volunteerbackupclients, setbackupVolunteerClients] = useState([]);
  const [backupStarterclients, setStarterbackupClients] = useState([]);
  const [backupUnverfiedEmails, setbackupUnverifiedEmails] = useState([]);
  const [filterOprater, setFilterOprater] = useState("LIKE");
  const [operatorList, setOperatorList] = useState(['=', 'LIKE']);

  const [sortingNameOrderBy, setSortingNameOrderBy] = useState('');
  // const [sortingNameCreated, setSortingNameCreated] = useState('');
  const [sortingTtabType, setSortingTabType] = useState('');

  const [searchParams] = useSearchParams();
  const status = searchParams.get("view");
  const searchVal = searchParams.get("search");
  const searchName = searchParams.get("searchName");
  const searchFilterOprater = searchParams.get("searchFilterOprater");
  const searchOprator = searchParams.get("searchOprator");
  const page = searchParams.get('page');
  const rowperpage = searchParams.get('rowperpage');
  const pagintion = searchParams.get('pagintion');


  const location = useLocation();
  const [deleteInfo, setDeletInfo] = useState({});
  // const [filterStatus ,setfilterStatus] = useState(false)
  const [filterValues, setFilterValues] = useState('screen_name');
  const [showOprater, setShowOprater] = useState(true);
  const [searchValue, setSearchValues] = useState('');
  const [optionsList, setOptionsList] = useState([{ value: '1', name: 'Yes' },
  { value: '0', name: 'No' }]);
  const [filterValueOptions, setFilterValueOptions] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [TimezoneData, setTimezoneData] = useState([]);
  const [formLanguages, setFormLanguages] = useState([]);
  useEffect(() => {
    console.log(pagintion, "pagintion")
    localStorage.setItem('pagintionTYPE', 'change')

    if (searchVal !== null) {
      setFilterValues(searchVal);
    }
    setOptionsList([{ value: '1', name: 'Yes' },
    { value: '0', name: 'No' }]);
    if (searchName !== null) {
      setSearchValues({ [searchVal]: searchName })
      if (searchVal == "screen_name" || searchVal == "legal_name" || searchVal == "email") {
        setOperatorList(['=', 'LIKE']);

      } else if (searchVal == "id" || searchVal == "referralCode") {
        setOperatorList(['=',]);

      } else if (searchVal == "phone" || searchVal == "whatsapp") {
        setOperatorList(['=', 'LIKE']);

      } else if (searchVal == "created_at") {
        setOperatorList(['=', 'BETWEEN']);
      } else if (searchVal == "country") {
        setOperatorList(['=']);
        updateCountries()
          .then((data) => {
            const formattedCountryData = data.data.data.map(country => ({
              value: country.country_name,
              name: country.country_name
            }));
            setOptionsList(formattedCountryData);
          })
          .catch(err => console.log(err));
      } else if (searchVal == "language") {
        setOperatorList(['=']);
        updateLanguage()
          .then((data) => {  // Get the data directly from the API response
            const formattedformLanguages = data.data.data.map(language => ({
              value: language.id,
              name: language.name
            }));
            setOptionsList(formattedformLanguages);
            console.log(formattedformLanguages, "formattedformLanguages"); // This will show the actual data
          })
          .catch(err => console.log(err));
      } else if (searchVal == "timezone") {
        setOperatorList(['=']);
        updateTimeZone()
          .then((data) => {
            const formattedTimezoneData = data.data.data.map(timezone => ({
              value: timezone.id,
              name: timezone.front_name
            }));
            setOptionsList(formattedTimezoneData);
          })
          .catch(err => console.log(err));
      }
      else {
        setOperatorList(['=']);
      }
    }

    if (searchFilterOprater !== null) {
      setFilterOprater(searchFilterOprater)
      //
    }
    if (searchOprator !== null) {
      console.log(searchOprator, "searchOprator")
      if (searchOprator == "true") {
        setShowOprater(true);
      } else {
        setShowOprater(false);
      }

    }
    if (page !== null) {

    }
    setTimeout(() => {
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete("view");
      newUrl.searchParams.delete("search");
      newUrl.searchParams.delete("searchName");
      newUrl.searchParams.delete("searchFilterOprater");
      newUrl.searchParams.delete("searchOprator");
      newUrl.searchParams.delete("page");
      newUrl.searchParams.delete("rowperpage");
      newUrl.searchParams.delete("pagintion");
      // setQueryValue(newUrl.searchParams.delete("view"))
      window.history.replaceState({}, document.title, newUrl.href);
    }, 2000)

    console.log(location.search, "location.search location.search ")
    if (location.pathname === "/clients" && location.search === "") {
      launcApi();

    }
    if (location.pathname === "/clients" && location.search === "?view=0") {
      setClients(backupclients)

    }
    if (location.pathname === "/clients" && location.search === "?view=1") {
      setStarterClients(backupStarterclients)

    }
    if (location.pathname === "/clients" && location.search === "?view=2") {
      setRestrictedClients(restructedbackupclients)

    }
    if (location.pathname === "/clients" && location.search === "?view=3") {
      setVolunteerClients(volunteerbackupclients)

    }
    if (location.pathname === "/clients" && location.search === "?view=4") {
      setUnverifiedEmails(backupUnverfiedEmails)

    }
    if (status === "all" || status === "4") setActiveTab(4);
    if (status === "1") setActiveTab(1);
    if (status === "2") setActiveTab(2);
    if (status === "3") setActiveTab(3);
    if (status === "5") setActiveTab(5);
  }, []);
  const updateCountries = () => {
    return new Promise((resolve, reject) => {
      getCountry()
        .then((data) => {
          if (data.data.status) {
            setCountryData(data.data.data);
          }
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });

  };
  const updateTimeZone = () => {
    return new Promise((resolve, reject) => {
      getTimezones()
        .then((data) => {
          if (data.data.status) {
            setTimezoneData(filterVisibleTimeZones(data.data.data));
          }
          resolve(data); // Resolve the promise with the data
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };
  const updateLanguage = () => {
    return new Promise((resolve, reject) => {
      getLanguages()
        .then((data) => {
          setFormLanguages(
            data.data.data.map((item) => {
              return {
                ...item,
                value: item.id,
                label: item.name,
              };
            })
          );
          resolve(data); // Resolve the promise after setting form languages
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  const setListedArray = (item, set) => {
    const update = item.map((resp) => {
      return {
        ...resp,
        client_status: false,
        resent_status: 0,
      };
    });

    set(update)
    setListArray(
      list.map((item) => {
        if (item.name === "Outreach Clients") {
          return {
            ...item,
            ...(item.count = allOutreachClientsCount),
          };
        }
        if (item.name === "Starter Clients") {
          return {
            ...item,
            ...(item.count = allStarterClientsCount),
          };

        }
        if (item.name === "Restricted Clients") {
          return {
            ...item,
            ...(item.count = allRestrictedClientsCount),
          };
        }
        if (item.name === "Potential Clients") {
          return {
            ...item,
            ...(item.count = allVolunteerClientsCount),
          };
        }
        if (item.name === "Incomplete Registration") {
          return {
            ...item,
            ...(item.count = allUnverifiedEmailsCount),
          };
        }
      })
    );
  }

  useEffect(() => {
    setListedArray(allClients, setClients);
  }, [allClients]);

  useEffect(() => {
    setListedArray(allStarterClients, setStarterClients);
  }, [allStarterClients]);
  useEffect(() => {
    setListedArray(allRestrictedClients, setRestrictedClients);
  }, [allRestrictedClients]);

  useEffect(() => {
    setListedArray(allVolunteerClients, setVolunteerClients);
  }, [allVolunteerClients]);

  useEffect(() => {
    setListedArray(allunverifiedEmails, setUnverifiedEmails);
  }, [allunverifiedEmails]);



  // useEffect(() => {
  //   if (window.renderFirst) {
  //     const data = {
  //       page: 1,
  //       rowsPerPage: 10,
  //     };
  //     if (activeTab === 0) {
  //       // setFilterValues('screen_name')
  //       // setSearchValues('')
  //       getAllStarterClients(data, 'tab');
  //       getAllRestrictedClients(data, 'tab');
  //       getAllVolunteerClients(data, 'tab');
  //       getAllUnVerifiedEmails(data, 'tab');


  //       getAllFreeClients(data);
  //     }

  //     if (activeTab === 1) {
  //       // setFilterValues('screen_name')
  //       // setSearchValues('')

  //       getAllFreeClients(data, 'tab');
  //       getAllRestrictedClients(data, 'tab');
  //       getAllVolunteerClients(data, 'tab');
  //       getAllUnVerifiedEmails(data, 'tab');

  //       getAllStarterClients(data);
  //     }
  //     if (activeTab === 2) {
  //       // setFilterValues('screen_name')
  //       // setSearchValues('')
  //       getAllFreeClients(data, 'tab');


  //       getAllStarterClients(data, 'tab');
  //       getAllVolunteerClients(data, 'tab');
  //       getAllUnVerifiedEmails(data, 'tab');

  //       getAllRestrictedClients(data);
  //     }
  //     if (activeTab === 3) {
  //       // setFilterValues('screen_name')
  //       // setSearchValues('')
  //       getAllFreeClients(data, 'tab');


  //       getAllStarterClients(data, 'tab');
  //       getAllRestrictedClients(data, 'tab');
  //       getAllUnVerifiedEmails(data, 'tab');

  //       getAllVolunteerClients(data)

  //     }
  //     if (activeTab === 4) {
  //       // setFilterValues('screen_name')
  //       // setSearchValues('')
  //       getAllFreeClients(data, 'tab');


  //       getAllStarterClients(data, 'tab');
  //       getAllRestrictedClients(data, 'tab');
  //       getAllVolunteerClients(data, 'tab');

  //       getAllUnVerifiedEmails(data);

  //     }
  //   }
  //   else {
  //     window.renderFirst = true;
  //   }
  // }, [activeTab]);

  //volunteer client

  //restricted clients
  const getAllRestrictedClients = (paginationdata, info) => {
    let keyVal = "";
    let searchVal = "";
    if (!searchValue || Object.keys(searchValue).length === 0) {
      keyVal = "";
      searchVal = "";
    } else {
      const [[key, val]] = Object.entries(searchValue);
      keyVal = key != null && key != "orderBy" && key != "created_at_order_by" ? key : "";
      searchVal = val != null && val != "asc" && val != "desc" ? val : "";
    }
    let data = {
      clientType: "",
      status: 4,
      paginationdata: paginationdata,
      field: keyVal,
      value: searchVal,
      oprater: filterOprater,
      client_name: info === "tab" ? '' : searchValue !== undefined && searchValue.screen_name !== undefined ? searchValue.screen_name : '',
      email: info === "tab" ? '' : searchValue !== undefined && searchValue.email !== undefined ? searchValue.email : '',
      created_at: info === "tab" ? '' : searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : '',
      created_at_order_by: paginationdata.created_at_order_by !== undefined ? paginationdata.created_at_order_by : '',
      orderBy: paginationdata.orderBy !== undefined ? paginationdata.orderBy : ''
    };
    getAllClients(data)
      // getAllClients("outreach")
      .then((data) => {
        if (data.data.status) {
          dispatch(setAllRestrictedClientsCount(data.data.data.total));

          dispatch(setAllRestrictedClients(data.data.data.data));
          setbackupRestrictedClients(JSON.parse(JSON.stringify(data.data.data.data)));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllFreeClients = (paginationdata, info) => {
    let keyVal = "";
    let searchVal = "";
    if (!searchValue || Object.keys(searchValue).length === 0) {
      keyVal = "";
      searchVal = "";
    } else {
      const [[key, val]] = Object.entries(searchValue);
      keyVal = key != null && key != "orderBy" && key != "created_at_order_by" ? key : "";
      searchVal = val != null && val != "asc" && val != "desc" ? val : "";
    }
    let data = {
      status: "",
      clientType: "outreach",
      paginationdata: paginationdata,
      field: keyVal,
      value: searchVal,
      oprater: filterOprater,
      client_name: info === "tab" ? '' : searchValue !== undefined && searchValue.screen_name !== undefined ? searchValue.screen_name : '',
      email: info === "tab" ? '' : searchValue !== undefined && searchValue.email !== undefined ? searchValue.email : '',
      created_at: info === "tab" ? '' : searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : '',
      created_at_order_by: paginationdata.created_at_order_by !== undefined ? paginationdata.created_at_order_by : '',
      orderBy: paginationdata.orderBy !== undefined ? paginationdata.orderBy : ''

    };

    // let body = {
    //   field: key != null && key != "orderBy" && key != "created_at_order_by" ? key : "",
    //   value: val != null && val != "asc" && val != "desc" ? val : "",
    //   created_at_order_by,
    //   orderBy,
    //   oprater: opraterVal,
    // };
    getAllClients(data)
      // getAllClients("outreach")
      .then((data) => {
        if (data.data.status) {
          dispatch(setAllOutreachClientsCount(data.data.data.total));

          dispatch(setAllClients(data.data.data.data));
          setbackupClients(JSON.parse(JSON.stringify(data.data.data.data)));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllStarterClients = (paginationdata, info) => {
    let keyVal = "";
    let searchVal = "";
    if (!searchValue || Object.keys(searchValue).length === 0) {
      keyVal = "";
      searchVal = "";
    } else {
      const [[key, val]] = Object.entries(searchValue);
      keyVal = key != null && key != "orderBy" && key != "created_at_order_by" ? key : "";
      searchVal = val != null && val != "asc" && val != "desc" ? val : "";
    }
    let data = {
      status: "",
      clientType: "starter",
      paginationdata: paginationdata,
      field: keyVal,
      value: searchVal,
      oprater: filterOprater,
      client_name: info === "tab" ? '' : searchValue !== undefined && searchValue.screen_name !== undefined ? searchValue.screen_name : '',
      email: info === "tab" ? '' : searchValue !== undefined && searchValue.email !== undefined ? searchValue.email : '',
      created_at: info === "tab" ? '' : searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : '',
      created_at_order_by: paginationdata.created_at_order_by !== undefined ? paginationdata.created_at_order_by : '',
      orderBy: paginationdata.orderBy !== undefined ? paginationdata.orderBy : ''
    };
    getAllClients(data)
      .then((data) => {
        if (data.data.status) {
          dispatch(setAllStarterClientsCount(data.data.data.total));

          dispatch(setAllStarterClients(data.data.data.data));
          setStarterbackupClients(
            JSON.parse(JSON.stringify(data.data.data.data))
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllVolunteerClients = (paginationdata, info) => {
    let keyVal = "";
    let searchVal = "";
    if (!searchValue || Object.keys(searchValue).length === 0) {
      keyVal = "";
      searchVal = "";
    } else {
      const [[key, val]] = Object.entries(searchValue);
      keyVal = key != null && key != "orderBy" && key != "created_at_order_by" ? key : "";
      searchVal = val != null && val != "asc" && val != "desc" ? val : "";
    }
    let data = {
      status: "",
      clientType: "volunteer",
      paginationdata: paginationdata,
      field: keyVal,
      value: searchVal,
      oprater: filterOprater,
      client_name: info === "tab" ? '' : searchValue !== undefined && searchValue.screen_name !== undefined ? searchValue.screen_name : '',
      email: info === "tab" ? '' : searchValue !== undefined && searchValue.email !== undefined ? searchValue.email : '',
      created_at: info === "tab" ? '' : searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : '',
      created_at_order_by: paginationdata.created_at_order_by !== undefined ? paginationdata.created_at_order_by : '',
      orderBy: paginationdata.orderBy !== undefined ? paginationdata.orderBy : ''
    };
    getAllClients(data)
      .then((data) => {
        if (data.data.status) {
          dispatch(setAllVolunteerClientsCount(data.data.data.total));

          dispatch(setAllVolunteerClients(data.data.data.data));
          setbackupVolunteerClients(
            JSON.parse(JSON.stringify(data.data.data.data))
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const launcApi = () => {
    const data = {
      page: 1,
      rowsPerPage: 10,
    };
    getAllFreeClients(data);
    getAllStarterClients(data);
    getAllRestrictedClients(data);
    getAllVolunteerClients(data);
    getAllUnVerifiedEmails(data);
  };

  const getPaginationData = (data, activeTab) => {

    if (activeTab + 1 === parseInt(sortingTtabType)) {
      Object.assign(data, sortingNameOrderBy)
    }
    if (activeTab === 0) {

      getAllFreeClients(data);
    }

    if (activeTab === 1) {
      getAllStarterClients(data);
    }
    if (activeTab === 2) {
      getAllRestrictedClients(data);
    }
    if (activeTab === 3) {
      getAllVolunteerClients(data);
    }
    if (activeTab === 4) {
      getAllUnVerifiedEmails(data);
    }
  };

  const getAllUnVerifiedEmails = (paginationdata, info) => {
    let keyVal = "";
    let searchVal = "";
    if (!searchValue || Object.keys(searchValue).length === 0) {
      keyVal = "";
      searchVal = "";
    } else {
      const [[key, val]] = Object.entries(searchValue);
      keyVal = key != null && key != "orderBy" && key != "created_at_order_by" ? key : "";
      searchVal = val != null && val != "asc" && val != "desc" ? val : "";
    }
    let data = {
      status: "",
      clientType: "unverified",
      paginationdata: paginationdata,
      field: keyVal,
      value: searchVal,
      oprater: filterOprater,
      client_name: info === "tab" ? '' : searchValue !== undefined && searchValue.screen_name !== undefined ? searchValue.screen_name : '',
      email: info === "tab" ? '' : searchValue !== undefined && searchValue.email !== undefined ? searchValue.email : '',
      created_at: info === "tab" ? '' : searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : '',
      created_at_order_by: paginationdata.created_at_order_by !== undefined ? paginationdata.created_at_order_by : '',
      orderBy: paginationdata.orderBy !== undefined ? paginationdata.orderBy : ''
    };
    getAllClients(data)
      .then((data) => {
        if (data.data.status) {


          dispatch(setAllUnverifiedEmailsCount(data.data.data.total))
          dispatch(setAllUnverifiedEmails(data.data.data.data));
          setbackupUnverifiedEmails(JSON.parse(JSON.stringify(data.data.data.data)));

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const getAllUnVerifiedEmails = async (data) => {
  //   // dispatch(setLoading(true));
  //   const response = await getUnverfiedEmailsClients(data)
  //     .then((data) => {
  //       // dispatch(setLoading(false));
  //       const update = data.data.data.data.map((resp) => {
  //         return {
  //           ...resp,
  //           status: false,
  //         };
  //       });
  //       dispatch(setAllUnverifiedEmailsCount(data.data.data.total))
  //       dispatch(setAllUnverifiedEmails(update));
  //       setbackupUnverifiedEmails(JSON.parse(JSON.stringify(data.data.data.data)));
  //     })
  //     .catch((err) => {
  //       // dispatch(setLoading(false));
  //       console.log(err);
  //     });

  //   return response;
  // };

  const onClickHandler = (e, id, name) => {
    e.stopPropagation();
    if (name === "outreach") {
      let data = JSON.parse(JSON.stringify(clients));
      const response = data.map((user) => {
        if (user.id === id) {
          return Object.assign(user, { client_status: !user.client_status });
        } else {
          return Object.assign(user, { client_status: false });
        }
      });
      setClients(response);
    }
    if (name === "starter") {

      let data = JSON.parse(JSON.stringify(starterclients));
      const response = data.map((user) => {
        if (user.id === id) {
          return Object.assign(user, { client_status: !user.client_status });
        } else {
          return Object.assign(user, { client_status: false });
        }
      });
      setStarterClients(response);
    }
    if (name === "restricted") {

      let data = JSON.parse(JSON.stringify(restrictedclients));
      const response = data.map((user) => {
        if (user.id === id) {
          return Object.assign(user, { client_status: !user.client_status });
        } else {
          return Object.assign(user, { client_status: false });
        }
      });
      setRestrictedClients(response);
    }
    if (name === "volunteer") {

      let data = JSON.parse(JSON.stringify(volunteerclients));
      const response = data.map((user) => {
        if (user.id === id) {
          return Object.assign(user, { client_status: !user.client_status });
        } else {
          return Object.assign(user, { client_status: false });
        }
      });
      setVolunteerClients(response);
    }
    if (name === "unverifiedemails") {

      let data = JSON.parse(JSON.stringify(unverifiedEmails));
      const response = data.map((user) => {
        if (user.id === id) {
          return Object.assign(user, { client_status: !user.client_status });
        } else {
          return Object.assign(user, { client_status: false });
        }
      });
      setUnverifiedEmails(response);
    }
  };

  const updateUser = async (user, status, name, item) => {
    if (name === "delete") {
      window.loadModal2("#deleteConfirmation", "show");
      let data = { user, status, name, item };
      setDeletInfo(data);
    } else {
      deleteUser(user, status, name, item);
    }
  };
  const deleteUser = async (user, status, name, item) => {
    let body = {
      user_id: status !== undefined ? user.id : deleteInfo.user.id,
      medium_status:
        status !== undefined
          ? user.medium_status
          : deleteInfo.user.medium_status,
      status: status !== undefined ? status : deleteInfo.status,
    };
    await approveUser(body)
      .then((data) => {
        if (data.data.status) {
          const data = {
            page: 1,
            rowsPerPage: 10,
          };
          getAllFreeClients(data);
          getAllStarterClients(data);
          getAllRestrictedClients(data);
          getAllUnVerifiedEmails(data);
        }
      })
      .catch(() => { });
  };

  const resendEmail = (e, id, name) => {
    // const activeClient = name === "outreach" ? clients : starterclients;
    let activeClient;
    let activeSetData;
    if (name === "outreach") {
      activeClient = clients;
    } else if (name === "starter") {
      activeClient = starterclients;
    } else if (name === "unverifiedEmails") {
      activeClient = unverifiedEmails
    }
    if (name === "outreach") {
      activeSetData = setClients;
    } else if (name === "starter") {
      activeSetData = setStarterClients;
    } else if (name === "unverifiedEmails") {
      activeSetData = setUnverifiedEmails
    }
    // const activeSetData = name === "outreach" ? setClients : setStarterClients;

    e.stopPropagation();
    resendActivationMail(id, name)
      .then((data) => {
        if (data.data.status) {
          let response = activeClient.map((item) => {
            if (parseInt(item.id) === parseInt(id)) {
              return {
                ...item,
                ...(item.resent_status = 1),
              };
            } else {
              return { ...item };
            }
          });
          activeSetData(response);
        }
        if (!data.data.status) {
          let response = activeClient.map((item) => {
            if (parseInt(item.id) === parseInt(id)) {
              return {
                ...item,
                ...(item.resent_status = 2),
              };
            } else {
              return { ...item };
            }
          });
          activeSetData(response);
        }
      })
      .catch(() => { });

    setTimeout(() => {
      let response = activeClient.map((item) => {
        return {
          ...item,
          ...(item.resent_status = 0),
        };
      });
      activeSetData(response);
    }, 2000);
  };
  const filterListClients = (constants, opraterVal) => {

    if (Boolean(constants.created_at_order_by)) {
      var { email, screen_name, created_at, created_at_order_by, orderBy, referal_code } = constants;
    } else {
      var { email, screen_name, created_at, created_at_order_by, orderBy = "asc", referal_code } = constants;
    }

    let client_name = screen_name == undefined ? searchValue !== undefined && searchValue.screen_name !== undefined ? searchValue.screen_name : '' : screen_name;
    let emails = email == undefined ? searchValue !== undefined && searchValue.email !== undefined ? searchValue.email : '' : email;
    let created_att = created_at == undefined ? searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : '' : created_at;

    // let phone_no = searchValue !== undefined && searchValue.phone !== undefined ? searchValue.phone : '' ;
    // let whatsapp_no = searchValue !== undefined && searchValue.whatsapp !== undefined ? searchValue.whatsapp : '';
    // let website = searchValue !== undefined && searchValue.website !== undefined ? searchValue.website : '';
    // let country = searchValue !== undefined && searchValue.country !== undefined ? searchValue.country : '';
    // let agree_to_legal_terms_and_conditionss = searchValue !== undefined && searchValue.agree_to_legal_terms_and_conditionss !== undefined ? searchValue.agree_to_legal_terms_and_conditionss : '';
    // let agree_to_starter_client_guidelines = searchValue !== undefined && searchValue.agree_to_starter_client_guidelines !== undefined ? searchValue.agree_to_starter_client_guidelines : '';
    // let opt_in_out = searchValue !== undefined && searchValue.opt_in_out !== undefined ? searchValue.opt_in_out : '';
    // let email_verified_at = searchValue !== undefined && searchValue.email_verified_at !== undefined ? searchValue.email_verified_at : '';
    // let timezoneVal = searchValue !== undefined && searchValue.timezone !== undefined ? searchValue.timezone : '';
    // let test_mode = searchValue !== undefined && searchValue.test_mode !== undefined ? searchValue.test_mode : '';
    // let test_user = searchValue !== undefined && searchValue.test_user !== undefined ? searchValue.test_user : '';
    let keyVal = "";
    let searchVal = "";
    console.log(opraterVal, "opraterVal");

    if (!searchValue || Object.keys(searchValue).length === 0) {
      keyVal = "";
      searchVal = "";
    } else {
      const [[keyData, valData]] = Object.entries(searchValue);
      keyVal = keyData != null && keyData != "orderBy" && key != "created_at_order_by" ? keyData : "";
      searchVal = valData != null && valData != "asc" && valData != "desc" ? valData : "";
    }
    const [[key, val]] = Object.entries(constants);
    if (key == "created_at_order_by" || key == "orderBy") {
      keyVal = keyVal;
      searchVal = searchVal;
    } else {
      keyVal = key;
      searchVal = val;
    }
    let body = {
      field: keyVal,
      value: searchVal,
      created_at_order_by,
      orderBy,
      oprater: opraterVal ? opraterVal : filterOprater,
    };
    let type = "";
    if (activeTab === 0) {
      type = "outreach"
    }
    if (activeTab === 1) {
      type = "starter"
    }
    if (activeTab === 2) {
      type = "restricted"
    }

    if (activeTab === 3) {
      type = "volunteer"
    }
    if (activeTab === 4) {
      type = "unverified"
    }
    let typeArry = ["outreach", "starter", "restricted", "volunteer", "unverified"]



    // let type = activeTab === 1 ? "starter" : "outreach";
    const promises = typeArry.map((type, index) => {
      return filterAllClients(body, type)
        .then((data) => {
          if (data.data.status) {

            switch (index) {
              case 0: // Outreach
                if (data.data.externalInfo == "outreach") {

                  dispatch(setAllOutreachClientsCount(data.data.data.total));
                  dispatch(setAllClients(data.data.data.data));
                  dispatch(setAllOutreachClientsPage(allOutreachClientsPage));
                  dispatch(setAllOutreachClientsrowsPerPage(globalRowsPerPage));
                  setbackupClients(JSON.parse(JSON.stringify(data.data.data.data)));
                }
                break;
              case 1: // Starter
                if (data.data.externalInfo == "starter") {
                  dispatch(setAllStarterClientsCount(data.data.data.total));
                  dispatch(setAllStarterClients(data.data.data.data));
                  dispatch(setAllStarterClientsPage(allStarterClientsPage));
                  dispatch(setAllStarterClientsrowsPerPage(globalRowsPerPage));
                  setStarterbackupClients(JSON.parse(JSON.stringify(data.data.data.data)));
                }
                break;
              case 2: // Restricted
                if (data.data.externalInfo == "restricted") {
                  dispatch(setAllRestrictedClientsCount(data.data.data.total));
                  dispatch(setAllRestrictedClients(data.data.data.data));
                  dispatch(setAllRestrictedClientsPage(allRestrictedClientsPage));
                  dispatch(setAllRestrictedClientsrowsPerPage(globalRowsPerPage));
                  setbackupRestrictedClients(JSON.parse(JSON.stringify(data.data.data.data)));
                }
                break;
              case 3: // Volunteer
                if (data.data.externalInfo == "volunteer") {
                  dispatch(setAllVolunteerClientsCount(data.data.data.total));
                  dispatch(setAllVolunteerClients(data.data.data.data));
                  dispatch(setAllVolunteerClientsPage(allVolunteerClientsPage));
                  dispatch(setAllVolunteerClientsrowsPerPage(globalRowsPerPage));
                  setbackupVolunteerClients(JSON.parse(JSON.stringify(data.data.data.data)));
                }
                break;
              case 4: // Unverified
                if (data.data.externalInfo == "unverified") {
                  dispatch(setAllUnverifiedEmailsCount(data.data.data.total));
                  dispatch(setAllUnverifiedEmails(data.data.data.data));
                  dispatch(setAllUnverifiedEmailsPage(allUnverifiedEmailsPage));
                  dispatch(setAllUnverifiedEmailsrowsPerPage(globalRowsPerPage));
                  setbackupUnverifiedEmails(JSON.parse(JSON.stringify(data.data.data.data)));
                }
                break;
              default:
                break;
            }
          }
        })
        .catch((error) => {
          console.error(`Error fetching clients for ${type}:`, error);
        });
    });

    Promise.all(promises);

    // let type = activeTab === 1 ? "starter" : "outreach";
    // filterAllClients(body, type)
    //   .then((data) => {
    //     if (data.data.status) {
    //       dispatch(setAllOutreachClientsCount(data.data.data.total));
    //       dispatch(
    //         activeTab === 1
    //           ? setAllStarterClients(data.data.data.data)
    //           : setAllClients(data.data.data.data)
    //       );
    //     }
    //   })
    //   .catch(() => { });

    // }
  };
  const filterReset = () => {
    setFilterValues('screen_name');
    setSearchValues("");
    let dataVal = {
      "email": "",
      "client_name": "",
      "created_at": "",
      "orderBy": "asc",
      "referal_code": "",
      "phone": "",
      "whatsapp": "",
      "website": "",
      "country": "",
      "agree_to_legal_terms_and_conditionss": "",
      "agree_to_starter_client_guidelines": "",
      "opt_in_out": "",
      "email_verified_at": "",
      "timezone": "",
      "test_mode": "",
      "test_user": "",
      "created_at_order_by": ""
    }
    filterListClients(dataVal);
  }


  return (
    <div className="all-medium-page">
      <Tabs active={activeTab} list={listArray} setActiveTab={setActiveTab} />

      <ul className="tabs-content">
        <TabPanel id={0} activeTab={activeTab}>
          <FreeClients
            resendEmail={resendEmail}
            updateUser={updateUser}
            filterListClients={filterListClients}
            filterReset={filterReset}
            optionsList={optionsList}
            setOptionsList={setOptionsList}
            filterValueOptions={filterValueOptions}
            setFilterValueOptions={setFilterValueOptions}
            clients={clients}
            operatorList={operatorList}
            setOperatorList={setOperatorList}
            onClickHandler={onClickHandler}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            searchValue={searchValue}
            filterValues={filterValues}
            showOprater={showOprater}
            setShowOprater={setShowOprater}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}
            setFilterOprater={setFilterOprater}
            filterOprater={filterOprater}
            allOutreachClientsPage={allOutreachClientsPage}
            allOutreachClientsrowsPerPage={allOutreachClientsrowsPerPage}
            setClients={setClients}


          />
        </TabPanel>
        <TabPanel id={1} activeTab={activeTab}>
          <StarterClient
            resendEmail={resendEmail}
            updateUser={updateUser}
            filterListClients={filterListClients}
            filterReset={filterReset}
            clients={starterclients}
            optionsList={optionsList}
            setOptionsList={setOptionsList}
            filterValueOptions={filterValueOptions}
            setFilterValueOptions={setFilterValueOptions}
            operatorList={operatorList}
            setOperatorList={setOperatorList}
            onClickHandler={onClickHandler}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            searchValue={searchValue}
            filterValues={filterValues}
            showOprater={showOprater}
            setShowOprater={setShowOprater}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}
            setFilterOprater={setFilterOprater}
            filterOprater={filterOprater}
            allStarterClientsPage={allStarterClientsPage}
            allStarterClientsrowsPerPage={allStarterClientsrowsPerPage}


          />
        </TabPanel>
        <TabPanel id={2} activeTab={activeTab}>
          <RestrictedClients
            resendEmail={resendEmail}
            updateUser={updateUser}
            filterListClients={filterListClients}
            operatorList={operatorList}
            setOperatorList={setOperatorList}
            optionsList={optionsList}
            setOptionsList={setOptionsList}
            filterValueOptions={filterValueOptions}
            setFilterValueOptions={setFilterValueOptions}
            filterReset={filterReset}
            clients={restrictedclients}
            onClickHandler={onClickHandler}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            searchValue={searchValue}
            filterValues={filterValues}
            showOprater={showOprater}
            setShowOprater={setShowOprater}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}
            setFilterOprater={setFilterOprater}
            filterOprater={filterOprater}
            allRestrictedClientsPage={allRestrictedClientsPage}
            allRestrictedClientsrowsPerPage={allRestrictedClientsrowsPerPage}

          />
        </TabPanel>
        <TabPanel id={3} activeTab={activeTab}>
          <VolunteerClients
            resendEmail={resendEmail}
            updateUser={updateUser}
            filterListClients={filterListClients}
            operatorList={operatorList}
            setOperatorList={setOperatorList}
            optionsList={optionsList}
            setOptionsList={setOptionsList}
            filterValueOptions={filterValueOptions}
            setFilterValueOptions={setFilterValueOptions}
            filterReset={filterReset}
            clients={volunteerclients}
            onClickHandler={onClickHandler}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            searchValue={searchValue}
            filterValues={filterValues}
            showOprater={showOprater}
            setShowOprater={setShowOprater}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}
            setFilterOprater={setFilterOprater}
            filterOprater={filterOprater}
            allVolunteerClientsPage={allVolunteerClientsPage}
            allVolunteerClientsrowsPerPage={allVolunteerClientsrowsPerPage}
          />
        </TabPanel>
        <TabPanel id={4} activeTab={activeTab}>
          <UnverifiedEmails
            resendEmail={resendEmail}
            updateUser={updateUser}
            filterListClients={filterListClients}
            filterReset={filterReset}
            optionsList={optionsList}
            setOptionsList={setOptionsList}
            filterValueOptions={filterValueOptions}
            setFilterValueOptions={setFilterValueOptions}
            unverifiedEmails={unverifiedEmails}
            operatorList={operatorList}
            setOperatorList={setOperatorList}
            onClickHandler={onClickHandler}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            searchValue={searchValue}
            filterValues={filterValues}
            showOprater={showOprater}
            setShowOprater={setShowOprater}
            setFilterOprater={setFilterOprater}
            filterOprater={filterOprater}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}
            allUnverifiedEmailsPage={allUnverifiedEmailsPage}
            allUnverifiedEmailsrowsPerPage={allUnverifiedEmailsrowsPerPage}
          />
        </TabPanel>
      </ul>

      <ConfirmDelete deleteUser={deleteUser} id="deleteConfirmation" />
    </div>
  );
}