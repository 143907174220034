import React, { useEffect, useState } from "react";
// import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Search from "../../../../components/search";
import Pagination from "../../../../components/tablePagination";
import { CompletedHeaders, filterList } from "../data";
import Button from "../../../../components/button";
import { noshowGroupAppointments, noshowRemoveGroupAppointments, resendSurveyEmail } from "../../../../services/medium";
import { toast } from "react-toastify";
import ConfirmCancel from "../../../admin/appointments/subcomponents/cancelpopup";
import {
  copyToClipboard,
  emptyAllSelectBox,
  session_id_beg,
  session_id_end,
} from "../../../../utils/helpers";
import { settlePaymentOutside } from "../../../../services/admin";
import { setLoading } from "../../../../reducer/auth/auth";
import NoshowBox from "../../../admin/appointments/subcomponents/noshowbox";
import SettlePaymentOutsitePopup from "../../../admin/appointments/subcomponents/settleoutsidepopup";
import BackFillConfirmation from "../subcomponetnts/backfillconfirmation";
import BackFillConfirmationTime from "../subcomponetnts/backfilltimeselection";
import Contactpopup from "../subcomponetnts/contactpopup";
import ZoomLinkPopup from "../subcomponetnts/zoomlinkpopup";
export default function Completed(props) {
  const {
    completedAppointments,
    changeHandler,
    changeHandle1,
    onClickHandler,
    filterReset,
    filterListAppointments,
    getPaginationData,
    setFilterValues,
    setSearchValues,
    getAllAppointmentsList,
    activeTab,
    searchValue,
    filterValues,
    blockUser,
    goToMediumBackFill,
    setBlink,
  } = props;
  const { id } = useSelector((state) => state.user.user);
  // const copyMeetingLink = (url) => {
  //   navigator.clipboard.writeText(url);
  // };
  const { allCompletedCount } = useSelector((state) => state.medium);
  const [active, setActive] = useState({});
  const [appType, setAppType] = useState("");
  const [attendeesList, setattendeesList] = useState([])
  const [noshowFrom, setNoShowFrom] = useState('');
  const [noShowActive, setNoShowActive] = useState();
  const [settlePaymentLocalId, setsettlePaymentLocalId] = useState('')
  const [backFillId, setBackFillId] = useState('');
  const [activeContact, setactiveContact] = useState('');
  const dispatch = useDispatch();


  useEffect(() => {
    setBlink(false)
    // window.loadModal2("#BackFillConfirmationTime", "show");
  }, [])
  const handleNoShow = (e, appoinment) => {
    setBackFillId(appoinment.id)
    setAppType(appoinment.session_type);
    let value = e.target.value;
    console.log(value, "values");
    let noShowBy = value;
    if (value === "me") {
      if (appoinment.session_type === "1" || appoinment.session_type === "2") {
        // if (userid === clientid) send mediumid
        // if(userid !== clientid ) send clientid
        // if (parseInt(id) === parseInt(appoinment.client_id)) {
        changeHandlerLocal1(
          e,
          2,
          appoinment.appointmentId,
          id,
          "medium",
          "medium_id",
          appoinment.session_type,
          noShowBy
        );
      } else {
        changeHandlerLocal(
          e,
          2,
          appoinment.appointmentId,
          appoinment.medium_id,
          "medium",
          "medium_id",
          appoinment.session_type,
          noShowBy
        );
      }
    } else if (value === "give-add-1") {
      changeHandlerLocal(
        e,
        6,
        appoinment.appointmentId,
        appoinment.medium_id,
        "medium",
        "medium_id",
        appoinment.session_type,
        noShowBy
      );
    } else {
      if (appoinment.session_type === "1" || appoinment.session_type === "2") {
        // if (userid === clientid) send mediumid
        // if(userid !== clientid ) send clientid
        if (parseInt(id) === parseInt(appoinment.medium_id)) {
          changeHandlerLocal1(
            e,
            2,
            appoinment.appointmentId,
            appoinment.client_id,
            "medium",
            "medium_id",
            appoinment.session_type,
            noShowBy
          );
        } else {
          changeHandlerLocal1(
            e,
            2,
            appoinment.appointmentId,
            appoinment.medium_id,
            "medium",
            "medium_id",
            appoinment.session_type,
            noShowBy
          );
        }

        // if (userid === clientid) send mediumid
        // if(userid !== clientid ) send clientid
      } else {
        changeHandlerLocal(
          e,
          2,
          appoinment.appointmentId,
          appoinment.client_id,
          "medium",
          "client_id",
          appoinment.session_type,
          noShowBy
        );
      }
    }
  };

  const changeHandlerLocal1 = (
    e,
    type,
    id,
    targetId,
    targetType,
    targetTypeID,
    sessionType,
    noShowBy
  ) => {
    setActive({ e, type, id, targetId, targetType, targetTypeID, sessionType, noShowBy });
    window.loadModal2("#ConfirmCancelcompletedmedium", "show");
  };

  const changeHandlerLocal = (
    e,
    type,
    id,
    targetId,
    targetType,
    targetTypeID,
    sessionType,
    noShowBy
  ) => {
    setActive({ e, type, id, targetId, targetType, targetTypeID, sessionType, noShowBy });
    window.loadModal2("#ConfirmCancelcompletedmedium", "show");
  };

  const cancelRequest = () => {
    window.loadModal2("#ConfirmCancelcompletedmedium", "hide");
    emptyAllSelectBox();
  };
  const confirmRequest = () => {
    let { e, type, id, targetId, targetType, targetTypeID, sessionType, noShowBy } = active;
    if (parseInt(appType) === 1 || parseInt(appType) === 2) {
      changeHandle1(e, type, id, targetId, targetType, targetTypeID, sessionType, noShowBy);
    } else {
      changeHandler(e, type, id, targetId, targetType, targetTypeID, sessionType, noShowBy);
    }
  };

  const resendSurveyLink = (id) => {
    let body = {
      appointment_id: id,
      // survey_link
    };
    resendSurveyEmail(body)
      .then((data) => {
        if (data.data.status) {
          toast.success("Survey Sent Succesfully", {
            position: "top-center",
          });
          // getLink();
        }
      })
      .catch(() => { });
  };
  const settlePayment = (id) => {
    dispatch(setLoading(true));
    settlePaymentOutside(id).then((data) => {
      dispatch(setLoading(false));
      if (data.data.status) {
        toast.success("Appointment Updated Successfully!!", {
          position: "top-center",
        });
        const info = {
          page: 1,
          rowsPerPage: 10,
        };
        getAllAppointmentsList(info, activeTab);
      } else {
        toast.error(data.data.message, {
          position: "top-center",
        });
      }
    });
  };
  const coptText = (value) => {
    copyToClipboard(value);
    toast.success("E-mail Copied", { position: "top-center" });
  };

  const openNoShowBox = (list, from, item) => {
    setNoShowActive(item);
    setNoShowFrom(from);
    setattendeesList(list)
    window.loadModal('#NoshowBox', 'show');
  }
  const noshowGroupAppointment = (item) => {
    console.log(item);
    let body = {
      appointment_id: noShowActive.id,
      user_id: item.user_id
    }
    noshowGroupAppointments(body).then((data) => {
      if (data.data.status) {
        toast.success("Status updated succesfully", {
          position: "top-center",
        });
        const data = {
          page: 1,
          rowsPerPage: 10,
        };
        getAllAppointmentsList(data, activeTab,);
      }
      else {
        toast.error("Something went wrong", { position: "top-center" });
      }
    })
  }
  const noshowRemoveGroupAppointment = (item) => {
    console.log(item);
    let body = {
      appointment_id: noShowActive.id,
      user_id: item.user_id
    }
    noshowRemoveGroupAppointments(body).then((data) => {
      if (data.data.status) {
        toast.success("Status updated succesfully", {
          position: "top-center",
        });
        const data = {
          page: 1,
          rowsPerPage: 10,
        };
        getAllAppointmentsList(data, activeTab,);
      }
      else {
        toast.error("Something went wrong", { position: "top-center" });
      }
    })
  }
  const checkAppId = (appoinment) => {
    if (appoinment.is_group_session === 0) {
      return appoinment.id
    }
    else if (appoinment.is_group_session === 1) {
      if (appoinment.attendees == undefined) {
        if (appoinment.parent_id == null) {
          return appoinment.id
        }
        else {
          return appoinment.parent_id
        }

      }
      else {
        return appoinment.id
      }

    }
    else {
      return appoinment.id
    }

  }

  const settlePaymentLocal = (id) => {

    window.loadModal2("#SettlePaymentOutsitePopup", "show");
    setsettlePaymentLocalId(id)
  }
  const settleoutsidepopupconfirmRequest = () => {
    settlePayment(settlePaymentLocalId);
  }

  const settleoutsidepopupcancelRequest = () => {
    window.loadModal2("#SettlePaymentOutsitePopup", "hide");
  }
  const openContactPopup = (item) => {
    window.loadModal2("#Contactpopup", "show")
    setactiveContact(item)
  }
  const openZoomLinkPopup = (item) => {
    window.loadModal2("#ZoomLinkPopup", "show")
    setactiveContact(item)
  }
  return (
    <>
      {console.log(backFillId, "backFillId")}
      <div className="d-flex justify-content-end gap-10 flex-wrap">
        <Search
          callApi={filterListAppointments}
          list={filterList}
          setSearchValues={setSearchValues}
          setFilterValues={setFilterValues}
          searchValue={searchValue}
          filterValues={filterValues}
        />
        {/* <Button
          status={0} 
           type="button"
           onClick={filterReset}
           className={`resend-btn  width-80px  reset-btn-123`}
           title="Reset"
          /> */}
      </div>
      <div className="table-box-medium admin-appoi">
        <table className="caseTable">
          <tbody>
            <tr className="dark">
              {React.Children.toArray(
                CompletedHeaders.map((item) => <th>{item.title}</th>)
              )}

              {/* <input type="checkbox" id="one" />
                <label htmlFor="one"></label> */}
            </tr>
            {completedAppointments.length > 0 ? (
              completedAppointments.map((appoinment, index) => (
                <tr className="innr">
                  <td>
                    {session_id_beg + checkAppId(appoinment) + session_id_end}
                    {appoinment.give_add1_highlight ? (
                      <img
                        className="ml-1 star-app-hightlight"
                        src="../images/star.png"
                      />
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    {appoinment.attendees == undefined ?
                      <>
                        <span className="capitalize">{appoinment.client_name},</span>
                        <br />
                        <span className="capitalize">{appoinment.medium_name}</span>
                      </>
                      :

                      <span className="text-underline  pointer p-1" onClick={() => openNoShowBox(appoinment.attendees, '', appoinment)}>{appoinment.attendees.length}&nbsp;Attendee(s)</span>
                    }

                  </td>
                  <td>
                    {appoinment.date.split(" ")[0]}
                    {/* {moment
                      .utc(appoinment.date)
                      .local()
                      .format("LL")} */}
                  </td>
                  <td>
                    {appoinment.time}
                    {/* {moment.utc(appoinment.date).local().format("LL")} */}
                  </td>
                  <td>{appoinment.session_name}</td>

                  <td>
                    {appoinment.attendees == undefined ?
                      <>
                        {" "}
                        {(appoinment.session_type === "1" ||
                          appoinment.session_type === "16" ||
                          (appoinment.session_type === "2" && appoinment.client_id == id)) &&
                          appoinment.survey_status === "pending" ? (
                          <a
                            className={`resend-btn w-140 d-inline-flex align-items-center justify-content-center`}
                            target="_blank"
                            href={appoinment.survey_link}
                          >
                            Take Survey
                          </a>
                        ) : (
                          <div className="color-d5a67b justify-content-center d-flex align-items-center height-45 capitalize">
                            {appoinment.survey_status}
                          </div>
                        )}
                      </>
                      : 'N/A'
                    }
                  </td>
                  <td>
                    {appoinment.attendees == undefined ?

                      <div className="color-d5a67b d-flex align-items-center height-45">
                        {appoinment.payment_status}
                      </div>
                      :
                      'N/A'
                    }
                  </td>
                  <td>
                    {appoinment.attendees == undefined ?
                      <>
                        {appoinment.payment_request_button === "Send Request" &&
                          appoinment.session_type === "7" && (
                            <Button
                              status={0}
                              onClick={(e) => settlePaymentLocal(appoinment.id)}
                              type="button"
                              className={`resend-btn height-35 w-140 mt-2`}
                              title="Settle Outside"
                            />
                          )}
                      </>
                      :

                      'N/A'
                    }
                  </td>
                  <td>
                    {appoinment.attendees == undefined ?
                      <>
                        {appoinment.is_group_session === 0 ?
                          <select
                            // value={appoinment.statusValue}
                            onChange={(e) => handleNoShow(e, appoinment)}
                            className="appointment-select-box"
                          >
                            <option value="" selected disabled>
                              Select
                            </option>

                            {appoinment.is_no_show && (
                              <option value={"me"}>{"No Show By Me"}</option>
                            )}
                            {appoinment.is_no_show && (
                              <option value={"other"}>
                                {appoinment.session_type === "1" ||
                                  appoinment.session_type === "2" ||
                                  appoinment.session_type === "16"
                                  ? "No Show By Other Medium"
                                  : "No Show By Other"}
                              </option>
                            )}
                            {appoinment.session_type === "5" ? (
                              <option value={"give-add-1"}>{"Give Add’l"}</option>
                            ) : (
                              ""
                            )}
                          </select>
                          :
                          'N/A'
                        }
                      </>
                      :

                      <Button
                        status={0}
                        onClick={() => openNoShowBox(appoinment.attendees, '', appoinment)}
                        type="button"
                        className={`resend-btn w-130`}
                        title="No Show"
                      />
                    }
                  </td>
                  {/* <td>
                    <div>
                      <p className="d-flex">
                        <div> Client:</div>{" "}
                        <div className="d5a67b ml-1">
                          {appoinment.client_email}{" "}
                        </div>
                      </p>
                      <p className="d-flex">
                        {" "}
                        <div>Medium:</div>{" "}
                        <div className="d5a67b ml-1">
                          {appoinment.medium_email}
                        </div>
                      </p>
                    </div>
                  </td> */}
                  {/* <td>
                    <select
                      onChange={(e) =>
                        changeHandler(e, 2, appoinment.appointmentId)
                      }
                      className="appointment-select-box"
                    >
                      <option selected disabled>
                        Select
                      </option>
                      {Object.entries(appoinment.userArray).map((show) => (
                        <option value={show[0]}>{show[1]}</option>
                      ))}
                    </select>
                  </td> */}
                  <td className="position-relative ">
                    {appoinment.attendees == undefined ?
                      <>
                        {(appoinment.session_type === "1" ||
                          appoinment.session_type === "2" || appoinment.session_type === "16") && (
                            <>
                              {" "}
                              <Button
                                status={0}
                                onClick={(e) =>
                                  blockUser(
                                    appoinment.id,
                                    appoinment.medium_id,
                                    appoinment.client_id
                                  )
                                }
                                type="button"
                                className={`resend-btn w-130 tooltip-2-focus`}
                                title={
                                  parseInt(appoinment.is_blocked) === 0
                                    ? "Block"
                                    : "Unblock"
                                }
                              />
                              <p className="tooltip-2 block-tooltip-app">
                                {parseInt(appoinment.is_blocked) === 0
                                  ? "Only click if you do not want to work with this person at all."
                                  : "Only click if you want to work this person."}
                              </p>
                            </>
                          )}
                      </>
                      :
                      'N/A'
                    }
                  </td>
                  <td className="dot-image">
                    <img
                      onClick={(e) =>
                        onClickHandler(e, appoinment.appointmentId, "completed")
                      }
                      src="../images/Unionblck.png"
                      className="pointer dot-image-inside"
                      alt="dots"
                    />
                    {appoinment.status && (
                      <div className="approve-btn-1 approve-btn z-index-1">
                        {(appoinment.meeting_link !== 'NULL' && appoinment.meeting_link !== '' && appoinment.is_zoom_link == "1") &&  <span className="w-100 anchor-white ">
                          <a className="width-80px d-inline-block" onClick={() => openZoomLinkPopup(appoinment)}
                            href={`javascript:void();`}>
                            Find Zoom
                          </a>
                        </span>}
                        <span className="w-100 anchor-white ">

                          {(parseInt(id) === parseInt(appoinment.medium_id) && (appoinment.session_type === "7" || appoinment.session_type === "8" )) ?
                            <a onClick={() => openContactPopup(appoinment)}
                              href={`javascript:void();`}>
                              Contact
                            </a>
                            :
                            <a
                              onClick={() =>
                                coptText(
                                  (appoinment.session_type === "1" ||
                                    appoinment.session_type === "2" || appoinment.session_type === "16" || appoinment.session_type === "7" || appoinment.session_type === "8") &&
                                    parseInt(id) ===
                                    parseInt(appoinment.client_id)
                                    ? appoinment.medium_email
                                    : appoinment.client_email
                                )
                              }
                              href={`mailto:${(appoinment.session_type === "1" ||
                                appoinment.session_type === "2" || appoinment.session_type === "16" || appoinment.session_type === "7" || appoinment.session_type === "8") &&
                                parseInt(id) === parseInt(appoinment.client_id)
                                ? appoinment.medium_email
                                : appoinment.client_email
                                }`}
                            >
                              Contact
                            </a>
                          }
                        </span>
                      
                        {/* <span
                          className="w-100"
                          onClick={(e) => {
                            changeHandler(
                              e,
                              2,
                              appoinment.appointmentId,
                              appoinment.client_id
                            );
                          }}
                        >
                          No Show
                        </span> */}
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Appointments Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          rowsPerPageOptions={[5, 10, 25]}
          count={allCompletedCount}
          activeTab={"mediumcompleted"}
          getPaginationData={getPaginationData}
        />
      </div>
      <ConfirmCancel
        deleteUser={confirmRequest}
        cancelRequest={cancelRequest}
        id={"ConfirmCancelcompletedmedium"}
      />
      <NoshowBox
        noShowActive={noShowActive}
        list={attendeesList}
        noShowRequest={noshowGroupAppointment}
        noshowRemoveGroupAppointment={noshowRemoveGroupAppointment}
        noshowFrom={noshowFrom}
      />
      <SettlePaymentOutsitePopup
        deleteUser={settleoutsidepopupconfirmRequest}
        cancelRequest={settleoutsidepopupcancelRequest}
        id="SettlePaymentOutsitePopup"
      />
      <BackFillConfirmation
        cancelRequest={() => window.loadModal2("#BackFillConfirmation", "hide")}
        deleteUser={() => { window.loadModal2("#BackFillConfirmationTime", "show"); window.loadModal2("#BackFillConfirmation", "hide") }}
        id={'BackFillConfirmation'}
      />
      <BackFillConfirmationTime
        goToMediumBackFill={goToMediumBackFill}
        backFillId={backFillId}
        cancelRequest={() => window.loadModal2("#BackFillConfirmationTime", "hide")}
        deleteUser={() => window.loadModal2("#BackFillConfirmationTime", "hide")}
        id={'BackFillConfirmationTime'} />
      <Contactpopup
        activeContact={activeContact}

      />
      <ZoomLinkPopup 
       activeContact={activeContact}
      />
    </>
  );
}
