import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/button";
import Pagination from "../../../../components/tablePagination";
import CsvDownloadAll from "../../../../components/csvdownloadAll";
import CopyComponent from "../../../../components/copyComponent";
import CsvDownload from "../../../../components/csvdownload";
import Search from "../../../../components/search";
import Sorting from "../../../../components/sorting";
import ToggleTestUser from "../../Subcomponents/toogletestuser";
// import UserStatus from "../../../../components/userstatus";
import { filterList } from "../data";
import { fireFilter } from "../functions";
import InfoPopupclient from "../subcomponent";
import { toast } from "react-toastify";
import { setAllOutreachClientsPage, setAllOutreachClientsrowsPerPage, setPathArray } from "../../../../reducer/adminSlice";
import { enableScvStatus } from "../../../../services/admin";
import { items } from "../../calendar/data";

const headers = [
  { label: "Screen Name", key: "screen_name" },
  { label: "Client Name", key: "client_name" },
  { label: "Email", key: "email" },
  { label: "Created", key: "created_at" },

];

export default function FreeClients(props) {
  const navigate = useNavigate();
  const {
    clients,
    onClickHandler,
    updateUser,
    resendEmail,
    filterListClients,
    filterReset,
    getPaginationData,
    setFilterValues,
    setSearchValues,
    searchValue,
    filterValues,
    showOprater,
    setShowOprater,
    optionsList,
    setOptionsList,
    filterValueOptions,
    setFilterValueOptions,
    operatorList,
    setOperatorList,
    setSortingNameOrderBy,
    setSortingTabType,
    allOutreachClientsPage,
    setFilterOprater,
    filterOprater,
    allOutreachClientsrowsPerPage,
    setClients
  } = props;
  let info = JSON.parse(localStorage.getItem('pagination'))
  const {
    allOutreachClientsCount
  } = useSelector((state) => state.users);
  const [active, setActive] = useState([]);
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const rowperpage = searchParams.get('rowperpage');
  const [checked, setChecked] = useState(false);
  //   const [toggleStates, setToggleStates] = useState(() => {
  //     const initialState = {};
  //     clients.forEach(client => {
  //         initialState[client.id] = client.test_user === 1; // Set initial toggle state based on test_user value
  //     });
  //     return initialState;
  // });

  useEffect(() => {
    if (page !== null) {

      dispatch(setAllOutreachClientsPage(page));
    }
    if (rowperpage !== null) {
      dispatch(setAllOutreachClientsrowsPerPage(rowperpage));
    }

  }, [])
  console.log(info)
  const navigateUser = (id) => {
    window.renderFirst = false;
    dispatch(setPathArray(id.id));
    navigate(`/view/${id.id}?view=0&search=${filterValues}&searchName=${Object.values(searchValue)}&page=${info !== null ? info.page : 1}&rowperpage=${info !== null ? info.rowsPerPage : 10}&searchOprator=${showOprater}&searchFilterOprater=${filterOprater}`);
  };
  const navigateEdit = (e, id) => {
    window.renderFirst = false
    if (e.target.className == "capitalize") {
      navigate(`/edit/${id.id}?view=0&search=${filterValues}&searchName=${Object.values(searchValue)}&page=${info !== null ? info.page : 1}&searchFilterOprater=${filterOprater}&rowperpage=${info !== null ? info.rowsPerPage : 10}&searchOprator=${showOprater}`);
    } else {

    }
  };
  // const onChange = (id) => {
  //   const newStatus = !toggleStates[id];
  //   setToggleStates(prevState => ({
  //       ...prevState,
  //       [id]: newStatus // Toggle the state for the specific client
  //   }));
  //     console.log(newStatus ? 1 : 0,"anuj");
  // };
  const openMoreInfo = (e, info) => {
    e.stopPropagation();
    // console.log(info,"info")
    let length = Object.keys(info.appointment_counts).length;
    console.log("admin info", info.appointment_counts, length);
    if (length !== 0) {

      // let array = [];
      // Object.values(info.appointment_counts).map((item) => {
      //     // array.push({heading: info:item});
      //   });

      setActive(info.appointment_counts);
      // console.log(array,"ayfdsg")
      window.loadModal2("#InfoPopupclient", "show");
    }
    else {
      toast.error("Nothing to display", { position: "top-center" })
    }
    return;
  };

  const enableScvStatusHanler = (info) => {
    enableScvStatus(info.id).then((data) => {
      if (data.data.status) {
console.log(clients ,info)
        let newInfo = clients.map((item) => {
          if (item.id == info.id) {
            return {
              ...item,
              scv_status: 0,
              client_status:false
            }
          }
          else {
            return {
              ...item,
            }
          }
        })
        setClients(newInfo);
        toast.success(data.data.message, { position: "top-center" })
      }
      else {
        toast.error(data.data.message, { position: "top-center" })
      }
    })
  }
  return (
    <>
      <div className="d-flex justify-content-end gap-10 flex-wrap flex-column-admin-client">
        <Search callApi={filterListClients}
          list={filterList}
          setSearchValues={setSearchValues}
          setFilterValues={setFilterValues}
          searchValue={searchValue}
          filterValues={filterValues}
          operatorList={operatorList}
          setOperatorList={setOperatorList}
          showOprater={showOprater}
          setShowOprater={setShowOprater}
          isDatepickerShow={1}
          setFilterOprater={setFilterOprater}
          filterOprater={filterOprater}
          optionsList={optionsList}
          setOptionsList={setOptionsList}
          filterValueOptions={filterValueOptions}
          setFilterValueOptions={setFilterValueOptions}
          isOperator={1}
        />
        {/* <Button
          status={0}
          type="button"
          onClick={filterReset}
          className={`resend-btn  width-80px  reset-btn-123`}
          title="Reset"
        /> */}
      </div>
      <CsvDownloadAll
        href={`${process.env.REACT_APP_API_URL_EXPORT}` + 'export-client-list'}
      //  data={fireFilter(clients)} headers={headers}filename={'outreachclients'}
      />
      <CsvDownload
        href={`${process.env.REACT_APP_API_URL_EXPORT}` + 'export-client-list?client_type=outreach'}
      //  data={fireFilter(clients)} headers={headers}filename={'outreachclients'}
      />
      <div className="table-box-medium">
        <table className="caseTable">
          <tbody>
            <tr className="dark">
              <th>&nbsp;</th>
              <th>Screen Name</th>
              <th > <div className="d-flex align-items-center"> <span>Client Name </span> <Sorting callApi={filterListClients} shortedName={"client_name"} tableName={"client"}
                setSortingNameOrderBy={setSortingNameOrderBy}
                setSortingTabType={setSortingTabType}
                tab={1}
              /></div></th>

              <th>Email</th>
              <th>Test User</th>
              <th>Referral Code</th>
              <th>SCV Status</th>
              <th > <div className="d-flex align-items-center"> <span>Created </span> <Sorting callApi={filterListClients} shortedName={"created_at_order_by"} tableName={"client"}
                setSortingNameOrderBy={setSortingNameOrderBy}
                setSortingTabType={setSortingTabType}
                tab={1} /></div></th>

              <th>Activation email</th>
              {/* <th>Online Status</th> */}
              <th>Action</th>
            </tr>
            {clients.length > 0 ? (
              clients.map((client) => (
                <tr
                  onClick={(e) => navigateEdit(e, client)}
                  className="innr pointer"
                >
                  <td>
                    {" "}
                    <img src="/images/undraw_profile.svg" className="profile-image-avatar" alt="avatar" />
                  </td>
                  <td>
                    {/* <input type="checkbox" id="two" />
                    <label htmlFor="two"></label> */}

                    <p className="capitalize">
                      {client.screen_name}
                      {client.comments && (<b style={{
                        fontSize: '22px',
                      }}>*</b>)}
                    </p>
                  </td>
                  <td>
                    {/* <input type="checkbox" id="two" />
                    <label htmlFor="two"></label> */}

                    <p className="capitalize">
                      {client.first_name}&nbsp;{client.last_name}
                    </p>
                  </td>
                  <td>{client.email} <CopyComponent value={client.email} /></td>
                  <td> <ToggleTestUser
                    clientId={client.id}
                    initialStatus={client.test_user === 1} // Set initial toggle state
                    onStatusChange={(id, newStatus) => {
                      console.log(`Client ${id} status changed to ${newStatus ? 'on' : 'off'}`);
                      // Additional handling if necessary
                    }}
                  /></td>
                  <td>{client.referralCode ? client.referralCode : "-"}</td>
                  <td>{client.scv_status == 1 ? "Rejected" : "-"}</td>
                  <td>
                    {client.created_at}
                  </td>

                  <td>
                    <Button
                      status={client.resent_status}
                      onClick={(e) => resendEmail(e, client.id, "outreach")}
                      type="button"
                      className="resend-btn"
                      title="Resend"
                    />
                  </td>
                  {/* <td>
                    <UserStatus status={client.is_available} />
                  </td> */}
                  <td className="dott">
                    <img
                      onClick={(e) => onClickHandler(e, client.id, "outreach")}
                      src="/images/Unionblck.png"
                      className="padding-10"
                      alt="unblock"
                    />

                    {client.client_status && (
                      <div className="approve-btn-1 approve-btn z-index-1">
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            navigateUser(client);
                          }}
                        >
                          View
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            updateUser(
                              client,
                              parseInt(client.status) === 4 ? 1 : 4,
                              "freeclient",
                              ""
                            );
                          }}
                        >
                          {parseInt(client.status) === 4
                            ? "UnRestrict"
                            : "Restrict"}
                        </span>

                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            updateUser(client, 3, "delete", "");
                          }}
                        >
                          Delete
                        </span>
                        <span
                          onClick={(e) => openMoreInfo(e, client)}
                        >
                          Info
                        </span>
                        {
                          client.scv_status == 1 &&
                          <span
                            onClick={() => enableScvStatusHanler(client)}
                          >
                            Enable scv
                          </span>
                        }
                      </div>
                    )}
                  </td>
                  {/* <td>
                    <Link to={`/view/${client.id}`}>
                      <img src="../images/blue-e.png" alt="view profile" />
                      View Profile
                    </Link>
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  No Clients Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          rowsPerPageOptions={[5, 10, 25]}
          count={allOutreachClientsCount}
          activeTab="outreachclient"
          getPaginationData={getPaginationData}
          allOutreachClientsPage={allOutreachClientsPage}
          allOutreachClientsrowsPerPage={allOutreachClientsPage}
        />
        <InfoPopupclient feedBackinfo={active} />
      </div>
    </>
  );
}