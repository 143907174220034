import React from "react";
import AdvanceAppointment from "../../../client/appointment/advanceappointment";
import { useDispatch } from "react-redux";
import {
  setBookingDate,
  setBookingTime,
} from "../../../../reducer/clientsSlice";

export default function PreviewModal({
  user_type,
  setPreview,
  activesession_id,
  setActivesession_id,
  mediumAvailabilityId,
  type,
  preferncesSection,
  activeSubSessionId,
  is_group_session,
  client_id = "",
  from = "",
  cancelRequest,
  requestHandler,
  warningMessage,
  errorType,
  colorType,
  reschedeId,
}) {
  // const { id  } = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const previewHandler = () => {
    setPreview(false);
    dispatch(setBookingTime(""));
    dispatch(setBookingDate(""));
    setActivesession_id("");
  };
  return (
    <div>
      <div
        id="prvvvvv"
        class="modal fade bd-example-modal-lg mt-5 preview-modal-availability"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div class="modal-dialog modal-lg modal-dialog-preview">
          <div class="modal-content">
            <div class="modal-header position-relative">
              <h5 class="modal-title" id="exampleModalLabel">
                {" "}
                Client Booking View
              </h5>
              {from !== "client" && (
                <button
                  onClick={previewHandler}
                  type="button"
                  class="close about-us-review-close  calendar-view-creoss-icin"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="cross-preview-box" aria-hidden="true">
                    &times;
                  </span>
                </button>
              )}
            </div>
            {from == "client" && (
              <>
                <div className="schedule-popup">
                  <h3 className=" mt-2 text-center ">
                    Re-Schedule Appointment ({"2A" + reschedeId.id + "X"})
                  </h3>
                  {warningMessage !== "" && (
                    <div className={`eroorbox warning-color`}>
                      {warningMessage}
                    </div>
                  )}
                  {errorType.map((item) => (
                    <div className={`eroorbox ${colorType}`}>{item}</div>
                  ))}
                </div>

                <div className="register-medium">
                  <div className="confirm-delete-wrapper ">
                    <div className="confirm-btn-box">
                      <div
                        className="register-medium-confirnation client-reschedule-btns text-center margin-unset"
                        onClick={cancelRequest}
                        // data-dismiss="modal"
                      >
                        Cancel
                      </div>
                      <div
                        className="register-medium-confirnation client-reschedule-btns text-center margin-unset"
                        // data-dismiss="modal"
                        onClick={requestHandler}
                      >
                        Update
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div
              className={`modal-body ${
                from == "client" ? "" : "p-5"
              } calendar-view`}
            >
              <AdvanceAppointment
                is_group_session={is_group_session}
                activesession_id={activesession_id}
                user_type={user_type}
                mediumAvailabilityId={mediumAvailabilityId}
                type={type}
                preferncesSection={preferncesSection}
                activeSubSessionId={activeSubSessionId}
                client_id={client_id}
                // handleStart={handleStart}
                // handleReset={handleReset}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
