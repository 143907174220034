import API from "../api/api";

const config = (token) => {
  return {
    headers: {
      "Content-type": "application/json",

      "X-Frame-Options": "DENY",
      "Content-Security-Policy": "style-src 'self'",
      Authorization: `Bearer ${token}`,
    },
  };
};

export const getUsers = async (data) => {
  let token = localStorage.getItem("access_token");
  let page = data !== undefined ? data.page : 1;
  let rowsPerPage = data !== undefined ? data.rowsPerPage : 10;

  const response = await API.get(
    `admin/pending-approvals?page=${page}&rowsPerPage=${rowsPerPage}&field=${data.field || ""}&value=${data.value || ""}&oprater=${data.oprater || ""}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};



export const getUnverfiedEmailsMediums = async (data) => {
  let token = localStorage.getItem("access_token");
  let page = data !== undefined ? data.page : 1;
  let rowsPerPage = data !== undefined ? data.rowsPerPage : 10;

  const response = await API.get(
    `unverified-users?usertype=2&page=${page}&rowsPerPage=${rowsPerPage}&field=${data.field || ""}&value=${data.value || ""}&oprater=${data.oprater || ""}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};



export const getUnverfiedEmailsClients = async (data) => {
  let token = localStorage.getItem("access_token");
  let page = data !== undefined ? data.paginationdata.page : 1;
  let rowsPerPage = data !== undefined ? data.paginationdata.rowsPerPage : 10;

  const response = await API.get(
    `unverified-users?usertype=3&page=${page}&rowsPerPage=${rowsPerPage}&status=${data.status
    }&client_name=${data.client_name}&email=${data.email}&created_at=${data.created_at
    }&created_at_order_by=${data.created_at_order_by}&orderBy=${data.orderBy}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const approveUser = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("admin/approve-user", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const testUser = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("admin/test-user", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getAdminAppointmentCount = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`admin/get-dashboard-counts`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
//
export const getAllMediums = async (data) => {
  let page = data !== undefined ? data.page : 1;
  let rowsPerPage = data !== undefined ? data.rowsPerPage : 10;

  let token = localStorage.getItem("access_token");
  // const {page=1,rowsPerPage=10}=data

  const response = await API.get(
    `admin/get-all-mediums?page=${page}&rowsPerPage=${rowsPerPage}&email=${data.email}&screen_name=${data.screen_name}&created_at=${data.created_at}&field=${data.field || ""}&value=${data.value || ""}&oprater=${data.oprater || ""}&searchFrom=${data.searchFrom || ""}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getAllProfessionals = async (data) => {
  let token = localStorage.getItem("access_token");
  let page = data !== undefined ? data.page : 1;
  let rowsPerPage = data !== undefined ? data.rowsPerPage : 10;

  const response = await API.get(
    `admin/get-professional-mediums?page=${page}&rowsPerPage=${rowsPerPage}&email=${data.email}&screen_name=${data.screen_name}&created_at=${data.created_at}&field=${data.field || ""}&value=${data.value || ""}&oprater=${data.oprater || ""}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getAllIntermediate = async (data) => {
  let token = localStorage.getItem("access_token");
  let page = data !== undefined ? data.page : 1;
  let rowsPerPage = data !== undefined ? data.rowsPerPage : 10;

  const response = await API.get(
    `admin/get-intermediate-mediums?page=${page}&rowsPerPage=${rowsPerPage}&email=${data.email}&screen_name=${data.screen_name}&created_at=${data.created_at}&field=${data.field || ""}&value=${data.value || ""}&oprater=${data.oprater || ""}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getAllNovice = async (data) => {
  let token = localStorage.getItem("access_token");
  let page = data !== undefined ? data.page : 1;
  let rowsPerPage = data !== undefined ? data.rowsPerPage : 10;

  const response = await API.get(
    `admin/get-novice-mediums?page=${page}&rowsPerPage=${rowsPerPage}&email=${data.email}&screen_name=${data.screen_name}&created_at=${data.created_at}&field=${data.field || ""}&value=${data.value || ""}&oprater=${data.oprater || ""}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
// export const getAllNovice = async (data) => {
//   let token = localStorage.getItem("access_token");

//   const response = await API.get(`admin/get-novice-mediums/from=${data.from}/to=${data.to}`, config(token))
//     .then((data) => {
//       return data;
//     })
//     .catch((err) => {
//       return err;
//     });
//   return response;
// };
export const getAllDeclinedMediums = async (data) => {
  let token = localStorage.getItem("access_token");
  let page = data !== undefined ? data.page : 1;
  let rowsPerPage = data !== undefined ? data.rowsPerPage : 10;

  const response = await API.get(
    `admin/get-declined-mediums?page=${page}&rowsPerPage=${rowsPerPage}&email=${data.email}&screen_name=${data.screen_name}&created_at=${data.created_at}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getAllAppointments = async (data, appointmentType) => {
  console.log("paginatedData", data);
  console.log("appointmentType", appointmentType);
  let token = localStorage.getItem("access_token");
  let page = data !== undefined ? data.page : 1;
  let rowsPerPage = data !== undefined ? data.rowsPerPage : 10;
  let screen_name =
    data !== undefined && data.screen_name !== undefined
      ? data.screen_name
      : "";
  let session_type =
    data !== undefined && data.session_type !== undefined
      ? data.session_type
      : "";
  let name = data !== undefined && data.name !== undefined ? data.name : "";
  let medium_email =
    data !== undefined && data.medium_email !== undefined
      ? data.medium_email
      : "";
  let client_email =
    data !== undefined && data.client_email !== undefined
      ? data.client_email
      : "";
  let meeting_id =
    data !== undefined && data.meeting_id !== undefined ? data.meeting_id : "";
  const response = await API.get(
    `admin/get-all-appointments?page=${page}&appoitment_type=${appointmentType}&rowsPerPage=${rowsPerPage}&session_type=${session_type}&medium_name=${screen_name}&name=${name}&medium_email=${medium_email}&client_email=${client_email}&meeting_id=${meeting_id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

///
export const getAllUpComingAppointments = async (data, appointmentType) => {
  let token = localStorage.getItem("access_token");
  let page = data !== undefined ? data.page : 1;
  let rowsPerPage = data !== undefined ? data.rowsPerPage : 10;
  let screen_name =
    data !== undefined && data.screen_name !== undefined
      ? data.screen_name
      : "";
  let session_type =
    data !== undefined && data.session_type !== undefined
      ? data.session_type
      : "";
  let name = data !== undefined && data.name !== undefined ? data.name : "";
  let medium_email =
    data !== undefined && data.medium_email !== undefined
      ? data.medium_email
      : "";
  let client_email =
    data !== undefined && data.client_email !== undefined
      ? data.client_email
      : "";
  let meeting_id =
    data !== undefined && data.meeting_id !== undefined ? data.meeting_id : "";
  let created_at =
    data !== undefined && data.created_at !== undefined ? data.created_at : "";
  let oprater =
    data !== undefined && data.oprater !== undefined ? data.oprater : "";
  let country =
    data !== undefined && data.country !== undefined ? data.country : "";
  let session_id =
    data !== undefined && data.session_id !== undefined ? data.session_id : "";
  let orderBy = data !== undefined && data.orderBy ? data.orderBy : ""
  let order = data !== undefined && data.order ? data.order : ""
  const response = await API.get(
    `admin/get-admin-appointments/upcoming?page=${page}&rowsPerPage=${rowsPerPage}&session_type=${session_type}&medium_name=${screen_name}&name=${name}&medium_email=${medium_email}&client_email=${client_email}
    &meeting_id=${meeting_id}&created_at=${created_at}&orderBy=${orderBy}&order=${order}&oprater=${oprater}&country=${country}&session_id=${session_id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getAllCompletedAppointments = async (data, appointmentType) => {

  let token = localStorage.getItem("access_token");
  let page = data !== undefined ? data.page : 1;
  let rowsPerPage = data !== undefined ? data.rowsPerPage : 10;
  let screen_name =
    data !== undefined && data.screen_name !== undefined
      ? data.screen_name
      : "";
  let session_type =
    data !== undefined && data.session_type !== undefined
      ? data.session_type
      : "";
  let name = data !== undefined && data.name !== undefined ? data.name : "";
  let medium_email =
    data !== undefined && data.medium_email !== undefined
      ? data.medium_email
      : "";
  let client_email =
    data !== undefined && data.client_email !== undefined
      ? data.client_email
      : "";
  let meeting_id =
    data !== undefined && data.meeting_id !== undefined ? data.meeting_id : "";
  let created_at =
    data !== undefined && data.created_at !== undefined ? data.created_at : "";
  let oprater =
    data !== undefined && data.oprater !== undefined ? data.oprater : "";
  let country =
    data !== undefined && data.country !== undefined ? data.country : "";
  let session_id =
    data !== undefined && data.session_id !== undefined ? data.session_id : "";
  let orderBy = data !== undefined && data.orderBy ? data.orderBy : ""
  let order = data !== undefined && data.order ? data.order : ""
  const response = await API.get(
    `admin/get-admin-appointments/completed?page=${page}&rowsPerPage=${rowsPerPage}&session_type=${session_type}&medium_name=${screen_name}&name=${name}&medium_email=${medium_email}&client_email=${client_email}&meeting_id=${meeting_id}&created_at=${created_at}&orderBy=${orderBy}&order=${order}&oprater=${oprater}&country=${country}&session_id=${session_id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getAllUpCancelledAppointments = async (data, appointmentType) => {
  let token = localStorage.getItem("access_token");
  let page = data !== undefined ? data.page : 1;
  let rowsPerPage = data !== undefined ? data.rowsPerPage : 10;
  let screen_name =
    data !== undefined && data.screen_name !== undefined
      ? data.screen_name
      : "";
  let session_type =
    data !== undefined && data.session_type !== undefined
      ? data.session_type
      : "";
  let name = data !== undefined && data.name !== undefined ? data.name : "";
  let medium_email =
    data !== undefined && data.medium_email !== undefined
      ? data.medium_email
      : "";
  let client_email =
    data !== undefined && data.client_email !== undefined
      ? data.client_email
      : "";
  let meeting_id =
    data !== undefined && data.meeting_id !== undefined ? data.meeting_id : "";
  let created_at =
    data !== undefined && data.created_at !== undefined ? data.created_at : "";
  let oprater =
    data !== undefined && data.oprater !== undefined ? data.oprater : "";
  let country =
    data !== undefined && data.country !== undefined ? data.country : "";
  let session_id =
    data !== undefined && data.session_id !== undefined ? data.session_id : "";
  let orderBy = data !== undefined && data.orderBy ? data.orderBy : ""
  let order = data !== undefined && data.order ? data.order : ""
  const response = await API.get(
    `admin/get-admin-appointments/cancelled?page=${page}&rowsPerPage=${rowsPerPage}&session_type=${session_type}&medium_name=${screen_name}&name=${name}&medium_email=${medium_email}&client_email=${client_email}&meeting_id=${meeting_id}&created_at=${created_at}&orderBy=${orderBy}&order=${order}&oprater=${oprater}&country=${country}&session_id=${session_id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;

};
export const getAllNoShowsAppointments = async (data, appointmentType) => {

  let token = localStorage.getItem("access_token");
  let page = data !== undefined ? data.page : 1;
  let rowsPerPage = data !== undefined ? data.rowsPerPage : 10;
  let screen_name =
    data !== undefined && data.screen_name !== undefined
      ? data.screen_name
      : "";
  let session_type =
    data !== undefined && data.session_type !== undefined
      ? data.session_type
      : "";
  let name = data !== undefined && data.name !== undefined ? data.name : "";
  let medium_email =
    data !== undefined && data.medium_email !== undefined
      ? data.medium_email
      : "";
  let client_email =
    data !== undefined && data.client_email !== undefined
      ? data.client_email
      : "";
  let meeting_id =
    data !== undefined && data.meeting_id !== undefined ? data.meeting_id : "";
  let created_at =
    data !== undefined && data.created_at !== undefined ? data.created_at : "";
  let oprater =
    data !== undefined && data.oprater !== undefined ? data.oprater : "";
  let country =
    data !== undefined && data.country !== undefined ? data.country : "";
  let session_id =
    data !== undefined && data.session_id !== undefined ? data.session_id : "";
  let orderBy = data !== undefined && data.orderBy ? data.orderBy : ""
  let order = data !== undefined && data.order ? data.order : ""
  const response = await API.get(
    `admin/get-admin-appointments/noshow?page=${page}&rowsPerPage=${rowsPerPage}&session_type=${session_type}&medium_name=${screen_name}&name=${name}&medium_email=${medium_email}&client_email=${client_email}&meeting_id=${meeting_id}&created_at=${created_at}&orderBy=${orderBy}&order=${order}&oprater=${oprater}&country=${country}&session_id=${session_id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

//
export const getAllRestrictedAppointments = async (data, appointmentType) => {

  let token = localStorage.getItem("access_token");
  let page = data !== undefined ? data.page : 1;
  let rowsPerPage = data !== undefined ? data.rowsPerPage : 10;
  let screen_name =
    data !== undefined && data.screen_name !== undefined
      ? data.screen_name
      : "";
  let session_type =
    data !== undefined && data.session_type !== undefined
      ? data.session_type
      : "";
  let name = data !== undefined && data.name !== undefined ? data.name : "";
  let medium_email =
    data !== undefined && data.medium_email !== undefined
      ? data.medium_email
      : "";
  let client_email =
    data !== undefined && data.client_email !== undefined
      ? data.client_email
      : "";
  let meeting_id =
    data !== undefined && data.meeting_id !== undefined ? data.meeting_id : "";
  let created_at =
    data !== undefined && data.created_at !== undefined ? data.created_at : "";
  let oprater =
    data !== undefined && data.oprater !== undefined ? data.oprater : "";
  let country =
    data !== undefined && data.country !== undefined ? data.country : "";
  let session_id =
    data !== undefined && data.session_id !== undefined ? data.session_id : "";
  let orderBy = data !== undefined && data.orderBy ? data.orderBy : ""
  let order = data !== undefined && data.order ? data.order : ""
  const response = await API.get(
    `admin/get-admin-appointments/restrict?page=${page}&rowsPerPage=${rowsPerPage}&session_type=${session_type}&medium_name=${screen_name}&name=${name}&medium_email=${medium_email}&client_email=${client_email}&meeting_id=${meeting_id}&created_at=${created_at}&orderBy=${orderBy}&order=${order}&oprater=${oprater}&country=${country}&session_id=${session_id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getAllEndedEarlyAppointments = async (data, appointmentType) => {

  let token = localStorage.getItem("access_token");
  let page = data !== undefined ? data.page : 1;
  let rowsPerPage = data !== undefined ? data.rowsPerPage : 10;
  let screen_name =
    data !== undefined && data.screen_name !== undefined
      ? data.screen_name
      : "";
  let session_type =
    data !== undefined && data.session_type !== undefined
      ? data.session_type
      : "";
  let name = data !== undefined && data.name !== undefined ? data.name : "";
  let medium_email =
    data !== undefined && data.medium_email !== undefined
      ? data.medium_email
      : "";
  let client_email =
    data !== undefined && data.client_email !== undefined
      ? data.client_email
      : "";
  let meeting_id =
    data !== undefined && data.meeting_id !== undefined ? data.meeting_id : "";
  let created_at =
    data !== undefined && data.created_at !== undefined ? data.created_at : "";
  let oprater =
    data !== undefined && data.oprater !== undefined ? data.oprater : "";
  let country =
    data !== undefined && data.country !== undefined ? data.country : "";
  let session_id =
    data !== undefined && data.session_id !== undefined ? data.session_id : "";
  let orderBy = data !== undefined && data.orderBy ? data.orderBy : ""
  let order = data !== undefined && data.order ? data.order : ""
  const response = await API.get(
    `admin/get-admin-appointments/endearly?page=${page}&rowsPerPage=${rowsPerPage}&session_type=${session_type}&medium_name=${screen_name}&name=${name}&medium_email=${medium_email}&client_email=${client_email}&meeting_id=${meeting_id}&created_at=${created_at}&orderBy=${orderBy}&order=${order}&oprater=${oprater}&country=${country}&session_id=${session_id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

//
export const getAllOngoingAppointments = async (data, appointmentType) => {
  let token = localStorage.getItem("access_token");
  let page = data !== undefined ? data.page : 1;
  let rowsPerPage = data !== undefined ? data.rowsPerPage : 10;
  let screen_name =
    data !== undefined && data.screen_name !== undefined
      ? data.screen_name
      : "";
  let session_type =
    data !== undefined && data.session_type !== undefined
      ? data.session_type
      : "";
  let name = data !== undefined && data.name !== undefined ? data.name : "";
  let medium_email =
    data !== undefined && data.medium_email !== undefined
      ? data.medium_email
      : "";
  let client_email =
    data !== undefined && data.client_email !== undefined
      ? data.client_email
      : "";
  let meeting_id =
    data !== undefined && data.meeting_id !== undefined ? data.meeting_id : "";
  let created_at =
    data !== undefined && data.created_at !== undefined ? data.created_at : "";
  let oprater =
    data !== undefined && data.oprater !== undefined ? data.oprater : "";
  let country =
    data !== undefined && data.country !== undefined ? data.country : "";
  let session_id =
    data !== undefined && data.session_id !== undefined ? data.session_id : "";
  let orderBy = data !== undefined && data.orderBy ? data.orderBy : ""
  let order = data !== undefined && data.order ? data.order : ""
  const response = await API.get(
    `admin/get-admin-appointments/ongoing?page=${page}&rowsPerPage=${rowsPerPage}&session_type=${session_type}&medium_name=${screen_name}&name=${name}&medium_email=${medium_email}&client_email=${client_email}&meeting_id=${meeting_id}&created_at=${created_at}&orderBy=${orderBy}&order=${order}&oprater=${oprater}&country=${country}&session_id=${session_id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
///
export const getAllClients = async (data) => {
  let token = localStorage.getItem("access_token");
  let page = data !== undefined ? data.paginationdata.page : 1;
  let rowsPerPage = data !== undefined ? data.paginationdata.rowsPerPage : 10;
  let url = data.new_appt !== undefined && data.new_appt ?
    `admin/users/3${data.clientType !== "" ? `/${data.clientType}` : ""
    }?page=${page}&rowsPerPage=${rowsPerPage}&status=${data.status
    }&field=${data.field}&value=${data.value}&oprater=${data.oprater}&searchFrom=${data.searchFrom}&client_name=${data.client_name}&email=${data.email}&created_at=${data.created_at
    }&created_at_order_by=${data.created_at_order_by}&orderBy=${data.orderBy}&new_appt=${true}`
    :
    `admin/users/3${data.clientType !== "" ? `/${data.clientType}` : ""
    }?page=${page}&rowsPerPage=${rowsPerPage}&status=${data.status
    }&field=${data.field}&value=${data.value}&oprater=${data.oprater}&searchFrom=${data.searchFrom}&client_name=${data.client_name}&email=${data.email}&created_at=${data.created_at
    }&created_at_order_by=${data.created_at_order_by}&orderBy=${data.orderBy}`
  const response = await API.get(
    url,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
//

export const getMediumProfileDetails = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `admin/get-medium-details?medium=${id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getClientProfileDetails = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `admin/get-medium-details?medium=${id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const changeMediumshipStatus = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "admin/change-current-mediumship-level",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const changeAppointmentStatus = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "admin/change-status-appointment",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const changeSessionPrefrences = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "admin/change-session-preferences",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const filterAllAppointments = async (body) => {
  const {
    session_type = "",
    screen_name = "",
    orderBy = "",
    name = "",
    medium_email = "",
    client_email = "",
    meeting_id = "",
  } = body;

  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `admin/get-all-appointments?session_type=${session_type}&medium_name=${screen_name}&orderBy=${orderBy}&name=${name}&medium_email=${medium_email}&client_email=${client_email}&meeting_id=${meeting_id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const filterAllUpcomingAppointments = async (body) => {
  const {
    session_type = "",
    screen_name = "",
    orderBy = "",
    order = "",
    name = "",
    medium_email = "",
    client_email = "",
    meeting_id = "",
    created_at = "",
    oprater = "",
    country = "",
    session_id = "",
  } = body;

  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `admin/get-admin-appointments/upcoming?session_type=${session_type}&medium_name=${screen_name}&orderBy=${orderBy}&name=${name}&medium_email=${medium_email}&client_email=${client_email}&meeting_id=${meeting_id}&order=${order}&created_at=${created_at}&oprater=${oprater}&country=${country}&session_id=${session_id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const filterAllCompletedAppointments = async (body) => {
  const {
    session_type = "",
    screen_name = "",
    orderBy = "",
    order = "",
    name = "",
    medium_email = "",
    client_email = "",
    meeting_id = "",
    created_at = "",
    oprater = "",
    country = "",
    session_id = "",
  } = body;

  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `admin/get-admin-appointments/completed?session_type=${session_type}&medium_name=${screen_name}&orderBy=${orderBy}&name=${name}&medium_email=${medium_email}&client_email=${client_email}&meeting_id=${meeting_id}&order=${order}&created_at=${created_at}&oprater=${oprater}&country=${country}&session_id=${session_id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const filterAllCancelleddAppointments = async (body) => {
  const {
    session_type = "",
    screen_name = "",
    orderBy = "",
    order = "",
    name = "",
    medium_email = "",
    client_email = "",
    meeting_id = "",
    created_at = "",
    oprater = "",
    country = "",
    session_id = "",
  } = body;

  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `admin/get-admin-appointments/cancelled?session_type=${session_type}&medium_name=${screen_name}&orderBy=${orderBy}&name=${name}&medium_email=${medium_email}&client_email=${client_email}&meeting_id=${meeting_id}&order=${order}&created_at=${created_at}&oprater=${oprater}&country=${country}&session_id=${session_id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const filterAllNoShowAppointments = async (body) => {
  const {
    session_type = "",
    screen_name = "",
    orderBy = "",
    order = "",
    name = "",
    medium_email = "",
    client_email = "",
    meeting_id = "",
    created_at = "",
    oprater = "",
    country = "",
    session_id = "",
  } = body;

  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `admin/get-admin-appointments/noshow?session_type=${session_type}&medium_name=${screen_name}&orderBy=${orderBy}&name=${name}&medium_email=${medium_email}&client_email=${client_email}&meeting_id=${meeting_id}&order=${order}&created_at=${created_at}&oprater=${oprater}&country=${country}&session_id=${session_id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const filterAllRestrictedAppointments = async (body) => {
  const {
    session_type = "",
    screen_name = "",
    orderBy = "",
    order = "",
    name = "",
    medium_email = "",
    client_email = "",
    meeting_id = "",
    created_at = "",
    oprater = "",
    country = "",
    session_id = "",
  } = body;

  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `admin/get-admin-appointments/restrict?session_type=${session_type}&medium_name=${screen_name}&orderBy=${orderBy}&name=${name}&medium_email=${medium_email}&client_email=${client_email}&meeting_id=${meeting_id}&order=${order}&created_at=${created_at}&oprater=${oprater}&country=${country}&session_id=${session_id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const filterAllEndedEarlyAppointments = async (body) => {
  const {
    session_type = "",
    screen_name = "",
    orderBy = "",
    order = "",
    name = "",
    medium_email = "",
    client_email = "",
    meeting_id = "",
    created_at = "",
    oprater = "",
    country = "",
    session_id = "",
  } = body;

  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `admin/get-admin-appointments/endearly?session_type=${session_type}&medium_name=${screen_name}&orderBy=${orderBy}&name=${name}&medium_email=${medium_email}&client_email=${client_email}&meeting_id=${meeting_id}&order=${order}&created_at=${created_at}&oprater=${oprater}&country=${country}&session_id=${session_id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const filterAllOngoingAppointments = async (body) => {
  const {
    session_type = "",
    screen_name = "",
    orderBy = "",
    order = "",
    name = "",
    medium_email = "",
    client_email = "",
    meeting_id = "",
    created_at = "",
    oprater = "",
    country = "",
    session_id = "",
  } = body;

  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `admin/get-admin-appointments/ongoing?session_type=${session_type}&medium_name=${screen_name}&orderBy=${orderBy}&name=${name}&medium_email=${medium_email}&client_email=${client_email}&meeting_id=${meeting_id}&order=${order}&created_at=${created_at}&oprater=${oprater}&country=${country}&session_id=${session_id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const filterAllmediums = async (body) => {
  const { email = "", screen_name = "", created_at = "", orderBy = "", field = "", value = "", oprater = "" } = body;

  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `admin/get-all-mediums?email=${email}&screen_name=${screen_name}&created_at=${created_at}&orderBy=${orderBy}&field=${field}&value=${value}&oprater=${oprater}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const filterAllClients = async (body, type) => {
  const {
    email = "",
    screen_name = "",
    value = "",
    field = "",
    created_at_order_by = "",
    orderBy = "",
    phone = "",
    whatsapp = "",
    website = "",
    country = "",
    agree_to_legal_terms_and_conditionss = "",
    agree_to_starter_client_guidelines = "",
    opt_in_out = "",
    email_verified_at = "",
    timezone = "",
    test_mode = "",
    oprater = "",
    test_user = ""

  } = body;
  const url = type === "restricted" ?
    `admin/users/3?created_at_order_by=${created_at_order_by}&orderBy=${orderBy}&oprater=${oprater}&field=${field}&value=${value}&status=${4}`
    : `admin/users/3/${type}?created_at_order_by=${created_at_order_by}&orderBy=${orderBy}&oprater=${oprater}&field=${field}&value=${value}`

  let token = localStorage.getItem("access_token");

  const response = await API.get(url
    ,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const filterAllFeedbacks = async (body) => {
  const { name, newId, screen_name, medium_email, client_email, session_name, session_type } = body;
  let token = localStorage.getItem("access_token");
  let url = session_type == 1 || session_type == 2 ?
    `get-all-feedbacks?medium_name=${screen_name}&session_id=${newId}&client_name=${name}&medium_email=${medium_email}&client_email=${client_email}&session_name=${session_name}&session_type=${session_type}`
    :
    `get-all-feedbacks?medium_name=${screen_name}&session_id=${newId}&client_name=${name}&medium_email=${medium_email}&client_email=${client_email}&session_name=${session_name}`
  const response = await API.get(
    url,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const filterAllFeedbacksOndemant = async (body) => {
  const { name, newId, screen_name, medium_email, client_email, session_name, session_type } = body;
  let token = localStorage.getItem("access_token");
  let url =
    `get-all-feedbacks?medium_name=${screen_name}&session_id=${newId}&client_name=${name}&medium_email=${medium_email}&client_email=${client_email}&session_name=${session_name}&session_type=${session_type}`

  const response = await API.get(
    url,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const filterAllTransactions = async (body) => {
  const { name, newId, screen_name, payment_method, payment_status, session_name } = body;
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `get-all-transactions?medium_name=${screen_name}&session_name=${session_name}&client_name=${name}&payment_method=${payment_method}&payment_status=${payment_status}&session_id=${newId}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const filterAllProfessionals = async (body) => {
  const { email = "", screen_name = "", created_at = "", orderBy = "", field = "", value = "", oprater = "" } = body;
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `admin/get-professional-mediums?email=${email}&screen_name=${screen_name}&created_at=${created_at}&orderBy=${orderBy}&field=${field}&value=${value}&oprater=${oprater}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const filterAllIntermediate = async (body) => {
  const { email = "", screen_name = "", created_at = "", orderBy = "", field = "", value = "", oprater = "" } = body;
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `admin/get-intermediate-mediums?email=${email}&screen_name=${screen_name}&created_at=${created_at}&orderBy=${orderBy}&field=${field}&value=${value}&oprater=${oprater}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const filterAllPendingApprovals = async (body) => {
  const { email = "", screen_name = "", created_at = "", orderBy = "", field = "", value = "", oprater = "" } = body;
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `admin/pending-approvals?email=${email}&screen_name=${screen_name}&created_at=${created_at}&orderBy=${orderBy}&field=${field}&value=${value}&oprater=${oprater}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const filterAllUnverifiedEmails = async (body) => {
  const { email = "", screen_name = "", created_at = "", orderBy = "", field = "", value = "", oprater = "" } = body;
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `unverified-users?usertype=2&email=${email}&screen_name=${screen_name}&created_at=${created_at}&orderBy=${orderBy}&field=${field}&value=${value}&oprater=${oprater}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const filterAllNovice = async (body) => {
  const { email = "", screen_name = "", created_at = "", orderBy = "", field = "", value = "", oprater = "" } = body;
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `admin/get-novice-mediums?email=${email}&screen_name=${screen_name}&created_at=${created_at}&orderBy=${orderBy}&field=${field}&value=${value}&oprater=${oprater}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getProfileBasicInfo = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`profile-info/${id}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getLegalAndServicesForClient = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-client-legal-privacy?user_id=${id}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getProfileLeagalAndPrivacy = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-medium-legal-privacy?user_id=${id}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getProfilePreferences = async (id) => {
  let token = localStorage.getItem("access_token");
  const response = await API.get(`my-preference-data/${id}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getProfileTechnology = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`my-tech-data/${id}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const performBulkActions = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `admin/perform-bulk-actions`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const resendActivationMail = async (id, name) => {
  console.log('activeClient', name)
  const status = name === "unverifiedEmails" ? "unverified" : ""

  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `admin/resend-activation-mail?user_id=${id}&status=${status}`,
    "",
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const resendActivationUnverifiedMail = async (id, status) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `admin/resend-activation-mail?user_id=${id}&status=${status}`,
    "",
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getFeedbacks = async (body) => {
  console.log(body)
  let token = localStorage.getItem("access_token");

  let name = body !== undefined ? body.name : ''
  let newId = body !== undefined ? body.newId : ''
  let screen_name = body !== undefined ? body.screen_name : ''
  let medium_email = body !== undefined ? body.medium_email : ''
  let client_email = body !== undefined ? body.client_email : ''
  let session_name = body !== undefined ? body.session_name : ''
  let page = body !== undefined ? body.page : 1;
  let rowsPerPage = body !== undefined ? body.rowsPerPage : 10;

  const response = await API.get(`get-all-feedbacks?page=${page}&rowsPerPage=${rowsPerPage}&medium_name=${screen_name}&session_id=${newId}&client_name=${name}&medium_email=${medium_email}&client_email=${client_email}&session_name=${session_name}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getPracticeFeedbacks = async (body, type, subsession_id) => {
  console.log(body)
  let token = localStorage.getItem("access_token");

  let name = body !== undefined ? body.name : ''
  let newId = body !== undefined ? body.newId : ''
  let screen_name = body !== undefined ? body.screen_name : ''
  let medium_email = body !== undefined ? body.medium_email : ''
  let client_email = body !== undefined ? body.client_email : ''
  let session_name = body !== undefined ? body.session_name : ''
  let page = body !== undefined ? body.page : 1;
  let rowsPerPage = body !== undefined ? body.rowsPerPage : 10;
  let url = `get-all-feedbacks?page=${page}&rowsPerPage=${rowsPerPage}&medium_name=${screen_name}&session_id=${newId}&client_name=${name}&medium_email=${medium_email}&client_email=${client_email}&session_name=${session_name}&session_type=${type}`;
  let subsessionIdGroupCoaching = parseInt(process.env.REACT_APP_COACHING_GROUP_SESSION_ID);
  // Add subsession_id to the URL only if it's not undefined and equals 90
  if (subsession_id !== undefined && subsession_id === subsessionIdGroupCoaching) {
    url += `&subsession_id=${subsession_id}`;
  }
  const response = await API.get(url, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getAllRequests = async (body) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`admin/get-all-requests`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const approveRequestById = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `admin/approve-feedback-by-id`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const approveRequest = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(`admin/approve-request`, body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const setSessionLimit = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `admin/set-site-settings`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const setRescheduleLimit = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `admin/set-reschedule-settings`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const setBanner = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(`admin/save-banner-page`, body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const setOffDemandAll = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `admin/set-off-demand-all`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getLimit = async (key) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-site-setting?key=${key}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getIsReschedule = async (key) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`/admin/get-is_reschedule-sessions`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getBannerPage = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`/admin/get-banner-page`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const changeProfessionalStatus = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "admin/set-medium-status",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const saveSession = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("save-session-data", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const saveSubSession = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("save-sub-session-data", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getSessionData = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-sessions-data`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

// export const getSessionType = async () => {
//   let token = localStorage.getItem("access_token");

//   const response = await API.get(`get-session-type`, config(token))
//     .then((data) => {
//       return data;
//     })
//     .catch((err) => {
//       return err;
//     });
//   return response;
// };
export const getSessionType = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-all-subsession-data`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getSubSessionDetails = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-subsession-by-id/${id}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const assignSessionBylevel = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "assign-session-by-level",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const setSpecificMediumAvailability = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "set-specific-medium-availability",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const setAdvancedMediumAvailability = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "set-advanced-medium-availability",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getMettingAvailability = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `get-medium-availability?medium_id=${id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getSingleMediumAvailability = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `get-single-medium-availability/${id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const deleteSingleMediumAvailability = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `delete-single-medium-availability/${id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const deleteSubSessionData = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "delete-sub-session-data",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

// get-session-mediumship-records

export const getSessionMediumshipRecords = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `get-session-mediumship-records`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const syncGoogleCalendar = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`google/all-calendar-list`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getSurveyLinks = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`manual-survey-list`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const sendSurveyEmail = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "admin-send-manual-survey",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const resendSurveyEmail = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "admin-resend-manual-survey",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const testApi = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `push-assign-booking-google-calendars`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getApprovedFeedback = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-approved-feedback`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const importFeedbacks = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("admin/import-feedback", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const deleteUserFeedbacks = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "admin/delete-feedback-by-id",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const deleteAllUserFeedbacks = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "admin/delete-all-feedbacks",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const deleteFeedbackById = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("delete-feedback", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const updateFeedbackByAdmin = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("update-feedback", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const regenerateZoomLink = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "admin/zoom-link-generate",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const confirmAppointmentstatus = async (id) => {
  let token = localStorage.getItem("access_token");
  const response = await API.get(
    `confirm-appointment-api/${id}`,
    "",
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const createAppointmentAdmin = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `admin/save-direct-appointment`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getCreateAppointmentSubseesions = async (id) => {
  let token = localStorage.getItem("access_token");
  const response = await API.get(`admin/get-subsessions/${id}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getClientMediumTimeZone = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `admin/get-client-medium-timezone`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getAppointmentsDetails = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `appointment-slots-by-id`,
    // `date-time-as-per-client-medium`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const reschedueAppointment = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `reschedule-appointment-id`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const addReferralCode = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(`admin/add-referal-code`, body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getReferralCodes = async () => {
  let token = localStorage.getItem("access_token");
  const response = await API.get(`admin/get-referal-codes`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const sendRequestAdmin = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `admin/send-payment-request`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const settlePaymentOutside = async (id) => {
  let token = localStorage.getItem("access_token");
  const response = await API.get(`settle-appointment-transaction/${id}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
}
export const markPaymendAsCompleted = async (id) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `admin/update-transaction/${id}`,
    "",
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getsnglefeedback = async (id) => {
  let token = localStorage.getItem("access_token");
  const response = await API.get(`feedback-view/${id}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
}

export const emailReverification = async (newtoken) => {
  let body = {
    token: newtoken
  }
  let token = localStorage.getItem("access_token");
  const response = await API.get(`email-reverification`, { params: body }, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
}

export const setBannerMedium = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(`admin/medium/update-banner-text`, body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const setBannerStarterClients = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(`admin/starter-client/update-banner-text`, body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const setBannerOutreachClients = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(`admin/outreach-client/update-banner-text`, body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const updateBannerStaus = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(`update-banner-status`, body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const changeStatusAppointmentFromAdmin = async (body, acc_Type) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(acc_Type == "medium" ? `medium-appointment-status-change` : `admin/appointment-change-status`, body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const cancelGroupAppointments = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `admin/group-sessions/cancellation`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const noshowGroupAppointments = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `admin/group-sessions/no-show`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const noshowRemoveGroupAppointments = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `admin/group-sessions/remove`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const setondemandEvent = async (body, schedule_id) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(schedule_id == "" ?
    "save-on-demand-session" : `edit-on-demand-session/${schedule_id}`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getOndemandCategories = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-on-demand-session-categories?session_ids=${process.env.REACT_APP_GROUP_EVENT_IDS}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getAllEventDetails = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-on-demand-sessions`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const deleteSingleEventAvailability = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `remove-ondemand-session/${id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getEventDetailsById = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-on-demand-session-by-id/${id}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getAllOnDemandEvents = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-ondemand-queue`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
}
export const onDemandHistory = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-on-demand-sessions-history`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
}
export const deleteonDemandHistory = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `delete-on-demand-history/${id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const deleteAllOnDemandHistory = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `delete-all-on-demand-history`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const exportAppointments = async (body) => {
  const {
    appointment_type = "",
    session_type = "",
    session_id = "",
    screen_name = "",
    orderBy = "",
    order = "",
    name = "",
    medium_email = "",
    client_email = "",
    meeting_id = "",
    created_at = "",
    appoitment_value = "",
  } = body;

  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `/export-appoitment?appointment_type=${appointment_type}&session_type=${session_type}&session_id=${session_id}&medium_name=${screen_name}&orderBy=${orderBy}&name=${name}&medium_email=${medium_email}&client_email=${client_email}&meeting_id=${meeting_id}&order=${order}&created_at=${created_at}&appoitment_value=${appoitment_value}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const exportDownloadAppointments = async (body) => {
  const {
    appoitment_type = "",
  } = body;

  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `/export-appoitment-link?appoitment_type=${appoitment_type}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const voluntreeClientChangeStaus = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `voluntree-client-status-change`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const enableScvStatus = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`admin/client/starter-volunteer-status-update/${id}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};