export const list = [
  { id: 0, name: "Outreach Clients", count: 0 },
  { id: 1,name: "Starter Clients", count: 0 },
  { id: 2,name: "Restricted Clients", count: 0 },
  { id: 3,name: "Potential Clients", count: 0 },
  { id: 4,name: "Incomplete Registration", count: 0 },
];

export const filterList = [
  {
    id: 1,
    name: "Client ID",
    value: "id",
  },
  {
    id: 2,
    name: "Email",
    value: "email",
  },
  {
    id: 3,
    name: "Screen Name",
    value: "screen_name",
  },
  {
    id: 4,
    name: "Legal Name",
    value:"legal_name"
  },
  {
    id: 5,
    name: "Register Date",
    value: "created_at",
  },
  {
    id: 6,
    name: "Referral Code",
    value:"referralCode"
  },
  {
    id: 7,
    name: "Phone",
    value:"phone"
  },
  {
    id: 8,
    name: "Whatsapp",
    value:"whatsapp"
  },
  {
    id: 9,
    name: "Country",
    value:"country"
  },
  {
    id: 10,
    name: "Legal Terms and Conditions",
    value:"agree_to_legal_terms_and_conditionss"
  },
  {
    id: 11,
    name: "Starter Client Guidelines",
    value:"agree_to_starter_client_guidelines"
  },
  {
    id: 13,
    name: "Opt In Out",
    value:"opt_in_out"
  },
  {
    "id": 14,
    "name": "Timezone",
    "value": "timezone"
  },
  {
    "id": 15,
    "name": "Test Mode",
    "value": "test_mode"
  },
  {
    "id": 16,
    "name": "Test User",
    "value": "test_user"
  },
  {
    "id": 17,
    "name": "Is Zoom Verified",
    "value": "zoom_verification"
  },
  ,
  {
    "id": 18,
    "name": "Language",
    "value": "language"
  }
];
