import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

//utils
import { shortcuts } from "./utils";

//components
import Card from "./subcomponents/card/card";

//actions
import { setBlinkRequest } from '../../../reducer/clientsSlice';

export default function ShortCuts() {



    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        let redirect_to = localStorage.getItem("redirect_to")
        if (redirect_to === "starter-booking") {
            navigate('/appointment')
        }

    }, [])

    const onClickCardHandler = (item) => {
        if (item.id === 1) {
            findMeetingLink(item.url);
        } else if (item.id === 2) {
            cancelBooking(item.url);
        } else if (item.id === 3) {
            giveFeedBack(item.url);
        } else if (item.id === 4) {
            payMedium(item.url);
        } else if (item.id === 5) {
            bookSession(item.url);
        }
    };

    const findMeetingLink = (url) => {
        dispatch(setBlinkRequest('meetinglink'))
        navigate(url);
    };

    const cancelBooking = (url) => {
        dispatch(setBlinkRequest('cancelbooking'))
        navigate(url);
    };
    const giveFeedBack = (url) => {
        dispatch(setBlinkRequest('givefeedback'))
        navigate(url);
    };
    const payMedium = (url) => {
        dispatch(setBlinkRequest('paymedium'))
        navigate(url);
    };
    const bookSession = (url) => {
        navigate(url);
    };
    return (
        <>
            <div className="dashboard">
                <div className="row">
                    <div className="col-md-12">
                        <div className="quck-box">
                            <h2>Quick Shortcuts</h2>

                            <div className="quick-status-two client-dashboard-card-wrapper">
                                {shortcuts.map((item) => (
                                    <>
                                        <Card
                                            type={item.type}
                                            src={item.src}
                                            alt={item.alt}
                                            title={item.title}
                                            onclick={() => onClickCardHandler(item)}
                                            url={item.url}
                                        />
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
}
