import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getMediumRole } from "../../../../utils/utils";
import Filter from "../../../../components/filter";
import { useNavigate, useSearchParams } from "react-router-dom";
import { filterList, bulkListApproval, filterListpendin } from "../data";
import Checkbox from "../../Subcomponents/checkbox";
import Button from "../../../../components/button";
import "../mediums.css"


import { fireFilter } from "../functions";
import CsvDownload from "../../../../components/csvdownload";
import CopyComponent from "../../../../components/copyComponent";
import Pagination from "../../../../components/tablePagination";
import Search from "../../../../components/search";
import Sorting from "../../../../components/sorting";
import ToggleTestUser from "../../Subcomponents/toogletestuser";
import { setPathArray, setPendingApprovalsPage, setPendingApprovalsrowsPerPage } from "../../../../reducer/adminSlice";


const headers = [
  { label: "Screen Name", key: "screen_name" },
  { label: "Email", key: "email" },
  { label: "Created", key: "created_at" },
  { label: "osen level", key: "created_at" },
  { label: "Created", key: "created_at" },
  { label: "Approved Role", key: "approved_role" },

];

export default function PendingApprovals(props) {
  const { pendingApprovals, updateUser, onClickHandler, filterListMediums, bulkActions, resendEmail, getPaginationData, pendingapprovalsPage,
    pendingapprovalsrowsPerPage, setFilterValues, filterReset,
    setSearchValues,
    searchValue,
    filterValues, setOptionsList,
    optionsList,
    operatorList,
    setOperatorList,
    showOprater,
    setShowOprater,
    setFilterOprater,
    filterOprater, setFilterValueOptions, filterValueOptions, } = props;

  const dispatch = useDispatch()
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const rowperpage = searchParams.get('rowperpage');

  useEffect(() => {
    if (page !== null) {

      dispatch(setPendingApprovalsPage(page));
    }
    if (rowperpage !== null) {
      dispatch(setPendingApprovalsrowsPerPage(rowperpage));
    }
  }, [])
  let info = JSON.parse(localStorage.getItem('pagination'))
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [selected, setSelected] = useState([]);

  const {
    allPendingApprovalsCount
  } = useSelector((state) => state.users);

  const navigateUser = (id) => {
    window.renderFirst = false;
    dispatch(setPathArray(id.id));
    navigate(`/profile-view/${id.id}?view=4&search=${filterValues}&searchName=${Object.values(searchValue)}&page=${info.page}&rowperpage=${info.rowsPerPage}&searchOprator=${showOprater}&searchFilterOprater=${filterOprater}`);
  };
  const navigateEdit = (e, id) => {
    window.renderFirst = false
    if (e.target.className == "capitalize") {
      navigate(`/profile-edit/${id.id}?view=4&search=${filterValues}&searchName=${Object.values(searchValue)}&page=${info.page}&rowperpage=${info.rowsPerPage}&searchOprator=${showOprater}&searchFilterOprater=${filterOprater}`);
    } else {
    }
  };
  const checkBoxChangeHandler = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let result = pendingApprovals.map((a) => a.id);
      setSelected(result);
    } else {
      setSelected([]);
    }
  };

  // const getPaginationData = (item) => {
  //   console.log('paginationdata',item.from);
  //   getFromToData(item);
  //  };


  const changeHandler = (e) => {
    if (e.target.value === "Decline") {
      bulkActions(2, selected);
    }
    if (e.target.value === "Delete") {
      bulkActions(3, selected);
    }
    if (e.target.value === "Approve") {
      bulkActions(1, selected);
    }
  };
  return (
    <>
      <div className="d-flex justify-content-end gap-10 flex-wrap bottomClass">
        <Search callApi={filterListMediums} list={filterListpendin} searchValue={searchValue} setSearchValues={setSearchValues} setFilterValues={setFilterValues}
          filterValues={filterValues} isDatepickerShow={1} setOptionsList={setOptionsList}
          optionsList={optionsList}
          operatorList={operatorList}
          setOperatorList={setOperatorList}
          showOprater={showOprater}
          setShowOprater={setShowOprater}
          setFilterOprater={setFilterOprater}
          filterOprater={filterOprater} setFilterValueOptions={setFilterValueOptions} filterValueOptions={filterValueOptions} isOperator={1} />
        {/* <Button
          status={0}
          type="button"
          onClick={filterReset}
          className={`resend-btn  width-80px  reset-btn-123`}
          title="Reset"
        /> */}
        {/* <Filter
          name={"Bulk Actions"}
          list={bulkListApproval}
          changeHandler={changeHandler}
        /> */}
      </div>
      <CsvDownload
        href={`${process.env.REACT_APP_API_URL_EXPORT}` + 'export-medium-list?user_type=pending'}
      //  usertype={"pending"}
      //  data={fireFilter(pendingApprovals)} headers={headers} filename={'pendingApprovals'}
      />
      <div className="table-box-medium table-container" >
        <table className="caseTable">
          <tbody>
            <tr className="dark">
              <th>
                <input
                  onChange={(e) => checkBoxChangeHandler(e)}
                  type="checkbox"
                  id="one"
                />
                <label htmlFor="one"></label>
                &nbsp;
              </th>
              <th>
                <div className="d-flex align-items-center">
                  {" "}
                  <span className="scren-name">Screen Name</span>{" "}
                  <Sorting callApi={filterListMediums} />
                </div></th>
              <th>Email</th>
              <th>Test User</th>
              <th>Starter Referral Code</th>
              <th>Total Starter Referrals</th>
              <th>Created</th>
              <th>Approved Role</th>
              <th>Chosen Level</th>
              <th> Preferred Sessions</th>
              <th>Action</th>
            </tr>
            {pendingApprovals.length > 0 ? (
              pendingApprovals.map((user) => (
                <tr onClick={(e) => navigateEdit(e, user)} className="innr pointer">
                  <td >
                    <div className="d-flex-medium-tables">


                      <Checkbox
                        medium={user}
                        status={checked}
                        selected={selected}
                        setSelected={setSelected}
                      />
                      <img src="/images/undraw_profile.svg" className="profile-image-avatar" alt="avatar" />
                    </div>
                  </td>
                  <td className="capitalize">
                    {" "}
                    {user.screen_name ? user.screen_name : "N/A"}{(user.comments != null && user.comments !== "") && (<b style={{
                      fontSize: '22px',
                    }}>*</b>)}
                    {/* {user.first_name} &nbsp; {user.last_name} */}
                  </td>
                  <td>{user.email}<CopyComponent value={user.email} /></td>
                  <td><ToggleTestUser
                    clientId={user.id}
                    initialStatus={user.test_user === 1} // Set initial toggle state
                    onStatusChange={(id, newStatus) => {
                      console.log(`Client ${id} status changed to ${newStatus ? 'on' : 'off'}`);
                      // Additional handling if necessary
                    }} /></td>
                  <td>{user.refer_code ? user.refer_code : "-"}</td>
                  <td>{user.number_of_uses}</td>
                  <td>
                    {user.created_at}
                    {/* {moment(user.created_at).format("YYYY-MM-DD HH:mm:ss")} */}
                  </td>
                  <td>{getMediumRole(user.current_mediumship_level)}</td>
                  <td>{getMediumRole(user.current_mediumship_level_medium)}</td>
                  <td>{JSON.parse(user.user_preferences.client_session_preferences).join(', ')}</td>
                  {/* <td>Medium</td> */}
                  {/* <td>
                    <Button
                      status={user.resent_status}
                      onClick={(e) => resendEmail(e, user.id)}
                      type="button"
                      className="resend-btn"
                      title="Resend"
                    />
                  </td> */}
                  <td className="dot-image dott">
                    <img
                      className="padding-10"
                      onClick={(e) =>
                        onClickHandler(e, user.id, "pendingAprrovals")
                      }
                      src="../images/Unionblck.png"
                      alt="dots"
                    />
                    {user.status && (
                      <div className="approve-btn-1 approve-btn z-index-1">
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            updateUser(
                              user,
                              1,
                              "pendingApprovals",
                              "pendingApprovals"
                            );
                          }}
                        >
                          Approve
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            updateUser(user, 2, "pendingApprovals", "");
                          }}
                        >
                          Decline
                        </span>

                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            updateUser(user, 3, "delete", "pendingApprovals");
                          }}
                        >
                          Delete
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            navigateUser(user);
                          }}
                        >
                          View
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Pending Approvals
                </td>
              </tr>
            )}
            {/* <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr>
          <tr className="innr">
            <td>
              <input type="checkbox" id="two" />
              <label htmlFor="two"></label>
              <img src="../images/davilimg.png" />
              <span>Devid Smith</span>
            </td>
            <td>mariagoncalves.school@gmail.com</td>
            <td className="change">
              <em>Novice</em>
              <a href="#">Change</a>
            </td>
            <td>Practice - 60 Min (30 Min each)</td>
            <td className="dot-image">
              <img src="../images/Unionblck.png" />
            </td>
          </tr> */}
          </tbody>
        </table>
        {/* <div className="pagination-box">
        <div className="left-pagi">
          <span>Showing 1 to 10 of 18 entries</span>
        </div>
        <div className="right-pagi">
          <ul>
            <li>Previous</li>
            <li className="active">
              <a href="#">1</a>
            </li>
            <li>
              <a href="#">2</a>
            </li>
            <li>
              <a href="#">3</a>
            </li>
            <li>Next</li>
          </ul>
        </div>
      </div> */}
        <Pagination rowsPerPageOptions={[5, 10, 25]} count={allPendingApprovalsCount} activeTab={"pendingAprrovals"} pendingapprovalsPage={pendingapprovalsPage}
          pendingapprovalsrowsPerPage={pendingapprovalsrowsPerPage} getPaginationData={getPaginationData} />
      </div>
    </>
  );
}
