import React, { useEffect } from "react";
import { changeOnlineStatusFunc } from "../../commonfunctions";
import { logout } from "../../services/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoading, setUpAuthTrue } from "../../reducer/auth/auth";
import { GridLoader } from "react-spinners";

export default function Redirect() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setLoading(true));
    let token = localStorage.getItem("access_token");
    if (token) {
      logoutUser("/login?sendBack=start");
    } else {
      dispatch(setLoading(false));
      navigate("/login?sendBack=start");
      // localStorage.setItem("redirect_to", redirect_to)
    }

    // localStorage.setItem('sendBack', "start");
  }, []);

  const moveRoute = (url = "/registration") => {
    dispatch(setLoading(false));
    navigate(url);
    localStorage.clear();
    window.location.reload();
    dispatch(setUpAuthTrue());
  };
  const logoutUser = (url) => {
    console.log(url);
    //   let url = "/thanks";
    changeOnlineStatusFunc(0, dispatch);
    // dispatch(setLoading(true));
    logout()
      .then((data) => {
        // dispatch(setLoading(false));
        // if (data.data.status) {
        console.log(url);
        moveRoute(url);

        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="spinner">
      <GridLoader size={50} />
    </div>
  );
}
