import moment from "moment";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/button";
import Pagination from "../../../../components/tablePagination";
import CopyComponent from "../../../../components/copyComponent";
import CsvDownload from "../../../../components/csvdownload";
import CsvDownloadAll from "../../../../components/csvdownloadAll";
import Search from "../../../../components/search";
import Sorting from "../../../../components/sorting";
// import UserStatus from "../../../../components/userstatus";
import { filterList } from "../data";
import { fireFilter } from "../functions";
import ToggleTestUser from "../../Subcomponents/toogletestuser";
import { setAllUnverifiedEmailsPage, setAllUnverifiedEmailsrowsPerPage, setPathArray } from "../../../../reducer/adminSlice";

const headers = [
  { label: "Client Name", key: "client_name" },
  { label: "Email", key: "email" },
  { label: "Created", key: "created_at" },

];

export default function UnverifiedEmails(props) {
  const navigate = useNavigate();
  const {
    unverifiedEmails,
    onClickHandler,
    updateUser,
    resendEmail,
    filterListClients,
    getPaginationData,
    setFilterValues,
    filterReset,
    setSearchValues,
    searchValue,
    filterValues,
    showOprater,
    setShowOprater,
    operatorList,
    setOperatorList,
    optionsList,
    setOptionsList,
    filterValueOptions,
    setFilterValueOptions,
    setSortingNameOrderBy,
    setSortingTabType,
    allUnverifiedEmailsPage,
    setFilterOprater,
    filterOprater,
    allUnverifiedEmailsrowsPerPage,

  } = props;
  let info = localStorage.getItem('pagination') !== null ? JSON.parse(localStorage.getItem('pagination')) : { page: 1, rowsPerPage: 10 }

  const dispatch = useDispatch()
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const rowperpage = searchParams.get('rowperpage');

  useEffect(() => {
    if (page !== null) {

      dispatch(setAllUnverifiedEmailsPage(page));
    }
    if (rowperpage !== null) {
      dispatch(setAllUnverifiedEmailsrowsPerPage(rowperpage));
    }
  }, [])
  const {
    allUnverifiedEmailsCount
  } = useSelector((state) => state.users);

  const navigateUser = (id) => {
    window.renderFirst = false
    dispatch(setPathArray(id.id));
    navigate(`/view/${id.id}?view=4&search=${filterValues}&searchName=${Object.values(searchValue)}&page=${info.page}&searchFilterOprater=${filterOprater}&rowperpage=${info.rowsPerPage}&searchOprator=${showOprater}`);
  };
  const navigateEdit = (e, id) => {
    window.renderFirst = false
    if (e.target.className == "capitalize") {
      navigate(`/edit/${id.id}?view=4&search=${filterValues}&searchName=${Object.values(searchValue)}&page=${info.page}&searchFilterOprater=${filterOprater}&rowperpage=${info.rowsPerPage}&searchOprator=${showOprater}`);
    } else {

    }
  };
  return (
    <>
      <div className="d-flex justify-content-end gap-10 flex-wrap flex-column-admin-client">
        <Search callApi={filterListClients} list={filterList} searchValue={searchValue} setSearchValues={setSearchValues} setFilterValues={setFilterValues}
          filterValues={filterValues} isDatepickerShow={1} isOperator={1} setFilterOprater={setFilterOprater} showOprater={showOprater}
          setShowOprater={setShowOprater} filterOprater={filterOprater} operatorList={operatorList}
          setOperatorList={setOperatorList} optionsList={optionsList}
          setOptionsList={setOptionsList} filterValueOptions={filterValueOptions}
          setFilterValueOptions={setFilterValueOptions}/>
        {/* <Button
          status={0}
          type="button"
          onClick={filterReset}
          className={`resend-btn  width-80px  reset-btn-123`}
          title="Reset"
        /> */}
      </div>
      <CsvDownloadAll
        href={`${process.env.REACT_APP_API_URL_EXPORT}` + 'export-client-list'}
      //  data={fireFilter(clients)} headers={headers}filename={'outreachclients'}
      />
      <CsvDownload
        href={`${process.env.REACT_APP_API_URL_EXPORT}` + 'export-client-list?client_type=unverified'}
      //  data={fireFilter(clients)} headers={headers}filename={'outreachclients'}
      />
      <div className="table-box-medium">
        <table className="caseTable">
          <tbody>
            <tr className="dark">
              <th>&nbsp;</th>
              <th>Screen Name </th>
              <th > <div className="d-flex align-items-center"> <span>Client Name </span>
                <Sorting callApi={filterListClients} shortedName={"client_name"} tableName={"client"}
                  setSortingNameOrderBy={setSortingNameOrderBy}
                  setSortingTabType={setSortingTabType}
                  tab={5}
                />
              </div></th>

              <th>Email</th>
              <th>Test User</th>
              <th>Referral Code</th>
              <th > <div className="d-flex align-items-center"> <span>Created </span> <Sorting callApi={filterListClients} shortedName={"created_at_order_by"} tableName={"client"}
                setSortingNameOrderBy={setSortingNameOrderBy}
                setSortingTabType={setSortingTabType}
                tab={5}
              /></div></th>
              <th>Activation email</th>
              {/* <th>Online Status</th> */}
              <th>Action</th>
            </tr>
            {unverifiedEmails.length > 0 ? (
              unverifiedEmails.map((client) => (
                <tr
                  onClick={(e) => navigateEdit(e, client)}
                  className="innr pointer"
                >
                  <td>
                    {" "}
                    <img src="/images/undraw_profile.svg" className="profile-image-avatar" alt="avatar" />
                  </td>
                  <td>
                    {/* <input type="checkbox" id="two" />
                    <label htmlFor="two"></label> */}

                    <p className="capitalize">
                      {client.screen_name ? client.screen_name : "N/A"}
                      {client.comments && (<b  style={{
                              fontSize: '22px',
                            }}>*</b>)}
                    </p>
                  </td>
                  <td>
                    {/* <input type="checkbox" id="two" />
                    <label htmlFor="two"></label> */}

                    <p className="capitalize">
                      {client.first_name ? client.first_name+ " " +client.last_name : "N/A"}{}
                    </p>
                  </td>
                  <td>{client.email} <CopyComponent value={client.email} /></td>
                  <td><ToggleTestUser
                    clientId={client.id}
                    initialStatus={client.test_user === 1} // Set initial toggle state
                    onStatusChange={(id, newStatus) => {
                      console.log(`Client ${id} status changed to ${newStatus ? 'on' : 'off'}`);
                      // Additional handling if necessary
                    }}
                  /></td>
                  <td>{client.referralCode}</td>
                  <td>
                    {client.created_at}
                  </td>

                  {<td>
                    <Button
                      status={client.resent_status}
                      onClick={(e) => resendEmail(e, client.id, "unverifiedEmails")}
                      type="button"
                      className="resend-btn"
                      title="Resend"
                    />
                  </td>}
                  {/* <td>
                    <UserStatus status={client.is_available} />
                  </td> */}
                  <td className="dott">
                    <img
                      onClick={(e) => onClickHandler(e, client.id, "unverifiedemails")}
                      src="/images/Unionblck.png"
                      className="padding-10"
                      alt="unblock"
                    />

                    {client.client_status && (
                      <div className="approve-btn-1 approve-btn z-index-2">

                        {/* <span
                          onClick={(e) => {
                            e.stopPropagation();
                            updateUser(
                              client,
                              parseInt(client.status) === 4 ? 1 : 4,
                              "freeclient",
                              ""
                            );
                          }}
                        >
                          {parseInt(client.status) === 4
                            ? "UnRestrict"
                            : "Restrict"}
                        </span> */}
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            updateUser(
                              client,
                              5,
                              "unverifiedemails",
                              "unverifiedemails"
                            );
                          }}
                        >
                          Approve
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            updateUser(client, 3, "delete", "");
                          }}
                        >
                          Delete
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            navigateUser(client);
                          }}
                        >
                          View
                        </span>
                      </div>
                    )}
                  </td>
                  {/* <td>
                    <Link to={`/view/${client.id}`}>
                      <img src="../images/blue-e.png" alt="view profile" />
                      View Profile
                    </Link>
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  No Unverified Emails Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination rowsPerPageOptions={[5, 10, 25]}
          count={allUnverifiedEmailsCount}
          activeTab="unverifiedemails"
          getPaginationData={getPaginationData}
          allUnverifiedEmailsPage={allUnverifiedEmailsPage}
          allUnverifiedEmailsrowsPerPage={allUnverifiedEmailsrowsPerPage}
        />
      </div>
    </>
  );
}
