import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Formik, Field, ErrorMessage } from "formik";
import { updateInitialProfile } from "../../../../../services/profile";
import { setLoading } from "../../../../../reducer/auth/auth";
import { useDispatch } from "react-redux";
import { TagsInput } from "react-tag-input-component";
import { toast } from "react-toastify";
import PhoneInputField from "../../../../../components/Inputfields/phoneinput";

import ProfilePicture from "../subcomponent/profilepicture";
import { updateUserEmail } from "../../../../../services/auth";
import Button from "../../../../../components/button";
import { filterVisibleTimeZones } from "../../../../../utils/helpers";
import ToggleTestMode from "../../../Subcomponents/toogletestmode";
import CopyComponent from "../../../../../components/copyComponent"
import { values } from "lodash";

export default function Profile(props) {
  const dispatch = useDispatch();
  const {
    formLanguages,
    specialtyData,
    countryData,
    stepOneData,
    getProfile,
    timezoneData,
    param,
    stepTwoData,
    reloadTimeZones
  } = props;

  const [approvedPrefrences, setApprovedPrefrences] = useState([
    { id: 1, name: "Other Mediums", status: false },
    { id: 2, name: "Starter Clients", status: false },
    { id: 3, name: "Outreach Clients", status: false },
    { id: 4, name: "Paying Clients", status: false },
    { id: 5, name: "Coaching", status: false },
    { id: 6, name: "Hosting Practice Circles", status: false },
    { id: 7, name: "Hosting Q&As", status: false },
    { id: 8, name: "Group Session", status: false },

  ]);
  const [additional_settings_data, setAdditionalSettings] = useState(false);
  const [support_settings_data, setSupportSettings] = useState(true);
  const [show_support_settings, setshowSupportSettings] = useState(false);
  const [showAddSettings, setShowAddSettings] = useState(false);
  // const [newExtraPricing, setNewExtraPricing] = useState([]);
  const [newformData, setNewFormData] = useState([]);
  const [newSpecilityData, setNewSpecilityData] = useState([]);
  const [newCountryData, setNewCountryData] = useState([]);
  const [newTimezoneData, setnewTimezoneData] = useState([]);
  const [openOtherLnaguages, setOpenOtherLnaguages] = useState(false);
  const [openOtherSpecility, setOpenOtherSpecility] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [emailError, setEmailError] = useState("");
  // const [userCountryCode, setUserCountryCode] = useState("US");

  const [showPrePay, setShowPrePay] = useState(false);
  const [showPostPay, setShowPostPay] = useState(false);

  useEffect(() => {
    setAdditionalSettings(stepOneData.extra_services);
    if (parseInt(stepOneData.mediumship_level) === 8) {
      setshowSupportSettings(true)
    } else {
      setshowSupportSettings(false)
    }
    // setNewExtraPricing(stepOneData.extra_services_list);

    setNewFormData(
      formLanguages.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.name,
        };
      })
    );
    setNewSpecilityData(
      specialtyData.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.specialty_name,
        };
      })
    );
    setNewCountryData(
      countryData.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.country_name,
        };
      })
    );
    setnewTimezoneData(filterVisibleTimeZones(timezoneData));
    // addDefault();
  }, ["", formLanguages, specialtyData, countryData, timezoneData]);

  useEffect(() => {
    // console.log(stepTwoData.session_preferences,'workong,"workinh')
    let array = [5, 3, 6, 7]
    setTimeout(() => {

      let info = approvedPrefrences.map((value) => {
        return {
          ...value,
          status: stepTwoData.session_preferences.includes(value.name),
          show: value.id === 5 || value.id === 6 || value.id === 7 ? array.includes(parseInt(stepOneData.mediumship_level)) : true
        };
      });
      stepTwoData.session_preferences && stepTwoData.session_preferences[0] == "Support" ? setshowSupportSettings(true) : setshowSupportSettings(false)
      //show_support_settings
      setApprovedPrefrences(info);
    }, 2000);

  }, [stepTwoData.session_preferences.length > 0]);

  useEffect(() => {
    let { mediumship_level } = stepOneData;
    if (parseInt(mediumship_level) === 7) {
      setShowPostPay(true);
      setShowPrePay(true);
      setShowAddSettings(true);
    }

    if (parseInt(mediumship_level) === 3 || parseInt(mediumship_level) === 6) {
      if (parseInt(mediumship_level) === 3) {
        setShowPostPay(true);
        setShowPrePay(false);
        setShowAddSettings(true);
      }

      if (parseInt(mediumship_level) === 6) {
        setShowPostPay(false);
        setShowPrePay(true);
        setShowAddSettings(true);
      }
    }
  }, [stepOneData]);
  const RunFunction = (formData) => {
    dispatch(setLoading(true));
    // setValues(formData);
    const {
      phone,
      whatsapp,
      languages,
      mediumshipLevel,
      mediumship_level,
      mediumshipSpecialities,
      country,
      website,
      biographicalInfo,
      otherLanguages,
      otherMediumSpecialities,
      timezone,
      additional_settings,
      when_do_i_pay,
      session_length_30,
      session_length_30_currency,
      session_length_45,
      session_length_45_currency,
      session_length_60,
      session_length_60_currency,
      session_length_60_2_currency,
      session_length_45_2_currency,
      session_length_60_2,
      session_length_45_2,
      medium_professional_category,
      recommendation,
      session_currency,
      test_mode,
      extrapricing_81,
      extrapricing_80,
      extrapricing_82,
      coaching_pricing_30,
      coaching_pricing_60,
      hosting_circle_pricing_30,
      hosting_qa_pricing_30,
      comments,
      payitforward_pricing_30,
      payitforward_pricing_45,
      payitforward_pricing_60,
      payitforward_pricing_45_2,
      payitforward_pricing_60_2,
      allow_free_coaching_session,
    } = formData;
    let lang = languages.map((item) => {
      return item.id;
    });
    let speci = mediumshipSpecialities.map((item) => {
      return item.id;
    });
    // console.log('newextra',newExtraPricing)

    // let count = country.map((item)=> {return item.id})


    let bodyData;
    if (!show_support_settings) {
      bodyData = approvedPrefrences
        .filter((item) => {
          if (item.status) {
            return item.name;
          }
        })
        .map((value) => {
          return value.name;
        });
    } else {
      bodyData = ["Support"];
    }

    // mediumship_level=bodyData[3].status?"2":mediumship_level;
    let extra_services_data = stepOneData.extra_services_list.reduce((r, { id, amount }) => (r[id] = amount, r), {})

    console.log(coaching_pricing_30, "cdncj")

    console.log('extra_services_data', extra_services_data)
    let item = {
      // first_name: firstName,
      // last_name: lastName,
      session_preferences: bodyData,
      phone: phone,
      whatsapp: whatsapp,
      website: website,
      country: country.label !== undefined ? country.label : country,
      languages: lang,
      mediumship_specialties: speci,
      current_mediumship_level: parseInt(mediumshipLevel), // user selected
      current_mediumship_level_admin: mediumship_level, // approved admin
      biographical_info: biographicalInfo,
      other_languages: otherLanguages,
      other_mediumship_specialties: otherMediumSpecialities,
      timezone: timezone.id,
      userId: param.id,
      when_do_i_pay: when_do_i_pay,
      medium_professional_category: medium_professional_category,
      recommendation: recommendation,
      test_mode: test_mode,
      session_length: JSON.stringify({
        30: session_length_30,
        45: session_length_45,
        60: session_length_60,
        452: session_length_45_2,
        602: session_length_60_2,
      }),
      extra_services: additional_settings_data,
      extra_services_data: {
        80: extrapricing_80 !== undefined ? extrapricing_80 : extra_services_data[80],
        81: extrapricing_81 !== undefined ? extrapricing_81 : extra_services_data[81],
        82: extrapricing_82 !== undefined ? extrapricing_82 : extra_services_data[82]
      },
      // session_currency: JSON.stringify({
      //   30: session_length_30_currency,
      //   45: session_length_45_currency,
      //   60: session_length_60_currency,
      //   452: session_length_45_2_currency,
      //   602: session_length_60_2_currency,
      // }),
      session_currency: JSON.stringify({
        30: session_currency,
        45: session_currency,
        60: session_currency,
        452: session_currency,
        602: session_currency,
      }),

      coaching_pricing: (coaching_pricing_30 === "" || coaching_pricing_30 === null || parseInt(coaching_pricing_30) === 0) && 
            (coaching_pricing_60 === "" || coaching_pricing_60 === null || parseInt(coaching_pricing_60) === 0) ? 
            null : JSON.stringify({
              30: coaching_pricing_30,
              60: coaching_pricing_60
            }),
      hosting_circle_pricing: hosting_circle_pricing_30 === "" || hosting_circle_pricing_30 === null || parseInt(hosting_circle_pricing_30) === 0 ? null : JSON.stringify({
        30: hosting_circle_pricing_30
      }),
      hosting_qa_pricing: hosting_qa_pricing_30 === "" || hosting_qa_pricing_30 === null || parseInt(hosting_qa_pricing_30) === 0 ? null : JSON.stringify({
        30: hosting_qa_pricing_30
      }),
      comments: comments,
      allow_free_coaching_session: allow_free_coaching_session,
      payitforward_pricing: JSON.stringify({
        30: payitforward_pricing_30,
        45: payitforward_pricing_45,
        60: payitforward_pricing_60,
        452: payitforward_pricing_45_2,
        602: payitforward_pricing_60_2,
      }),
    };
    updateInitialProfile(item)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          toast.success("Profile Updated", { position: "top-center" });
          getProfile(param.id);
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };
  const countryChangeHandler = (value, name, setFieldValue) => {
    // setUserCountryCode(value.country_code);
    setFieldValue(name, value);
  };

  const additionalSettingsHandler = (e, setFieldValue, values) => {
    let value = e.target.checked;
    setAdditionalSettings(value);
    setFieldValue("additional_settings", value);
  };
  const supportSettingsHandler = (e, setFieldValue, values) => {
    let value = e.target.checked;
    // setSupportSettings(value);
    setFieldValue("support_settings", value);
  };

  const sessionChangeHandler = (e, item, values) => {
    // setUserCountryCode(value.country_code);
    if (
      parseInt(item.id) === 4 &&
      item.status &&
      item.name === "Paying Clients"
    ) {
      values.mediumship_level = 3;
    }
    setApprovedPrefrences(
      approvedPrefrences.map((value) => {
        if (parseInt(value.id) === parseInt(item.id)) {
          return {
            ...value,
            ...(value.status = e.target.checked),
          };
        } else {
          return {
            ...value,
          };
        }
      })
    );

    //   let bodyData = approvedPrefrences
    //     .filter((item) => {
    //       if (item.status) {
    //         return item.name;
    //       }
    //     })
    //     .map((value) => {
    //       return value.name;
    //     });
    //   let body = {
    //     session_preferences: bodyData
    //   };
    //  console.log('body....',body)
  };
  const onChangeEmailHandler = (e) => {
    if (emailValue !== "" && emailError === "") {

      // setEmailDisabled(false);
      // setEmailTitle("Submit");
      // if (emailTitle === "Submit" && emailError === "" && emailValue !== "") {
      let body = {
        user_id: param.id,
        email: emailValue,
      };
      updateUserEmail(body)
        .then((data) => {
          if (data.data.status) {
            toast.success("Email updated", { position: "top-center" });
          }
        })
        .catch(() => { });
    }
    else {
      setEmailError("*Required");
      return;
    }
  };

  const handleServicesChange = (e, item, setFieldValue) => {
    let value = e.target.value;
    // let array = [];
    // array.push({id:item.id,amount:value,name: item.name});
    // console.log("array",array)
    // setNewExtraPricing(array);
    // newExtraPricing.map((data)=>{
    //  if(item.id===data.id){
    //   data.amount=value;
    //  }
    // });
    // setFieldValue("when_do_i_pay", array);
    setFieldValue(`extrapricing_${item.id}`, value);

  }

  const handleChangeEmail = (e) => {
    setEmailValue(e.target.value);
    if (e.target.value === "") {
      setEmailError("*Required");
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(e.target.value)) {
      setEmailError("*Invalid Email");
    } else {
      setEmailError("");
    }
  };
  const professionalTypePost = (values) => {
    // values.mediumship_level ===
  };
  const customHandleForProf = (e, setFieldValue, values) => {
    let { mediumship_level } = values;
    // console.log(mediumship_level, "mediumship_level");
    let value = e.target.value;
    if (parseInt(mediumship_level) === 7) {
      setFieldValue("when_do_i_pay", []);
      let array = [];
      array.push(value);
      setFieldValue("when_do_i_pay", array);
      // console.log(values.when_do_i_pay, "when_do_i_pay");
      // let array = values.when_do_i_pay
      // let val = array.filter(item => item !== value)
      // if (array.includes(value)) {
      //   // console.log(val);
      //   // if (array.length > 1) {
      //   // array = val
      //   // }
      //   // array.
      // } else {
      //   array.push(value);

      // }
      // console.log(array, array)
      // setFieldValue('when_do_i_pay', array);
      console.log(array, "if");
    } else {
      setFieldValue("when_do_i_pay", []);
      let array = [];
      array.push(value);
      setFieldValue("when_do_i_pay", array);
      console.log(array, "else");
    }
  };
  const customHandleForProfApprovedLevel = (e, setFieldValue) => {
    let value = e.target.value;
    if (parseInt(value) === 8) {
      setshowSupportSettings(true);
    } else {
      setshowSupportSettings(false);
    }
    if (parseInt(value) === 5 || parseInt(value) === 3 || parseInt(value) === 6 || parseInt(value) === 5 || parseInt(value) === 7) {
      let array = approvedPrefrences;
      array.map((item) => {
        return {
          ...item,
          ...item.show = true
        }
      })
      setApprovedPrefrences(array);
    }
    else {
      let array = [5, 3, 6, 7]
      let info = approvedPrefrences.map((value) => {
        return {
          ...value,
          status: stepTwoData.session_preferences.includes(value.name),
          show: value.id === 5 || value.id === 6 || value.id === 7 ? array.includes(parseInt(stepOneData.mediumship_level)) : true
        };
      });
      setApprovedPrefrences(info);
    }

    if (parseInt(value) === 7) {
      console.log(value, "7");
      setShowPostPay(true);
      setShowAddSettings(true);
      setShowPrePay(true);
      // setFieldValue("additional_settings", true);
      setFieldValue("when_do_i_pay", ["1"]);
    }
    if (parseInt(value) === 1 || parseInt(value) === 2 || parseInt(value) === 4 || parseInt(value) === 5) {
      console.log(value, "7");

      setShowAddSettings(false);
      // setFieldValue("additional_settings", true);
      setFieldValue("when_do_i_pay", []);
    }

    if (parseInt(value) === 3 || parseInt(value) === 6) {
      console.log(value, "36");
      if (parseInt(value) === 3) {
        setShowPostPay(true);
        setShowPrePay(false);
        setShowAddSettings(true);
        // setFieldValue("additional_settings", false);
        setFieldValue("when_do_i_pay", ["2"]);
      }

      if (parseInt(value) === 6) {
        setShowPostPay(false);
        setShowPrePay(true);
        setShowAddSettings(true);
        // setFieldValue("additional_settings", false);
        setFieldValue("when_do_i_pay", ["1"]);
      }

      if (
        parseInt(value) !== 3 &&
        parseInt(value) !== 6 &&
        parseInt(value) !== 7
      ) {

        setFieldValue("additional_settings", false);

        setFieldValue("when_do_i_pay", []);
      }
    }
    setFieldValue("mediumship_level", value);
  };

  const onChangeTimeZoneHanlder = () => {
    console.log(newTimezoneData);
    if (newTimezoneData.length > 0) {
      return;
    }
    reloadTimeZones();
  }

  const onRefreshTimeZoneHandler = () => {

  }
  return (
    <div>
      {/* {parseInt(stepOneData.mediumship_level) === 3 || parseInt(stepOneData.mediumship_level) === 6 || parseInt(stepOneData.mediumship_level) === 7 ? (
        <ProfilePicture info={stepOneData} getProfile={getProfile} />
      ) : (
        ""
      )} */}
      <ProfilePicture info={stepOneData} getProfile={getProfile} />

      <Formik
        initialValues={stepOneData}

        validate={(values) => {
          const errors = {};
          if (!values.mediumshipSpecialities.length)
            errors.mediumshipSpecialities = "Required";

          if (!values.country) errors.country = "Required";

          if (!values.mediumshipLevel) errors.mediumshipLevel = "Required";

          // if (values.whatsapp && values.whatsapp.length < 10) {
          //   errors.whatsapp = "Invalid whatsapp";
          // }
          if (values.phone && values.phone.length < 10) {
            errors.phone = "Invalid Phone";
          }

          if (!values.timezone) errors.timezone = "Required";
          if (!values.session_currency) errors.session_currency = "Required";

          // if (approvedPrefrences[4].status && !values.coaching_pricing_30) errors.coaching_pricing_30 = "Required";
          // if (approvedPrefrences[5].status && !values.hosting_circle_pricing_30) errors.hosting_circle_pricing_30 = "Required";
          // if (approvedPrefrences[6].status && !values.hosting_qa_pricing_30) errors.hosting_qa_pricing_30 = "Required";

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          RunFunction(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          setFieldTouched,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="tab-pane"
            role="tabpanel"
            id="step1"
          >
            <div className="row-one-step">
              <div className="left-side">
                <label>Phone</label>
                <PhoneInputField
                  className={`number-input ${errors.phone && touched.phone && errors.phone
                    ? "error-boundry"
                    : ""
                    }`}
                  // country={userCountryCode.toLowerCase()}
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                  value={values.phone}
                  id="phone"
                  name="phone"
                />
                <div className="copy-icon">
                  <CopyComponent value={values.phone} />
                </div>
                {/* <input
                className={`${
                  errors.phone && touched.phone && errors.phone
                    ? "error-boundry"
                    : ""
                }`}
                onChange={(e) => handleChangeHandler(e, "phone", setFieldValue)}
                onBlur={handleBlur}
                value={values.phone}
                id="phone"
                name="phone"
                type="tel"
              /> */}
                <span className="error">
                  {errors.phone && touched.phone && errors.phone}
                </span>
              </div>
              <div className="right-side">
                <label>Whatsapp</label>
                <PhoneInputField
                  className={`${errors.whatsapp && touched.whatsapp && errors.whatsapp
                    ? "error-boundry"
                    : ""
                    }`}
                  // country={userCountryCode.toLowerCase()}
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                  value={values.whatsapp}
                  id="whatsapp"
                  name="whatsapp"
                />
                <div className="copy-icon">
                  <CopyComponent value={values.whatsapp} />
                </div>
                {/* <input
                onChange={(e) =>
                  handleChangeHandler(e, "whatsapp", setFieldValue)
                }
                onBlur={handleBlur}
                value={values.whatsapp}
                id="whatsapp"
                name="whatsapp"
                type="tel"
              /> */}
                <span className="error">
                  {errors.whatsapp && touched.whatsapp && errors.whatsapp}
                </span>
              </div>
            </div>
            <div className="row-one-step">
              <div className="full-side">
                <label>
                  Languages Available for Mediumship Sessions<i>*</i>
                </label>
                <Select
                  id="languages"
                  className="selection-box"
                  options={newformData}
                  multi={true}
                  isMulti
                  onChange={(value) => setFieldValue("languages", value)}
                  onBlur={handleBlur}
                  placeholder="Select languages"
                  value={values.languages}
                  closeMenuOnSelect={false}
                  closeMenuOnScroll={false}
                />
                <span
                  onClick={() => setOpenOtherLnaguages(!openOtherLnaguages)}
                  className="other-lang pointer"
                >
                  Other Language
                </span>
                {openOtherLnaguages && (
                  <TagsInput
                    value={values.otherLanguages}
                    className="form-other-tags-input"
                    name="otherLanguages"
                    onChange={(value) => setFieldValue("otherLanguages", value)}
                    placeHolder="Other Language"
                  />
                )}
                <span className="error">
                  {errors.languages && touched.languages && errors.languages}
                </span>
              </div>
            </div>
            <div className="row-one-step">
              <div className="full-side">
                <label>
                  Current Mediumship Level
                  <i>*</i>
                </label>
                <div className="InputGroup">
                  <div className="radio-box">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="radio"
                      name="mediumshipLevel"
                      id="size_1"
                      value={1}
                      checked={
                        parseInt(values.mediumshipLevel) === 1 ? true : false
                      }
                    />
                    <label htmlFor="size_1" className=" w-unset">
                      Novice
                    </label>
                  </div>
                  <div className="radio-box">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="radio"
                      name="mediumshipLevel"
                      id="size_2"
                      value={2}
                      checked={
                        parseInt(values.mediumshipLevel) === 2 ? true : false
                      }
                    />
                    <label htmlFor="size_2" className=" w-unset">
                      Intermediate
                    </label>
                  </div>
                  <div className="radio-box">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="radio"
                      name="mediumshipLevel"
                      id="size_3"
                      value={3}
                      checked={
                        parseInt(values.mediumshipLevel) === 3 ? true : false
                      }
                    />
                    <label htmlFor="size_3" className=" w-unset">
                      Professional
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/***if professinal */}
            {parseInt(values.mediumshipLevel) === 3 && (
              <div className="row-one-step">
                <div className="full-side">
                  <label>
                    Years as professional <i>*</i>
                  </label>
                  <div className="InputGroup">
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="medium_professional_category"
                        id="medium_professional_category_1"
                        value={1}
                        checked={
                          parseInt(values.medium_professional_category) === 1
                            ? true
                            : false
                        }
                      />
                      <label
                        className="w-unset"
                        htmlFor="medium_professional_category_1"
                      >
                        1 year
                      </label>
                    </div>
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="medium_professional_category"
                        id="medium_professional_category_2"
                        value={2}
                        checked={
                          parseInt(values.medium_professional_category) === 2
                            ? true
                            : false
                        }
                      />
                      <label
                        className="w-unset"
                        htmlFor="medium_professional_category_2"
                      >
                        2-3 years
                      </label>
                    </div>
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="medium_professional_category"
                        id="medium_professional_category_3"
                        value={3}
                        checked={
                          parseInt(values.medium_professional_category) === 3
                            ? true
                            : false
                        }
                      />
                      <label
                        className="w-unset"
                        htmlFor="medium_professional_category_3"
                      >
                        3-5 years
                      </label>
                    </div>
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="medium_professional_category"
                        id="medium_professional_category_4"
                        value={4}
                        checked={
                          parseInt(values.medium_professional_category) === 4
                            ? true
                            : false
                        }
                      />
                      <label
                        className="w-unset"
                        htmlFor="medium_professional_category_4"
                      >
                        5-10 years
                      </label>
                    </div>
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="medium_professional_category"
                        id="medium_professional_category_5"
                        value={5}
                        checked={
                          parseInt(values.medium_professional_category) === 5
                            ? true
                            : false
                        }
                      />
                      <label
                        className="w-unset"
                        htmlFor="medium_professional_category_5"
                      >
                        10-15 years
                      </label>
                    </div>
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="medium_professional_category"
                        id="medium_professional_category_6"
                        value={6}
                        checked={
                          parseInt(values.medium_professional_category) === 6
                            ? true
                            : false
                        }
                      />
                      <label
                        className="w-unset"
                        htmlFor="medium_professional_category_6"
                      >
                        15+ years
                      </label>
                    </div>
                  </div>
                  <span className="error text-left">
                    {errors.mediumshipLevel &&
                      touched.mediumshipLevel &&
                      errors.mediumshipLevel}
                  </span>
                </div>
              </div>
            )}
            <div className="row-one-step">
              <div className="full-side">
                <label>
                  Approved Mediumship Level
                  <i>*</i>
                </label>
                <div className="InputGroup">
                  <div className="radio-box">
                    <input
                      onChange={(e) =>
                        customHandleForProfApprovedLevel(e, setFieldValue)
                      }
                      onBlur={handleBlur}
                      type="radio"
                      name="mediumship_level"
                      id="size_1_mediumship_level"
                      value={1}
                      checked={
                        parseInt(values.mediumship_level) === 1 ? true : false
                      }
                    />
                    <label
                      className=" w-unset"
                      htmlFor="size_1_mediumship_level"
                    >
                      Novice I
                    </label>
                  </div>
                  <div className="radio-box">
                    <input
                      onChange={(e) =>
                        customHandleForProfApprovedLevel(e, setFieldValue)
                      }
                      onBlur={handleBlur}
                      type="radio"
                      name="mediumship_level"
                      id="size_2_mediumship_level"
                      value={4}
                      checked={
                        parseInt(values.mediumship_level) === 4 ? true : false
                      }
                    />
                    <label
                      className=" w-unset"
                      htmlFor="size_2_mediumship_level"
                    >
                      Novice II
                    </label>
                  </div>
                  <div className="radio-box">
                    <input
                      onChange={(e) =>
                        customHandleForProfApprovedLevel(e, setFieldValue)
                      }
                      onBlur={handleBlur}
                      type="radio"
                      name="mediumship_level"
                      id="size_3_mediumship_level"
                      value={2}
                      checked={
                        parseInt(values.mediumship_level) === 2 ? true : false
                      }
                    />
                    <label
                      htmlFor="size_3_mediumship_level"
                      className=" w-unset"
                    >
                      Intermediate I
                    </label>
                  </div>
                  <div className="radio-box">
                    <input
                      onChange={(e) =>
                        customHandleForProfApprovedLevel(e, setFieldValue)
                      }
                      onBlur={handleBlur}
                      type="radio"
                      name="mediumship_level"
                      id="size_4_mediumship_level"
                      value={5}
                      checked={
                        parseInt(values.mediumship_level) === 5 ? true : false
                      }
                    />
                    <label
                      htmlFor="size_4_mediumship_level"
                      className=" w-unset"
                    >
                      Intermediate II
                    </label>
                  </div>
                  <div className="radio-box">
                    <input
                      onChange={(e) =>
                        customHandleForProfApprovedLevel(e, setFieldValue)
                      }
                      onBlur={handleBlur}
                      type="radio"
                      name="mediumship_level"
                      id="size_5_mediumship_level"
                      value={3}
                      checked={
                        parseInt(values.mediumship_level) === 3 ? true : false
                      }
                    />
                    <label
                      htmlFor="size_5_mediumship_level"
                      className=" w-unset"
                    >
                      Professional I
                    </label>
                  </div>
                  <div className="radio-box">
                    <input
                      onChange={(e) =>
                        customHandleForProfApprovedLevel(e, setFieldValue)
                      }
                      onBlur={handleBlur}
                      type="radio"
                      name="mediumship_level"
                      id="size_6_mediumship_level"
                      value={6}
                      checked={
                        parseInt(values.mediumship_level) === 6 ? true : false
                      }
                    />
                    <label
                      htmlFor="size_6_mediumship_level"
                      className=" w-unset"
                    >
                      Professional II
                    </label>
                  </div>
                  <div className="radio-box">
                    <input
                      onChange={(e) =>
                        customHandleForProfApprovedLevel(e, setFieldValue)
                      }
                      onBlur={handleBlur}
                      type="radio"
                      name="mediumship_level"
                      id="size_7_mediumship_level"
                      value={7}
                      checked={
                        parseInt(values.mediumship_level) === 7 ? true : false
                      }
                    />
                    <label
                      htmlFor="size_7_mediumship_level"
                      className=" w-unset"
                    >
                      Professional III
                    </label>
                  </div>
                  <div className="radio-box">
                    <input
                      onChange={(e) =>
                        customHandleForProfApprovedLevel(e, setFieldValue)
                      }
                      onBlur={handleBlur}
                      type="radio"
                      name="mediumship_level"
                      id="size_8_mediumship_level"
                      value={8}
                      checked={
                        parseInt(values.mediumship_level) === 8 ? true : false
                      }
                    />
                    <label
                      htmlFor="size_8_mediumship_level"
                      className=" w-unset"
                    >
                      Support
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row-one-step">
              <div className="full-side">
                <label>
                  Approved Session Preferences :<i>*</i>
                </label>
                <div className="check-field">
                  {/* {console.log(approvedPrefrences,"approvedPrefrences")} */}
                  {approvedPrefrences.map((item) => <>{
                    item.show && !show_support_settings ? <div className="check-field">
                      <input
                        onChange={(e) => sessionChangeHandler(e, item, values)}
                        value={item.name}
                        id={`my-check-${item.id}`}
                        multiple
                        // selected={item.status}
                        checked={item.status === true ? "checked" : ""}
                        type="checkbox"
                        name="approvedSessions"
                      />
                      {/* {console.log(item.status,"approvedPrefrences")} */}
                      <label htmlFor={`my-check-${item.id}`}>{item.name}</label>
                    </div>
                      : ""
                  }
                  </>
                  )}
                  {/* { preferncesSection!==null? console.log('session',preferncesSection.session_preferences):''}
                {preferncesSection!==null?preferncesSection.session_preferences.map((item) => (
                    <div className="check-field">
                      <input
                        onChange={(e)=>handleChange(e,item)}
                        onBlur={handleBlur}
                        value={item}
                        id={`my-check-${item}`}
                        multiple
                        
                        // selected={item.status}
                        checked={item.status === true ? "checked" : ""}
                        type="checkbox"
                        name="approvedsessions"
                      />
                      <label htmlFor={`my-check-${item}`}>{item}</label>
                    </div>
                  )):""} */}
                  {/* <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="medium"
                  type="checkbox"
                  value={1}
                  defaultChecked={values.currentRoles.includes(1)}
                  // value="Medium"
                  name="currentRoles"
                />
                <label htmlFor="medium">Medium</label> */}
                  {showAddSettings && !show_support_settings && (
                    <div className="check-field">
                      <input
                        onChange={(e) =>
                          additionalSettingsHandler(e, setFieldValue, values)
                        }
                        value={"Additional Settings"}
                        id={"additional_settings"}
                        // selected={item.status}
                        checked={additional_settings_data}
                        type="checkbox"
                        name="additional_settings"
                      />
                      {/* {console.log(item.status,"approvedPrefrences")} */}
                      <label htmlFor={"additional_settings"}>
                        {"Extra Services"}
                      </label>
                    </div>
                  )}
                  {show_support_settings && (
                    <div className="check-field">
                      <input
                        onChange={(e) =>
                          supportSettingsHandler(e, setFieldValue, values)
                        }
                        value={"Support Settings"}
                        id={"support_settings"}
                        // selected={item.status}
                        checked={support_settings_data}
                        type="checkbox"
                        name="support_settings"
                      />
                      {/* {console.log(item.status,"approvedPrefrences")} */}
                      <label htmlFor={"support_settings"}>
                        {"Support"}
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/*****New section***** */}
            {/* stepOneData.mediumship_level */}


            {approvedPrefrences[4].show && approvedPrefrences[4].status && !show_support_settings && <div className="row-one-step">
              <div className="full-side">
                <label>Coaching Session Length</label>

              </div>
              {/* stepOneData.mediumship_level */}

              <>
                <div className="mt-2 ml-5">
                  <label>30 Minutes</label>
                  <input
                    className="w-25 p-2 amount_input"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.coaching_pricing_30}
                    id={"coaching_pricing_30"}
                    name={"coaching_pricing_30"}
                    type="text"
                    placeholder="Amount"
                  />

                </div>
                <div className="mt-2 ml-5">
                  <label>60 Minutes</label>
                  <input
                    className="w-25 p-2 amount_input"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.coaching_pricing_60}
                    id={"coaching_pricing_60"}
                    name={"coaching_pricing_60"}
                    type="text"
                    placeholder="Amount"
                  />

                </div>
                {/* <span className="error mt-2 ml-5">
                  {errors.coaching_pricing_30 && touched.coaching_pricing_30 && errors.coaching_pricing_30}
                </span> */}
              </>

            </div>}
            {approvedPrefrences[5].show && approvedPrefrences[5].status && !show_support_settings && <div className="row-one-step">
              <div className="full-side">
                <label>Hosting Practice Circles</label>

              </div>
              {/* stepOneData.mediumship_level */}

              <>
                <div className="mt-2 ml-5">
                  <label>30 Minutes</label>
                  <input
                    className="w-25 p-2 amount_input"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.hosting_circle_pricing_30}
                    id={"hosting_circle_pricing_30"}
                    name={"hosting_circle_pricing_30"}
                    type="text"
                    placeholder="Amount"
                  />

                </div>
                {/* <span className="error mt-2 ml-5">
                  {errors.hosting_circle_pricing_30 && touched.hosting_circle_pricing_30 && errors.hosting_circle_pricing_30}
                </span> */}
              </>

            </div>
            }
            {approvedPrefrences[6].show && approvedPrefrences[6].status && !show_support_settings && <div className="row-one-step">
              <div className="full-side">
                <label>Hosting Q&As</label>

              </div>
              {/* stepOneData.mediumship_level */}

              <>
                <div className="mt-2 ml-5">
                  <label>30 Minutes</label>
                  <input
                    className="w-25 p-2 amount_input"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.hosting_qa_pricing_30}
                    id={"hosting_qa_pricing_30"}
                    name={"hosting_qa_pricing_30"}
                    type="text"
                    placeholder="Amount"
                  />

                </div>
                {/* <span className="error mt-2 ml-5">
                  {errors.hosting_qa_pricing_30 && touched.hosting_qa_pricing_30 && errors.hosting_qa_pricing_30}
                </span> */}
              </>

            </div>}


            {(parseInt(values.mediumship_level) === 3 ||
              parseInt(values.mediumship_level) === 6 ||
              parseInt(values.mediumship_level) === 7) && (
                <>
                  <div className="row-one-step">
                    <div className="full-side">
                      <label>When do I pay?</label>
                      <div className="full-fild">
                        {showPrePay && (
                          <div className="check-field">
                            <input
                              onChange={(e) =>
                                customHandleForProf(e, setFieldValue, values)
                              }
                              onBlur={handleBlur}
                              type="radio"
                              name="when_do_i_pay"
                              id="when_do_i_pay_1"
                              value={"1"}
                              checked={
                                values.when_do_i_pay.includes("1") ? true : false
                              }
                            // checked={
                            //   parseInt(values.when_do_i_pay) === 1 ? true : false
                            // }
                            />
                            <label className=" w-unset" htmlFor="when_do_i_pay_1">
                              Pay to Book
                            </label>
                          </div>
                        )}
                        {showPostPay && (
                          <div className="check-field">
                            <input
                              onChange={(e) =>
                                customHandleForProf(e, setFieldValue, values)
                              }
                              onBlur={handleBlur}
                              type="radio"
                              name="when_do_i_pay"
                              id="when_do_i_pay_2"
                              value={"2"}
                              checked={
                                values.when_do_i_pay.includes("2") ? true : false
                              }
                            // checked={
                            //   parseInt(values.when_do_i_pay) === 2 ? true : false
                            // }
                            />
                            <label htmlFor="when_do_i_pay_2" className=" w-unset">
                              Pay After Session
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/******************** */}
                  {(parseInt(values.mediumship_level) === 3 ||
                    parseInt(values.mediumship_level) === 6 ||
                    parseInt(values.mediumship_level) === 7) && (
                      <>
                        <div className="row-one-step p-2">
                          <div className="full-side">
                            <label>Session Currency</label>

                            {/* stepOneData.mediumship_level */}
                            <div className="full-fild">
                              <>
                                <div className="check-field">
                                  <input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="radio"
                                    name="session_currency"
                                    id="session_currency_usd"
                                    value={"USD"}
                                    checked={
                                      values.session_currency === "USD"
                                        ? true
                                        : false
                                    }
                                  // checked={
                                  //   parseInt(values.when_do_i_pay) === 1 ? true : false
                                  // }
                                  />
                                  <label
                                    className=" w-unset"
                                    htmlFor="when_do_i_pay_1"
                                  >
                                    USD
                                  </label>
                                </div>
                                <div className="check-field">
                                  <input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="radio"
                                    name="session_currency"
                                    id="session_currency_cad"
                                    value={"CAD"}
                                    checked={
                                      values.session_currency === "CAD"
                                        ? true
                                        : false
                                    }
                                  // checked={
                                  //   parseInt(values.when_do_i_pay) === 1 ? true : false
                                  // }
                                  />
                                  <label
                                    className=" w-unset"
                                    htmlFor="when_do_i_pay_1"
                                  >
                                    CAD
                                  </label>
                                </div>
                                <div className="check-field">
                                  <input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="radio"
                                    name="session_currency"
                                    id="session_currency_aud"
                                    value={"AUD"}
                                    checked={
                                      values.session_currency === "AUD"
                                        ? true
                                        : false
                                    }
                                  // checked={
                                  //   parseInt(values.when_do_i_pay) === 1 ? true : false
                                  // }
                                  />
                                  <label
                                    className=" w-unset"
                                    htmlFor="when_do_i_pay_1"
                                  >
                                    AUD
                                  </label>
                                </div>
                              </>
                            </div>
                          </div>
                        </div>
                        <div className="row-one-step">
                          <div className="full-side">
                            <span className="error text-left">
                              {errors.session_currency &&
                                touched.session_currency &&
                                errors.session_currency}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  {/******New section**** */}
                  {/******************** */}
                  <div className="row-one-step">
                    <div className="full-side">
                      <label>Session Length</label>
                      {/* <div className="InputGroup">
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="session_length"
                        id="size_1_session_length"
                        value={30}
                        checked={
                          parseInt(values.session_length) === 30 ? true : false
                        }
                      />
                      <label
                        className=" w-unset"
                        htmlFor="size_1_session_length"
                      >
                        30 Minutes
                      </label>
                    </div>
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="session_length"
                        id="size_2_session_length"
                        value={45}
                        checked={
                          parseInt(values.session_length) === 45 ? true : false
                        }
                      />
                      <label
                        htmlFor="size_2_session_length"
                        className=" w-unset"
                      >
                        45 Minutes
                      </label>
                    </div>
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="session_length"
                        id="size_3_session_length"
                        value={60}
                        checked={
                          parseInt(values.session_length) === 60 ? true : false
                        }
                      />
                      <label
                        htmlFor="size_3_session_length"
                        className=" w-unset"
                      >
                        60 Minutes
                      </label>
                    </div>
                  </div> */}
                    </div>
                    {/* stepOneData.mediumship_level */}
                    {(parseInt(values.mediumship_level) === 3 ||
                      parseInt(values.mediumship_level) === 6 ||
                      parseInt(values.mediumship_level) === 7) && (
                        <>
                          <div className="mt-2 ml-5">
                            <label>30 Minutes</label>
                            <input
                              className="w-25 p-2 amount_input"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.session_length_30}
                              id={"session_length_30"}
                              name={"session_length_30"}
                              type="text"
                              placeholder="Amount"
                            />
                            {/* <select
                          id="session_length_30_currency"
                          name="session_length_30_currency"
                          value={values.session_length_30_currency}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option disabled selected value="">
                            Currency
                          </option>
                          <option value={"USD"}>USD</option>
                          <option value={"CAD"}>CAD</option>
                          <option value={"AUD"}>AUD</option>
                        </select> */}
                          </div>
                          <div className="mt-2 ml-5">
                            <label>45 Minutes</label>

                            <input
                              className="p-2 w-25 amount_input"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.session_length_45}
                              id={"session_length_45"}
                              name={"session_length_45"}
                              type="text"
                              placeholder="Amount"
                            />
                            {/* <select
                          id="session_length_45_currency"
                          name="session_length_45_currency"
                          value={values.session_length_45_currency}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option disabled selected value="">
                            Currency
                          </option>
                          <option value={"USD"}>USD</option>
                          <option value={"CAD"}>CAD</option>
                          <option value={"AUD"}>AUD</option>
                        </select> */}
                          </div>
                          <div className=" mt-2 ml-5">
                            <label>60 Minutes</label>

                            <input
                              className="p-2 w-25 amount_input"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.session_length_60}
                              id={"session_length_60"}
                              name={"session_length_60"}
                              type="text"
                              placeholder="Amount"
                            />
                            {/* <select
                          id="session_length_60_currency"
                          name="session_length_60_currency"
                          value={values.session_length_60_currency}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option disabled selected value="">
                            Currency
                          </option>
                          <option value={"USD"}>USD</option>
                          <option value={"CAD"}>CAD</option>
                          <option value={"AUD"}>AUD</option>
                        </select> */}
                          </div>

                          <div className=" mt-2 ml-5">
                            <label>45 Minutes for 2 People</label>

                            <input
                              className="p-2 w-25 amount_input"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.session_length_45_2}
                              id={"session_length_45_2"}
                              name={"session_length_45_2"}
                              type="text"
                              placeholder="Amount"
                            />
                            {/* <select
                          id="session_length_45_2_currency"
                          name="session_length_45_2_currency"
                          value={values.session_length_45_2_currency}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option disabled selected value="">
                            Currency
                          </option>
                          <option value={"USD"}>USD</option>
                          <option value={"CAD"}>CAD</option>
                          <option value={"AUD"}>AUD</option>
                        </select> */}
                          </div>

                          <div className=" mt-2 ml-5">
                            <label>60 Minutes for 2 People</label>

                            <input
                              className="p-2 w-25 amount_input"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.session_length_60_2}
                              id={"session_length_60_2"}
                              name={"session_length_60_2"}
                              type="text"
                              placeholder="Amount"
                            />
                            {/* <select
                          id="session_length_60_2_currency"
                          name="session_length_60_2_currency"
                          value={values.session_length_60_2_currency}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option disabled selected value="">
                            Currency
                          </option>
                          <option value={"USD"}>USD</option>
                          <option value={"CAD"}>CAD</option>
                          <option value={"AUD"}>AUD</option>
                        </select> */}
                          </div>
                        </>
                      )}
                  </div>

                  {/******New section**** */}
                  {/* {console.log('123',values.extra_services_list)} */}
                  {additional_settings_data &&
                    (parseInt(values.mediumship_level) === 7 || parseInt(values.mediumship_level) === 3 || parseInt(values.mediumship_level) === 6) && (
                      <div className="row-one-step">
                        <div className="full-side">
                          <label>Extra Services Pricing</label>
                        </div>
                        {values.extra_services_list.map((item) => (
                          <>
                            <div className="mt-2 ml-5">
                              <label>{item.name}</label>
                              {/* <input
                              className="w-25 p-2 amount_input"
                              onChange={(e)=>handleServicesChange(e, item, setFieldValue)}
                              onBlur={handleBlur}
                              defaultValue={item.amount}
                              id={`extrapricing_${item.id}`}
                              name={`extrapricing_${item.id}`}
                              type="text"
                              placeholder="Amount"
                            /> */}
                              <input
                                className="w-25 p-2 amount_input"
                                onChange={(e) => handleServicesChange(e, item, setFieldValue)}
                                onBlur={handleBlur}
                                defaultValue={item.amount}
                                id={`extrapricing_${item.id}`}
                                name={`extrapricing_${item.id}`}
                                type="text"
                                placeholder="Amount"
                              />
                              {/* <input
                              className="w-25 p-2 amount_input"
                              onChange={(e)=>handleServicesChange(e, item, setFieldValue)}
                              onBlur={handleBlur}
                              value={item.amount}
                              id={item.id}
                              name={item.sub_session_name}
                              type="text"
                              placeholder="Amount"
                            /> */}
                            </div>
                            {/* <div className="mt-2 ml-5">
                            <label>45 Minutes Wellness Counseling</label>

                            <input
                              className="p-2 w-25 amount_input"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.wellness_counseling_45}
                              id={"wellness_counseling_45"}
                              name={"wellness_counseling_45"}
                              type="text"
                              placeholder="Amount"
                            />
                          </div>
                          <div className=" mt-2 ml-5">
                            <label>45 Minutes Distant Healing</label>

                            <input
                              className="p-2 w-25 amount_input"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.distant_healing_45}
                              id={"distant_healing_45"}
                              name={"distant_healing_45"}
                              type="text"
                              placeholder="Amount"
                            />
                          </div> */}
                          </>)
                        )}
                      </div>
                    )}

                </>
              )}
            {(parseInt(values.mediumship_level) === 3 ||
              parseInt(values.mediumship_level) === 6 ||
              parseInt(values.mediumship_level) === 7) && (
                <div className="row-one-step">
                  <div className="full-side">
                    <label>Pay It Forward Suggested Amount</label>
                  </div>
                  <div className="mt-2 ml-5">
                    <label>30 Minutes</label>
                    <Field
                      className="w-25 p-2 amount_input"
                      id="payitforward_pricing_30"
                      name="payitforward_pricing_30"
                      type="number"
                      placeholder="Amount"
                    />
                    <ErrorMessage
                      name="payitforward_pricing_30"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="mt-2 ml-5">
                    <label>45 Minutes</label>
                    <Field
                      className="w-25 p-2 amount_input"
                      id="payitforward_pricing_45"
                      name="payitforward_pricing_45"
                      type="number"
                      placeholder="Amount"
                    />
                    <ErrorMessage
                      name="payitforward_pricing_45"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="mt-2 ml-5">
                    <label>60 Minutes</label>
                    <Field
                      className="w-25 p-2 amount_input"
                      id="payitforward_pricing_60"
                      name="payitforward_pricing_60"
                      type="number"
                      placeholder="Amount"
                    />
                    <ErrorMessage
                      name="payitforward_pricing_60"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="mt-2 ml-5">
                    <label>45 Minutes for 2 People</label>
                    <Field
                      className="w-25 p-2 amount_input"
                      id="payitforward_pricing_45_2"
                      name="payitforward_pricing_45_2"
                      type="number"
                      placeholder="Amount"
                    />
                    <ErrorMessage
                      name="payitforward_pricing_45_2"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="mt-2 ml-5">
                    <label>60 Minutes for 2 People</label>
                    <Field
                      className="w-25 p-2 amount_input"
                      id="payitforward_pricing_60_2"
                      name="payitforward_pricing_60_2"
                      type="number"
                      placeholder="Amount"
                    />
                    <ErrorMessage
                      name="payitforward_pricing_60_2"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
              )

            }
            <div className="row-one-step">
              <div className="full-side">
                <label>
                  Mediumship Specialities<i>*</i>
                </label>
                <Select
                  id="mediumshipSpecialities"
                  className="selection-box"
                  options={newSpecilityData}
                  multi={true}
                  isMulti
                  onChange={(value) =>
                    setFieldValue("mediumshipSpecialities", value)
                  }
                  onBlur={handleBlur}
                  placeholder="Select Specialities"
                  value={values.mediumshipSpecialities}
                  closeMenuOnSelect={false}
                  closeMenuOnScroll={false}
                />

                <span
                  onClick={() => setOpenOtherSpecility(!openOtherSpecility)}
                  className="other-lang pointer"
                >
                  Other specialities
                </span>
                {openOtherSpecility && (
                  <TagsInput
                    value={values.otherMediumSpecialities}
                    className="form-other-tags-input"
                    onChange={(value) =>
                      setFieldValue("otherMediumSpecialities", value)
                    }
                    name="otherSpecialities"
                    placeHolder="Other specialities"
                  />
                )}
                <span className="error">
                  {errors.mediumshipSpecialities &&
                    touched.mediumshipSpecialities &&
                    errors.mediumshipSpecialities}
                </span>
              </div>
            </div>
            <div className="row-one-step">
              <div className="left-side">
                <label>
                  Country <i>*</i>
                </label>
                {/* <input type="text" placeholder="United States" /> */}
                {values.country && (
                  <Select
                    id="country"
                    className="selection-box"
                    options={newCountryData}
                    // multi={true}
                    // isMulti
                    onChange={(value) =>
                      countryChangeHandler(value, "country", setFieldValue)
                    }
                    onBlur={handleBlur}
                    placeholder="United States "
                    defaultInputValue={values.country}
                    value={values.country}
                    closeMenuOnSelect={true}
                    closeMenuOnScroll={true}
                  />
                )}
                {!values.country && (
                  <Select
                    id="country"
                    className="selection-box"
                    options={newCountryData}
                    // multi={true}
                    // isMulti
                    onChange={(value) =>
                      countryChangeHandler(value, "country", setFieldValue)
                    }
                    onBlur={handleBlur}
                    placeholder="United States "
                    defaultInputValue={values.country}
                    value={values.country}
                    closeMenuOnSelect={true}
                    closeMenuOnScroll={true}
                  />
                )}
                <span className="error">
                  {errors.country && touched.country && errors.country}
                </span>
              </div>
              <div className="right-side cuntrey-website">
                <label>Website</label>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.website}
                  id="website"
                  name="website"
                  type="text"
                  placeholder="yourwebsite.com"
                />
              </div>
            </div>
            <div className="row-one-step">
              <label>
                Timezone<i>*</i>
                {/* <img
                  title="Reload TimeZones"
                  onClick={(e) => reloadTimeZones("refresh")}
                  className="googl-link-copy-img copy-image-animate refresh-btn-app mt-4"
                  alt="Reload TimeZones"
                  src="/images/refresh.png"
                /> */}
              </label>
              {values.timezone && (
                <Select
                  id="timezone"
                  className="selection-box"
                  options={newTimezoneData}
                  onChange={(value) => setFieldValue("timezone", value)}
                  onBlur={handleBlur}
                  placeholder="Select Your Timezone"
                  // defaultInputValue={values.timezone.front_name}
                  defaultValue={values.timezone}
                  value={values.timezone}
                  closeMenuOnSelect={true}
                  closeMenuOnScroll={true}
                  isSearchable={true}
                  onMenuOpen={onChangeTimeZoneHanlder}
                />
              )}
              {!values.timezone && (
                <Select
                  id="timezone"
                  className="selection-box"
                  options={newTimezoneData}
                  onChange={(value) => setFieldValue("timezone", value)}
                  onBlur={handleBlur}
                  defaultValue={values.timezone}
                  // defaultInputValue={values.timezone.front_name}
                  placeholder="Select Your Timezone"
                  value={values.timezone}
                  closeMenuOnSelect={true}
                  closeMenuOnScroll={true}
                  isSearchable={true}
                  onMenuOpen={onChangeTimeZoneHanlder}
                />
              )}
              <span className="error">
                {errors.timezone && touched.timezone && errors.timezone}
              </span>
            </div>
            <div className="row-one-step textarea">
              <label>Biographical Info</label>
              <textarea
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.biographicalInfo}
                //   style="text"
                id="biographicalInfo"
                name="biographicalInfo"
                placeholder="Write Here..."
                rows="4"
                cols="50"
              ></textarea>
            </div>
            <div className="row-one-step">
              <div className="full-side">
                <label className="w-unset">
                  Zoom account integration&nbsp;:&nbsp;
                </label>
                <span className="other-lang pointer w-unset">
                  {values.zoom === "" ? "Pending" : "Done"}
                </span>
              </div>
            </div>
            <div className="row-one-step">
              <div className="full-side">
                <label className="w-unset">
                  Google Calendar integration&nbsp;:&nbsp;
                </label>

                <span className="other-lang pointer w-unset">
                  {values.google === "" ? "Pending" : "Done"}
                </span>
              </div>
            </div>
            {/********** */}
            <div className="row-one-step">
              <div className="full-side">
                <label className="w-unset">
                  Payment integration&nbsp;:&nbsp;
                </label>

                <div className="other-lang pointer ml-5">
                  <div>
                    <span>Stripe : </span>{" "}
                    {values.is_stripe_integrated ? (
                      <img className="mt-2 mr-2" src="/localImages/tick.png" />
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <span>Square : </span>{" "}
                    {values.is_square_integrated ? (
                      <img className="mt-2 mr-2" src="/localImages/tick.png" />
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <span>Paypal :</span>{" "}
                    {values.is_paypal_integrated ? (
                      <img className="mt-2 mr-2" src="/localImages/tick.png" />
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <span>Pay It Forward :</span>{" "}
                    {values.pay_forward ? (
                      <img className="mt-2 mr-2" src="/localImages/tick.png" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/********* */}
            <div className="row-one-step">
              <label>Update Email</label>
              <div className="verification-code-section mt-3">
                <div className="d-flex">
                  <>
                    {" "}
                    <input
                      onChange={handleChangeEmail}
                      onBlur={handleBlur}
                      className={`height-45 margin-right-30 `}
                      value={emailValue}
                      type="text"
                      id="changeemail"
                      name="changeemail"
                      placeholder="Email"
                    // disabled={emailDisabled}
                    />
                    <span className="error marg-top-55">{emailError}</span>
                  </>

                  <div className="mobile-div">
                    <Button
                      status={0}
                      type="button"
                      className={`resend-btn w-unset height-45 min-width-130px`}
                      onClick={onChangeEmailHandler}
                      title={"Change Email"}
                    />
                  </div>
                </div>

                <div className={`text-left green`}>
                  {/* <span>{emailMessage}</span> */}
                </div>
              </div>
            </div>
            <div className="row-one-step">
              <label>Recommendation </label>
              <div className="verification-code-section mt-3">
                <div className="d-flex">
                  <>
                    {" "}
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`height-45 margin-right-30 `}
                      value={values.recommendation}
                      type="text"
                      id="recommendation"
                      name="recommendation"
                      placeholder=""
                    // disabled={emailDisabled}
                    />
                    {/* <span className="error marg-top-55">{emailError}</span> */}
                  </>

                  {/* <div className="mobile-div">
                    <Button
                      status={0}
                      type="button"
                      className={`resend-btn w-unset height-45 min-width-130px`}
                      onClick={onChangeEmailHandler}
                      title={"Submit"}
                    />
                  </div> */}
                </div>

                <div className={`text-left green`}>
                  {/* <span>{emailMessage}</span> */}
                </div>
              </div>
            </div>
            <div className="row-one-step row-comment">
              <label>Comment </label>
              <div className="">
                <div className="d-flex">
                  <>
                    {" "}
                    <textarea
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`margin-right-30 `}
                      rows={40}
                      cols={40}
                      value={values.comments}
                      // type="text"
                      id="comments"
                      name="comments"
                      placeholder=""
                    // disabled={emailDisabled}
                    />
                    {/* <span className="error marg-top-55">{emailError}</span> */}
                  </>

                  {/* <div className="mobile-div">
                    <Button
                      status={0}
                      type="button"
                      className={`resend-btn w-unset height-45 min-width-130px`}
                      onClick={onChangeEmailHandler}
                      title={"Submit"}
                    />
                  </div> */}
                </div>

                <div className={`text-left green`}>
                  {/* <span>{emailMessage}</span> */}
                </div>
              </div>
            </div>
            <div className="row-one-step">
              <label>Test Mode </label>
              <div className="verification-code-section mt-3">
                <div className="d-flex">
                  <>
                    <ToggleTestMode
                      item={values}
                      setFieldValue={setFieldValue}
                      stepOneData={stepOneData}
                    />
                  </>
                </div>

                <div className={`text-left green`}>
                  {/* <span>{emailMessage}</span> */}
                </div>
              </div>
            </div>
            <div className="row-one-step">
              <div className="full-fild mt-2">
                {/* <label>Allow Free Coaching Session</label> */}
                <div className="prvacy-box eth-pri">
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value="allow_free_coaching_session"
                    id="allow_free_coaching_session"
                    type="checkbox"
                    name="allow_free_coaching_session"
                    checked={values.allow_free_coaching_session}
                  />
                  <label htmlFor="allow_free_coaching_session" className="width-unset">Allow One More Free Coaching Session</label>
                </div>
              </div>
            </div>
            <div className="update">
              <button type="submit" onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })} className="update">
                Update
              </button>
            </div>
            {/* <div className="edite-bution">
            <a href="#">
              <img src="images/edite.png" />
            </a>
          </div> */}
          </form>
        )}
      </Formik>
    </div>
  );
}
